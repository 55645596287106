//
// Función para medir el ancho de la barra de scroll.
// Basado en: http://davidwalsh.name/detect-scrollbar-width
//

var measureScroll = function () {
  // Ojito! Debe haber un document con body. :-)
  // Normalmente cuando se llama a esta función ya está el body cargado.
  var scrollDiv = document.createElement('div');
  scrollDiv.style.width = '100px';
  scrollDiv.style.height = '100px';
  scrollDiv.style.overflow = 'scroll';
  scrollDiv.style.position = 'absolute';
  scrollDiv.style.top = '-9999px';
  document.body.appendChild(scrollDiv);
  var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);

  return scrollbarWidth;
};

var scrollWidth = false;

export default {
  on: function () {
    scrollWidth = scrollWidth || measureScroll();

    // Bloquear el body
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = scrollWidth + 'px';

    // Bloquear la barra superior de navegación
    var navbar = document.getElementsByClassName('navbar-fixed-top');
    Array.prototype.forEach.call(navbar, function (nav) {
      nav.style.right = scrollWidth + 'px';
    });
  },
  off: function () {
    // Desbloquear los elementos pasados 300 milisegundos (que es
    // cuando termina la transición de las cards)
    setTimeout(function () {
      // Desbloquear el body
      document.body.removeAttribute('style');

      // Desbloquear la barra superior de navegación
      var navbar = document.getElementsByClassName('navbar-fixed-top');
      Array.prototype.forEach.call(navbar, function (nav) {
        nav.removeAttribute('style');
      });
    }, 300);
  }
};
