import {
  resetSelectProfile,
  updatePlan,
  setProductDetails
} from 'app/Data/plans/plan/actions';
import {setSubscription} from 'screens/ManageSubscriptions/actions';
import {websocketActions} from 'app/Scanning/actions';
import {addCompetitor, removeCompetitor} from 'components/Competitors/actions';
import {
  addKeyword,
  endUnassignKeyword,
  removeKeyword
} from 'components/Keywords/actions';
import {
  completeTask,
  endKeywordAssign,
  updateTasks
} from 'components/TasksPanel/actions';

import produce from 'immer';
import hydrate from 'lib/hydrate';
import {normalizeData} from 'misc/helper';

const plans_hydrated = hydrate('plans');
const current_plan = hydrate('config')?.current_plan;
export const initialState =
  plans_hydrated && plans_hydrated.length > 0
    ? normalizeData(plans_hydrated, 'uuid')
    : [];

export default (state = initialState, {type, payload}) =>
  produce(state, (draft) => {
    switch (type) {
      case setSubscription().type:
        draft[payload.plan].subscription = payload.data;
        break;
      case setProductDetails().type:
        draft[payload.plan].product_details.free = payload.status;
    }
    // XXX: Test due to this inclusion, we must disable its tests
    if (plans_hydrated && current_plan) {
      const {tasks, competitors, keywords, suggestions} =
        state?.[current_plan]?.site;

      switch (type) {
        case websocketActions.SCANNING_SINGLE_TASK_STARTED:
          draft[payload.uuid].site.tasks = tasks.map((task) =>
            task?.id === payload.task_id ? {...task, checking: true} : task
          );
          break;

        case websocketActions.SCANNING_SINGLE_TASK_COMPLETED:
          draft[payload.uuid].site.tasks = tasks.map((task) =>
            task?.id === payload.task_id
              ? {...task, ...payload.task, checking: false}
              : task
          );
          break;

        // No vamos a esperar al REFRESH_PLAN_DATA.
        // Vamos a actualizar el listado de pendings y keywords manualmente
        case endUnassignKeyword().type:
          draft[payload.uuid].site.keywords = payload.delete_from_tracked
            ? keywords.filter(({id}) => id !== payload.keyword.id)
            : keywords.map((keyword) =>
                keyword.id === payload.keyword.id
                  ? {...keyword, page: null}
                  : keyword
              );

          break;

        // Tampoco vamos a esperar al REFRESH_PLAN_DATA aquí
        // Si la keywords es asignada se debe eliminar de la lista sin esperar al refresh
        // Vamos a añadir mientras una página dumb temporal en el listado para evitar que aparezca
        case endKeywordAssign().type:
          draft[payload.uuid].site.keywords = keywords.find(
            (keyword) => keyword.id === payload.keyword.id
          )
            ? keywords.map((keyword) =>
                keyword.id === payload.keyword.id
                  ? {...keyword, page: {}}
                  : keyword
              )
            : [...keywords, payload.keyword];
          break;

        case removeCompetitor().type:
          draft[payload.uuid].site.competitors = competitors.filter(
            (competitor) => competitor.domain !== payload.competitorId
          );
          break;

        case addCompetitor().type:
          draft[payload.uuid].site.competitors = [
            ...competitors,
            payload.competitor
          ];
          break;

        case removeKeyword().type:
          draft[payload.uuid].site.keywords = keywords.filter(
            (keyword) => keyword.id !== payload.keyword_id
          );
          break;

        case addKeyword().type:
          draft[payload.uuid].site.keywords = [
            ...keywords,
            {...payload.keyword, is_tracked: 1}
          ];
          break;

        case updateTasks().type:
          if (payload.tasks) {
            draft[payload.uuid].site.tasks = payload.tasks;
          }

          if (payload.suggestions) {
            draft[payload.uuid].site.suggestions = payload.suggestions;
          }

          if (payload.task) {
            draft[payload.uuid].site.tasks = tasks.find(
              (task) => task.id === payload.task.id
            )
              ? tasks.map((task) =>
                  task.id === payload.task.id
                    ? {...task, ...payload.task}
                    : task
                )
              : [...tasks, payload.task];
          }

          if (payload.keyword) {
            draft[payload.uuid].site.keywords = keywords.find(
              (keyword) => keyword.id === payload.keyword.id
            )
              ? keywords.map((keyword) =>
                  keyword.id === payload.keyword.id
                    ? {...keyword, ...payload.keyword}
                    : keyword
                )
              : [...keywords, payload.keyword];
          }

          break;

        case completeTask().type:
          draft[payload.uuid].site.suggestions = suggestions.map(
            (suggestion) =>
              suggestion.task_id === payload.task.task_id
                ? {
                    ...task,
                    ...payload.task,
                    completed: true,
                    completed_at: new Date().toISOString().split('.')[0],
                    manual: true,
                    pass: true
                  }
                : task
          );
          break;

        // Actualizamos los datos del plan para añadir las cards en las tasks
        case updatePlan().type:
          draft[payload.plan.uuid] = payload.plan;
          break;

        /**
         * action: REFRESH_PLAN_DATA
         *
         * Resultado de AJAX de /ajax/plan/xxxx puede ser como respuesa a una
         * acción SCANNING_TASKS_COMPLETED desde el servidor o bien porque el
         * UI haya decidido actualizar los datos por otro motivo.
         * En cualquier caso, lo que debemos hacer es actualizar los datos
         * completos de "state.data.plans[<UUID>]"
         */
        case websocketActions.REFRESH_PLAN_DATA:
          const plan = draft[payload.uuid];
          if (plan) {
            draft[payload.uuid] = {...plan, ...payload.plan};
          }
          break;

        case resetSelectProfile().type:
          draft[payload.uuid || current_plan].site.config.google_analytics =
            null;
          break;

        case websocketActions.SCANNING_STARTED:
          if (draft[payload.uuid] || draft[current_plan]) {
            draft[payload.uuid || current_plan].site.error = null;
          }
          break;

        default:
          draft;
          break;
      }

      // Si llega al final del reducer sin haber entrado en ningún case, o sin
      // haber entrado en ningún "IF" tenemos que DEVOLVER SIEMPRE EL MISMO state
      // return state;
    }
  });
