import React from 'react';
import {connect} from 'react-redux';
import {modalHide} from 'components/UI/Modal/actions';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ButtonGroup,
  Button
} from '@marketgoo/components';

const modalContent = (
  <span
    dangerouslySetInnerHTML={{
      __html: __(
        '<p>Some customers might experience intermittent error messages connecting their Google Analytics account. ' +
          'We are aware of the issue and our engineering and operations teams are working on it urgently. ' +
          'The temporary issue is due to Google update for their app connection policy, reinforcing permission requirements that prevents connection to their services.</p>' +
          "<p>Please know that <strong>the issue won't affect any of your SEO progress or your subscriptions.</strong></p>" +
          '<p>Once everything is up and running we will send another update to notify the progress. Thanks for your understanding!</p>'
      )
    }}
  />
);

const GoogleAnalyticsIssue = ({closeModal}) => (
  <Modal open onClose={closeModal}>
    <ModalHeader
      title={__('Google Analytics is temporarily disabled')}
      intro={modalContent}
    />
    <ModalFooter>
      <ButtonGroup variant="reversed">
        <Button variant="primary" onClick={closeModal}>
          {__('Understood. Close this.')}
        </Button>
      </ButtonGroup>
    </ModalFooter>
  </Modal>
);

const mapDispatch = (dispatch) => ({
  closeModal: () => dispatch(modalHide())
});

export default connect(null, mapDispatch)(GoogleAnalyticsIssue);
