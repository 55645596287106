import React from 'react';
import {Modal, ModalContent, Typography} from '@marketgoo/components';
import {getThemesPath} from 'selectors';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

const OfsWrongScore = ({closeModal}) => {
  const {t} = useTranslation();
  const themesPath = useSelector(getThemesPath);

  return (
    <Modal
      open
      data-cy="optimize-wrong-modal"
      variant="center"
      onClose={closeModal}
    >
      <ModalContent className="ola-center">
        <img src={`${themesPath}/illustrations/oyp/web-wrong.svg`} />
        <Typography as="h2" m={{t: 6}}>
          {t('optimize.pendingmodal.title')}
        </Typography>
        <Typography m={{t: 1}}>{t('optimize.pendingmodal.text')}</Typography>
      </ModalContent>
    </Modal>
  );
};

export default OfsWrongScore;
