import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SearchField
} from '@marketgoo/components';
import {searchKeywordsRequest} from 'components/Keywords/actions';
import KeywordProgress from 'components/Keywords/components/KeywordProgress';
import KeywordResultTable from 'components/Keywords/components/KeywordResultTable';
import KeywordsSuggestedTable from 'components/Keywords/components/SuggestedTable';
import {required} from 'components/UI/Form/validators';
import {modalHide} from 'components/UI/Modal/actions';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {
  getCurrentPlan,
  getIsSearchingSuggestions,
  getKeywords,
  getMaxKeywordsGivenProduct,
  getSearchedKeywords,
  getSuggestedKeywords,
  getTrackedKeywords
} from 'selectors';

const KeywordInputSearch = ({disabled}) => {
  const {t} = useTranslation();
  const {handleSubmit, control} = useForm({
    defaultValues: {
      results: ''
    }
  });

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(searchKeywordsRequest(data.results));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        render={({
          field: {onChange, value, ref},
          formState: {isSubmitting, errors}
        }) => (
          <SearchField
            id="results"
            inputRef={ref}
            value={value}
            onChange={onChange}
            description={t('searchkeywords.form.description')}
            placeholder={t('searchkeywords.form.placeholder')}
            busy={isSubmitting}
            error={!!errors.results}
            disabled={disabled}
            textButton={t(
              `searchkeywords.form.${isSubmitting ? 'searching' : 'search'}`
            )}
          />
        )}
        name="results"
        control={control}
        rules={{required}}
      />
    </form>
  );
};

// Modal Search
const SearchKeywords = () => {
  const plan = useSelector(getCurrentPlan);
  const trackedKeywords = useSelector(getTrackedKeywords);
  const keywords = useSelector(getKeywords);
  const currentKeywords = keywords.length > 0 ? keywords : [];
  const suggestedKeywords = useSelector(getSuggestedKeywords);
  const product = plan?.product;
  const maxKeywords = useSelector(getMaxKeywordsGivenProduct(product));
  const disabledSearch = trackedKeywords.length >= maxKeywords;
  const searchedKeywords = useSelector(getSearchedKeywords);
  const searching = useSelector(getIsSearchingSuggestions);

  const dispatch = useDispatch();
  const {t} = useTranslation();

  const hideModal = () => dispatch(modalHide());

  return (
    <Modal open onClose={hideModal}>
      <ModalHeader
        title={t('searchkeywords.title')}
        intro={t('searchkeywords.intro')}
      >
        <KeywordProgress />
      </ModalHeader>
      <ModalContent>
        <KeywordInputSearch disabled={disabledSearch} />
      </ModalContent>

      {/* Aún no se ha hecho ninguna busqueda*/}
      {searching === undefined && suggestedKeywords.length > 0 && (
        <ModalContent variant="scroll">
          <KeywordsSuggestedTable
            currentKeywords={currentKeywords}
            suggestedKeywords={suggestedKeywords}
          />
        </ModalContent>
      )}

      {/* Ya se ha hecho una búsqueda*/}
      {searching !== undefined && (
        <ModalContent variant="scroll">
          {searching === false && searchedKeywords.length > 0 && (
            <KeywordResultTable
              currentKeywords={currentKeywords}
              searchedKeywords={searchedKeywords}
            />
          )}
        </ModalContent>
      )}

      {/* Búsqueda sin resultados*/}
      {searching === false && searchedKeywords.length == 0 && (
        <ModalFooter>
          <div className="no-results">
            <h2>{t('searchkeywords.noresults.title')}</h2>
            <p>{t('searchkeywords.noresults.description')}</p>
          </div>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default SearchKeywords;
