import produce from 'immer';
import {
  testingToolJourneyPopulate,
  testingToolJourneyUpdateRequest,
  testingToolJourneyUpdateResponse,
  testingToolTaskUpdateRequest,
  testingToolTaskUpdateResponse
} from 'testingtool/actions';

const initialState = {
  processing: undefined,
  available_journeys: null
};

export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case testingToolJourneyPopulate().type:
        draft.available_journeys = action.values.available_journeys;
        break;

      case testingToolJourneyUpdateRequest().type:
      case testingToolTaskUpdateRequest().type:
        draft.processing = true;
        break;

      case testingToolJourneyUpdateResponse().type:
      case testingToolTaskUpdateResponse().type:
        draft.processing = false;
        break;

      default:
        draft;
        break;
    }
  });
