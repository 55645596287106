const DOMAIN = 'keyword';

export const addKeyword = (keyword, uuid) => ({
  type: `${DOMAIN}/addKeyword`,
  payload: {
    keyword,
    uuid
  }
});

export const removeKeyword = (keyword_id, uuid, unassign = false) => ({
  type: `${DOMAIN}/removeKeyword`,
  payload: {
    keyword_id,
    uuid,
    unassign
  }
});

export const unassignKeyword = (taskId, keyword, deleteFromTracked) => ({
  type: `${DOMAIN}/unassignKeyword`,
  payload: {
    task_id: taskId,
    keyword: keyword,
    delete_from_tracked: deleteFromTracked
  }
});

export const endUnassignKeyword = (
  keyword,
  uuid,
  deleteFromTracked = false
) => ({
  type: `${DOMAIN}/endUnassignKeyword`,
  payload: {
    keyword,
    uuid,
    delete_from_tracked: deleteFromTracked
  }
});

export const searchReset = () => ({
  type: `${DOMAIN}/searchReset`
});

export const loadTemporalSuggested = (keywords) => ({
  type: `${DOMAIN}/loadTemporalSuggested`,
  payload: {
    keywords
  }
});

export const searchKeywordsRequest = (values) => ({
  type: `${DOMAIN}/searchKeywordsRequest`,
  payload: {
    values
  }
});

export const searchKeywordsResponse = (results) => ({
  type: `${DOMAIN}/searchKeywordsResponse`,
  payload: {
    results
  }
});
