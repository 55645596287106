import {
  Button,
  ButtonGroup,
  Field,
  Input,
  Panel,
  PanelContent
} from '@marketgoo/components';
import React from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {required, getMinLengthRule} from 'components/UI/Form/validators';

const Login = () => {
  const {t} = useTranslation();
  const {
    register,
    handleSubmit,
    formState: {errors, isSubmitting}
  } = useForm();

  const onSubmit = () => {
    document.getElementById('form_login').submit();
  };

  return (
    <Panel>
      <PanelContent>
        <div className="container">
          <h1>
            {t('login.title')}
            <span className="error-login email">
              {t('login.form.error.user.not.found')}
            </span>
            <span className="error-login password">
              {t('login.form.error.password.incorrect')}
            </span>
          </h1>

          <form
            id="form_login"
            action="/login"
            method="post"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Field
              id="email"
              label={t('g.email')}
              error={!!errors.email}
              description={errors && errors.email && errors.email.message}
            >
              <Input
                type="email"
                placeholder={t('login.form.email.placeholder')}
                {...register('email', {required})}
              />
            </Field>
            <Field
              id="password"
              label={t('g.password')}
              error={!!errors.password}
              description={errors && errors.password && errors.password.message}
            >
              <Input
                type="password"
                placeholder={t('login.form.password.placeholder', {count: 6})}
                {...register('password', {
                  required,
                  minLength: getMinLengthRule(6)
                })}
              />
            </Field>
            <ButtonGroup variant="reversed">
              <Button
                variant="primary"
                busy={isSubmitting && t('g.please.wait')}
              >
                {t('login.title')}
              </Button>
              <Button variant="secondary" href="/remember">
                {t('forgot.password.link.title')}
              </Button>
            </ButtonGroup>
          </form>
        </div>
        <div className="dontHaveAnAccountContainer">
          {t('signup.link.question')}
          <a href="/signup">{t('signup.link.title')}</a>
        </div>
      </PanelContent>
    </Panel>
  );
};

export default Login;
