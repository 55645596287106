import {
  Button,
  ButtonGroup,
  Check,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader
} from '@marketgoo/components';
import {removeKeyword} from 'components/Keywords/actions';
import {modalHide} from 'components/UI/Modal/actions';
import {getTitleFromPage} from 'misc/helper';
import React, {useState} from 'react';
import {connect} from 'react-redux';

const DeleteTrackedKeyword = ({
  closeModal,
  handleDelete,
  onDeleteCallback,
  keyword,
  uuid,
  page
}) => {
  // Title
  const pageTitle = getTitleFromPage(page);
  const keyword_name = keyword?.name;
  const keyword_id = keyword?.id;

  // Intro
  const intro = __(
    'All historical data related with keyword <strong>{keyword}</strong> will be removed.',
    {keyword: keyword_name}
  );

  const [unassign, setUnassign] = useState(false);

  return (
    <Modal open onClose={closeModal} variant="narrow">
      <ModalHeader
        title={__('Do you want to remove this keyword?')}
        intro={<span dangerouslySetInnerHTML={{__html: intro}} />}
      />
      {page && (
        <ModalContent>
          <Check type="checkbox" onChange={() => setUnassign(!unassign)}>
            <span
              dangerouslySetInnerHTML={{
                __html: __(
                  'This keyword is assigned to the page "<strong>{page}</strong>". Remove also from that page.',
                  {page: pageTitle}
                )
              }}
            />
          </Check>
        </ModalContent>
      )}

      <ModalFooter>
        <ButtonGroup variant="reversed">
          <Button variant="primary" onClick={() => closeModal()}>
            {__("Don't remove")}
          </Button>
          <Button
            variant="destructive"
            onClick={() => {
              handleDelete({keyword_id, uuid, unassign});
              onDeleteCallback(keyword_id);
            }}
          >
            {__('Remove the keyword')}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalHide()),
  handleDelete: ({keyword_id, uuid, unassign}) => {
    dispatch(removeKeyword(keyword_id, uuid, unassign));
    dispatch(modalHide());
  }
});

export default connect(null, mapDispatchToProps)(DeleteTrackedKeyword);
