export const downloadPDFIntent = () => ({
  type: 'pdf/downloadIntent'
});

export const downloadPDF = () => ({
  type: 'pdf/download'
});

export const pdfDownloaded = () => ({
  type: 'pdf/downloaded'
});
