import produce from 'immer';
import {
  downloadPDF,
  downloadPDFIntent,
  pdfDownloaded
} from 'lib/redux/actions/pdf';

export const initialState = {
  downloadIntents: 0,
  isDownloading: false
};

export default (state = initialState, {type}) =>
  produce(state, (draft) => {
    switch (type) {
      case downloadPDF().type:
        draft.isDownloading = true;
        break;

      case pdfDownloaded().type:
        draft.isDownloading = false;
        draft.downloadIntents = 0;
        break;

      case downloadPDFIntent().type:
        ++draft.downloadIntents;
        break;

      default:
        draft;
        break;
    }
  });
