import {
  Button,
  ButtonGroup,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader
} from '@marketgoo/components';
import {resetSelectProfile} from 'app/Data/plans/plan/actions';
import AnalyticsLogoLink from 'components/Analytics/AnalyticsLogoLink';
import List from 'components/UI/List';
import {modalHide} from 'components/UI/Modal/actions';
import PT from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';

const Profile = ({item}) => (
  <div className="list-profile-container">
    <div className="icon"></div>
    <div className="profile">
      <div>
        <div>
          <span className="title-container">{item?.website_url}</span>
        </div>
        <div>
          <span>{item?.name}</span>
        </div>
      </div>
      <div>{'[' + item?.web_property_id + ']'}</div>
    </div>
  </div>
);
const ProfileNotFound = () => (
  <div className="profile-not-found">
    <AnalyticsLogoLink />
    <h5>{__('The profile your are looking for is not on the list?')}</h5>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="//analytics.google.com/analytics"
      onClick={() => (window.location.href = '/')}
    >
      {__('Go to your Google Analytics account!')}
    </a>
  </div>
);
const EmptyData = () => (
  <div className="profile-not-found">
    <h4>{__('There are not any profile to connect.')}</h4>
  </div>
);
export class GoogleAnalyticsProfiles extends Component {
  constructor(props) {
    super(props);
    this.state = {selectedId: null, profile: null, sending: false};
    this._updateSelectedProfile = this._updateSelectedProfile.bind(this);
    this._checkSelection = this._checkSelection.bind(this);
  }

  _updateSelectedProfile(profile) {
    this.setState({selectedId: profile?.id, profile});
  }

  _checkSelection() {
    if (this.state.selectedId) {
      this.setState({sending: true});
      this.props.sendData(this.state.profile);
    }
  }

  render() {
    const {profiles, uuid, cancelProfile, closeModal} = this.props;
    const {sending, selectedId} = this.state;
    return (
      <Modal open onClose={closeModal}>
        <ModalHeader title={__('Connect Google Analytics')} />
        <ModalContent variant="scroll" className="modal-analytics-profiles">
          <AnalyticsLogoLink />
          {profiles && profiles.length > 0 ? (
            <div>
              <p>
                {__(
                  'You may have more than one property associated to your Analytics account. Choose the profile related to your site.'
                )}
              </p>
              <List
                data={profiles}
                components={[Profile]}
                onSelect={(item) => this._updateSelectedProfile(item)}
                selected={selectedId}
                selectedProp="id"
              />
            </div>
          ) : (
            <EmptyData />
          )}
          <ProfileNotFound />
        </ModalContent>
        <ModalFooter>
          <ButtonGroup variant="reversed">
            <Button onClick={() => cancelProfile(uuid)}>{__('Cancel')}</Button>
            <Button
              variant="primary"
              busy={sending && __('Please wait...')}
              disabled={!selectedId}
              onClick={() => this._checkSelection()}
            >
              {__('Connect')}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    );
  }
}

GoogleAnalyticsProfiles.propTypes = {
  profiles: PT.array,
  uuid: PT.string,
  cancelProfile: PT.func,
  closeModal: PT.func,
  sendData: PT.func
};

GoogleAnalyticsProfiles.defaultProps = {
  profiles: [],
  uuid: '',
  cancelProfile: () => {},
  sendData: () => {}
};

const mapState = ({data: {config}}, {profiles}) => ({
  profiles,
  uuid: config?.current_plan
});

const mapDispatch = (dispatch) => ({
  sendData: (profile) => dispatch({type: 'PROFILE_SELECTED', profile}),
  closeModal: () => {
    dispatch(modalHide());
  },
  cancelProfile: (uuid) => {
    dispatch(modalHide());
    dispatch(resetSelectProfile(uuid));
  }
});

export default connect(mapState, mapDispatch)(GoogleAnalyticsProfiles);
