import produce from 'immer';
import {
  saveEmailNotificationRequest,
  saveEmailNotificationsResponse,
  saveGeneralSettingsRequest,
  saveGeneralSettingsResponse
} from 'screens/Settings/actions';

// TODO: This should die, because this is not using react-hook-form
export const initialState = {
  general_settings_form_data: {},
  email_notifications_form_data: {},
  processing: false
};

export default (state = initialState, {type, payload, error = null}) =>
  produce(state, (draft) => {
    switch (type) {
      case saveEmailNotificationRequest().type:
      case saveGeneralSettingsRequest().type:
        draft.processing = true;
        break;

      case saveGeneralSettingsResponse().type:
        if (!error) {
          draft.general_settings_form_data = payload;
        }
        draft.processing = false;
        break;

      case saveEmailNotificationsResponse().type:
        if (!error) {
          draft.email_notifications_form_data = payload;
        }
        draft.processing = false;
        break;
      default:
        draft;
        break;
    }
  });
