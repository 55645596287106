export const availableCountries = [
  {emoji: '🇦🇫', value: 'af', label: __('Afghanistan')},
  {emoji: '🇦🇽', value: 'ax', label: __('Aland Islands')},
  {emoji: '🇦🇱', value: 'al', label: __('Albania')},
  {emoji: '🇩🇿', value: 'dz', label: __('Algeria')},
  {emoji: '🇦🇸', value: 'as', label: __('American Samoa')},
  {emoji: '🇦🇩', value: 'ad', label: __('Andorra')},
  {emoji: '🇦🇴', value: 'ao', label: __('Angola')},
  {emoji: '🇦🇮', value: 'ai', label: __('Anguilla')},
  {emoji: '🇦🇶', value: 'aq', label: __('Antarctica')},
  {emoji: '🇦🇬', value: 'ag', label: __('Antigua and Barbuda')},
  {emoji: '🇦🇷', value: 'ar', label: __('Argentina')},
  {emoji: '🇦🇲', value: 'am', label: __('Armenia')},
  {emoji: '🇦🇼', value: 'aw', label: __('Aruba')},
  {emoji: '🇦🇺', value: 'au', label: __('Australia')},
  {emoji: '🇦🇹', value: 'at', label: __('Austria')},
  {emoji: '🇦🇿', value: 'az', label: __('Azerbaijan')},
  {emoji: '🇧🇸', value: 'bs', label: __('Bahamas')},
  {emoji: '🇧🇭', value: 'bh', label: __('Bahrain')},
  {emoji: '🇧🇩', value: 'bd', label: __('Bangladesh')},
  {emoji: '🇧🇧', value: 'bb', label: __('Barbados')},
  {emoji: '🇧🇾', value: 'by', label: __('Belarus')},
  {emoji: '🇧🇪', value: 'be', label: __('Belgium')},
  {emoji: '🇧🇿', value: 'bz', label: __('Belize')},
  {emoji: '🇧🇯', value: 'bj', label: __('Benin')},
  {emoji: '🇧🇲', value: 'bm', label: __('Bermuda')},
  {emoji: '🇧🇹', value: 'bt', label: __('Bhutan')},
  {emoji: '🇧🇴', value: 'bo', label: __('Bolivia')},
  {emoji: '🇧🇦', value: 'ba', label: __('Bosnia and Herzegovina')},
  {emoji: '🇧🇼', value: 'bw', label: __('Botswana')},
  {emoji: '🇳🇴', value: 'bv', label: __('Bouvet Island')},
  {emoji: '🇧🇷', value: 'br', label: __('Brazil')},
  {emoji: '🇮🇴', value: 'io', label: __('British Indian Ocean Territory')},
  {emoji: '🇻🇬', value: 'vg', label: __('British Virgin Islands')},
  {emoji: '🇧🇳', value: 'bn', label: __('Brunei Darussalam')},
  {emoji: '🇧🇬', value: 'bg', label: __('Bulgaria')},
  {emoji: '🇧🇫', value: 'bf', label: __('Burkina Faso')},
  {emoji: '🇧🇮', value: 'bi', label: __('Burundi')},
  {emoji: '🇰🇭', value: 'kh', label: __('Cambodia')},
  {emoji: '🇨🇲', value: 'cm', label: __('Cameroon')},
  {emoji: '🇨🇦', value: 'ca', label: __('Canada')},
  {emoji: '🇨🇻', value: 'cv', label: __('Cape Verde')},
  {emoji: '🇰🇾', value: 'ky', label: __('Cayman Islands')},
  {emoji: '🇨🇫', value: 'cf', label: __('Central African Republic')},
  {emoji: '🇹🇩', value: 'td', label: __('Chad')},
  {emoji: '🇨🇱', value: 'cl', label: __('Chile')},
  {emoji: '🇨🇳', value: 'cn', label: __('China')},
  {emoji: '🇨🇽', value: 'cx', label: __('Christmas Island')},
  {emoji: '🇨🇨', value: 'cc', label: __('Cocos (Keeling) Islands')},
  {emoji: '🇨🇴', value: 'co', label: __('Colombia')},
  {emoji: '🇰🇲', value: 'km', label: __('Comoros')},
  {emoji: '🇨🇬', value: 'cg', label: __('Congo (Brazzaville)')},
  {emoji: '🇨🇩', value: 'cd', label: __('Congo, Democratic Republic')},
  {emoji: '🇨🇰', value: 'ck', label: __('Cook Islands')},
  {emoji: '🇨🇷', value: 'cr', label: __('Costa Rica')},
  {emoji: '🇭🇷', value: 'hr', label: __('Croatia')},
  {emoji: '🇨🇺', value: 'cu', label: __('Cuba')},
  {emoji: '🇨🇾', value: 'cy', label: __('Cyprus')},
  {emoji: '🇨🇿', value: 'cz', label: __('Czech Republic')},
  {emoji: '🇨🇮', value: 'ci', label: __("Côte d'Ivoire")},
  {emoji: '🇩🇰', value: 'dk', label: __('Denmark')},
  {emoji: '🇩🇯', value: 'dj', label: __('Djibouti')},
  {emoji: '🇩🇲', value: 'dm', label: __('Dominica')},
  {emoji: '🇩🇴', value: 'do', label: __('Dominican Republic')},
  {emoji: '🇪🇨', value: 'ec', label: __('Ecuador')},
  {emoji: '🇪🇬', value: 'eg', label: __('Egypt')},
  {emoji: '🇸🇻', value: 'sv', label: __('El Salvador')},
  {emoji: '🇬🇶', value: 'gq', label: __('Equatorial Guinea')},
  {emoji: '🇪🇷', value: 'er', label: __('Eritrea')},
  {emoji: '🇪🇪', value: 'ee', label: __('Estonia')},
  {emoji: '🇪🇹', value: 'et', label: __('Ethiopia')},
  {emoji: '🇫🇰', value: 'fk', label: __('Falkland Islands (Malvinas)')},
  {emoji: '🇫🇴', value: 'fo', label: __('Faroe Islands')},
  {emoji: '🇫🇯', value: 'fj', label: __('Fiji')},
  {emoji: '🇫🇮', value: 'fi', label: __('Finland')},
  {emoji: '🇫🇷', value: 'fr', label: __('France')},
  {emoji: '🇬🇫', value: 'gf', label: __('French Guiana')},
  {emoji: '🇵🇫', value: 'pf', label: __('French Polynesia')},
  {emoji: '🇹🇫', value: 'tf', label: __('French Southern Territories')},
  {emoji: '🇬🇦', value: 'ga', label: __('Gabon')},
  {emoji: '🇬🇲', value: 'gm', label: __('Gambia')},
  {emoji: '🇬🇪', value: 'ge', label: __('Georgia')},
  {emoji: '🇩🇪', value: 'de', label: __('Germany')},
  {emoji: '🇬🇭', value: 'gh', label: __('Ghana')},
  {emoji: '🇬🇮', value: 'gi', label: __('Gibraltar')},
  {emoji: '🇬🇷', value: 'gr', label: __('Greece')},
  {emoji: '🇬🇱', value: 'gl', label: __('Greenland')},
  {emoji: '🇬🇩', value: 'gd', label: __('Grenada')},
  {emoji: '🇬🇵', value: 'gp', label: __('Guadeloupe')},
  {emoji: '🇬🇺', value: 'gu', label: __('Guam')},
  {emoji: '🇬🇹', value: 'gt', label: __('Guatemala')},
  {emoji: '🇬🇬', value: 'gg', label: __('Guernsey')},
  {emoji: '🇬🇳', value: 'gn', label: __('Guinea')},
  {emoji: '🇬🇼', value: 'gw', label: __('Guinea-Bissau')},
  {emoji: '🇬🇾', value: 'gy', label: __('Guyana')},
  {emoji: '🇭🇹', value: 'ht', label: __('Haiti')},
  {emoji: '🇦🇺', value: 'hm', label: __('Heard Island and Mcdonald Islands')},
  {emoji: '🇭🇳', value: 'hn', label: __('Honduras')},
  {emoji: '🇭🇰', value: 'hk', label: __('Hong Kong')},
  {emoji: '🇭🇺', value: 'hu', label: __('Hungary')},
  {emoji: '🇮🇸', value: 'is', label: __('Iceland')},
  {emoji: '🇮🇳', value: 'in', label: __('India')},
  {emoji: '🇮🇩', value: 'id', label: __('Indonesia')},
  {emoji: '🇮🇷', value: 'ir', label: __('Iran')},
  {emoji: '🇮🇶', value: 'iq', label: __('Iraq')},
  {emoji: '🇮🇪', value: 'ie', label: __('Ireland')},
  {emoji: '🇮🇲', value: 'im', label: __('Isle of Man')},
  {emoji: '🇮🇱', value: 'il', label: __('Israel')},
  {emoji: '🇮🇹', value: 'it', label: __('Italy')},
  {emoji: '🇯🇲', value: 'jm', label: __('Jamaica')},
  {emoji: '🇯🇵', value: 'jp', label: __('Japan')},
  {emoji: '🇯🇪', value: 'je', label: __('Jersey')},
  {emoji: '🇯🇴', value: 'jo', label: __('Jordan')},
  {emoji: '🇰🇿', value: 'kz', label: __('Kazakhstan')},
  {emoji: '🇰🇪', value: 'ke', label: __('Kenya')},
  {emoji: '🇰🇮', value: 'ki', label: __('Kiribati')},
  {emoji: '🇰🇼', value: 'kw', label: __('Kuwait')},
  {emoji: '🇰🇬', value: 'kg', label: __('Kyrgyzstan')},
  {emoji: '🇱🇦', value: 'la', label: __('Lao PDR')},
  {emoji: '🇱🇻', value: 'lv', label: __('Latvia')},
  {emoji: '🇱🇧', value: 'lb', label: __('Lebanon')},
  {emoji: '🇱🇸', value: 'ls', label: __('Lesotho')},
  {emoji: '🇱🇷', value: 'lr', label: __('Liberia')},
  {emoji: '🇱🇾', value: 'ly', label: __('Libya')},
  {emoji: '🇱🇮', value: 'li', label: __('Liechtenstein')},
  {emoji: '🇱🇹', value: 'lt', label: __('Lithuania')},
  {emoji: '🇱🇺', value: 'lu', label: __('Luxembourg')},
  {emoji: '🇲🇴', value: 'mo', label: __('Macao')},
  {emoji: '🇲🇰', value: 'mk', label: __('Macedonia')},
  {emoji: '🇲🇬', value: 'mg', label: __('Madagascar')},
  {emoji: '🇲🇼', value: 'mw', label: __('Malawi')},
  {emoji: '🇲🇾', value: 'my', label: __('Malaysia')},
  {emoji: '🇲🇻', value: 'mv', label: __('Maldives')},
  {emoji: '🇲🇱', value: 'ml', label: __('Mali')},
  {emoji: '🇲🇹', value: 'mt', label: __('Malta')},
  {emoji: '🇲🇭', value: 'mh', label: __('Marshall Islands')},
  {emoji: '🇲🇶', value: 'mq', label: __('Martinique')},
  {emoji: '🇲🇷', value: 'mr', label: __('Mauritania')},
  {emoji: '🇲🇺', value: 'mu', label: __('Mauritius')},
  {emoji: '🇾🇹', value: 'yt', label: __('Mayotte')},
  {emoji: '🇲🇽', value: 'mx', label: __('Mexico')},
  {emoji: '🇫🇲', value: 'fm', label: __('Micronesia')},
  {emoji: '🇲🇩', value: 'md', label: __('Moldova')},
  {emoji: '🇲🇨', value: 'mc', label: __('Monaco')},
  {emoji: '🇲🇳', value: 'mn', label: __('Mongolia')},
  {emoji: '🇲🇪', value: 'me', label: __('Montenegro')},
  {emoji: '🇲🇸', value: 'ms', label: __('Montserrat')},
  {emoji: '🇲🇦', value: 'ma', label: __('Morocco')},
  {emoji: '🇲🇿', value: 'mz', label: __('Mozambique')},
  {emoji: '🇲🇲', value: 'mm', label: __('Myanmar')},
  {emoji: '🇳🇦', value: 'na', label: __('Namibia')},
  {emoji: '🇳🇷', value: 'nr', label: __('Nauru')},
  {emoji: '🇳🇵', value: 'np', label: __('Nepal')},
  {emoji: '🇳🇱', value: 'nl', label: __('Netherlands')},
  {emoji: '🇧🇶', value: 'an', label: __('Netherlands Antilles')},
  {emoji: '🇳🇨', value: 'nc', label: __('New Caledonia')},
  {emoji: '🇳🇿', value: 'nz', label: __('New Zealand')},
  {emoji: '🇳🇮', value: 'ni', label: __('Nicaragua')},
  {emoji: '🇳🇪', value: 'ne', label: __('Niger')},
  {emoji: '🇳🇬', value: 'ng', label: __('Nigeria')},
  {emoji: '🇳🇺', value: 'nu', label: __('Niue')},
  {emoji: '🇳🇫', value: 'nf', label: __('Norfolk Island')},
  {emoji: '🇰🇵', value: 'kp', label: __('North Korea')},
  {emoji: '🇲🇵', value: 'mp', label: __('Northern Mariana Islands')},
  {emoji: '🇳🇴', value: 'no', label: __('Norway')},
  {emoji: '🇴🇲', value: 'om', label: __('Oman')},
  {emoji: '🇵🇰', value: 'pk', label: __('Pakistan')},
  {emoji: '🇵🇼', value: 'pw', label: __('Palau')},
  {emoji: '🇵🇸', value: 'ps', label: __('Palestine')},
  {emoji: '🇵🇦', value: 'pa', label: __('Panama')},
  {emoji: '🇵🇬', value: 'pg', label: __('Papua New Guinea')},
  {emoji: '🇵🇾', value: 'py', label: __('Paraguay')},
  {emoji: '🇵🇪', value: 'pe', label: __('Peru')},
  {emoji: '🇵🇭', value: 'ph', label: __('Philippines')},
  {emoji: '🇵🇳', value: 'pn', label: __('Pitcairn')},
  {emoji: '🇵🇱', value: 'pl', label: __('Poland')},
  {emoji: '🇵🇹', value: 'pt', label: __('Portugal')},
  {emoji: '🇵🇷', value: 'pr', label: __('Puerto Rico')},
  {emoji: '🇶🇦', value: 'qa', label: __('Qatar')},
  {emoji: '🇷🇴', value: 'ro', label: __('Romania')},
  {emoji: '🇷🇺', value: 'ru', label: __('Russian Federation')},
  {emoji: '🇷🇼', value: 'rw', label: __('Rwanda')},
  {emoji: '🇷🇪', value: 're', label: __('Réunion')},
  {emoji: '🇸🇭', value: 'sh', label: __('Saint Helena')},
  {emoji: '🇰🇳', value: 'kn', label: __('Saint Kitts and Nevis')},
  {emoji: '🇱🇨', value: 'lc', label: __('Saint Lucia')},
  {emoji: '🇵🇲', value: 'pm', label: __('Saint Pierre and Miquelon')},
  {emoji: '🇻🇨', value: 'vc', label: __('Saint Vincent and Grenadines')},
  {emoji: '🇧🇱', value: 'bl', label: __('Saint-Barthélemy')},
  {emoji: '🇫🇷', value: 'mf', label: __('Saint-Martin (French part)')},
  {emoji: '🇼🇸', value: 'ws', label: __('Samoa')},
  {emoji: '🇸🇲', value: 'sm', label: __('San Marino')},
  {emoji: '🇸🇹', value: 'st', label: __('Sao Tome and Principe')},
  {emoji: '🇸🇦', value: 'sa', label: __('Saudi Arabia')},
  {emoji: '🇸🇳', value: 'sn', label: __('Senegal')},
  {emoji: '🇷🇸', value: 'rs', label: __('Serbia')},
  {emoji: '🇸🇨', value: 'sc', label: __('Seychelles')},
  {emoji: '🇸🇱', value: 'sl', label: __('Sierra Leone')},
  {emoji: '🇸🇬', value: 'sg', label: __('Singapore')},
  {emoji: '🇸🇰', value: 'sk', label: __('Slovakia')},
  {emoji: '🇸🇮', value: 'si', label: __('Slovenia')},
  {emoji: '🇸🇧', value: 'sb', label: __('Solomon Islands')},
  {emoji: '🇸🇴', value: 'so', label: __('Somalia')},
  {emoji: '🇿🇦', value: 'za', label: __('South Africa')},
  {
    emoji: '🇬🇸',
    value: 'gs',
    label: __('South Georgia and the South Sandwich Islands')
  },
  {emoji: '🇰🇷', value: 'kr', label: __('South Korea')},
  {emoji: '🇸🇸', value: 'ss', label: __('South Sudan')},
  {emoji: '🇪🇸', value: 'es', label: __('Spain')},
  {emoji: '🇱🇰', value: 'lk', label: __('Sri Lanka')},
  {emoji: '🇸🇩', value: 'sd', label: __('Sudan')},
  {emoji: '🇸🇷', value: 'sr', label: __('Suriname')},
  {emoji: '🇳🇴', value: 'sj', label: __('Svalbard and Jan Mayen Islands')},
  {emoji: '🇸🇿', value: 'sz', label: __('Swaziland')},
  {emoji: '🇸🇪', value: 'se', label: __('Sweden')},
  {emoji: '🇨🇭', value: 'ch', label: __('Switzerland')},
  {emoji: '🇸🇾', value: 'sy', label: __('Syria')},
  {emoji: '🇹🇼', value: 'tw', label: __('Taiwan, Republic of China')},
  {emoji: '🇹🇯', value: 'tj', label: __('Tajikistan')},
  {emoji: '🇹🇿', value: 'tz', label: __('Tanzania')},
  {emoji: '🇹🇭', value: 'th', label: __('Thailand')},
  {emoji: '🇹🇱', value: 'tl', label: __('Timor-Leste')},
  {emoji: '🇹🇬', value: 'tg', label: __('Togo')},
  {emoji: '🇹🇰', value: 'tk', label: __('Tokelau')},
  {emoji: '🇹🇴', value: 'to', label: __('Tonga')},
  {emoji: '🇹🇹', value: 'tt', label: __('Trinidad and Tobago')},
  {emoji: '🇹🇳', value: 'tn', label: __('Tunisia')},
  {emoji: '🇹🇷', value: 'tr', label: __('Turkey')},
  {emoji: '🇹🇲', value: 'tm', label: __('Turkmenistan')},
  {emoji: '🇹🇨', value: 'tc', label: __('Turks and Caicos Islands')},
  {emoji: '🇹🇻', value: 'tv', label: __('Tuvalu')},
  {emoji: '🇺🇬', value: 'ug', label: __('Uganda')},
  {emoji: '🇺🇦', value: 'ua', label: __('Ukraine')},
  {emoji: '🇦🇪', value: 'ae', label: __('United Arab Emirates')},
  {emoji: '🇬🇧', value: 'gb', label: __('United Kingdom')},
  {emoji: '🇺🇸', value: 'us', label: __('United States')},
  {emoji: '🇺🇸', value: 'um', label: __('United States Minor Outlying Islands')},
  {emoji: '🇺🇾', value: 'uy', label: __('Uruguay')},
  {emoji: '🇺🇿', value: 'uz', label: __('Uzbekistan')},
  {emoji: '🇻🇺', value: 'vu', label: __('Vanuatu')},
  {emoji: '🇻🇦', value: 'va', label: __('Vatican City State')},
  {emoji: '🇻🇪', value: 've', label: __('Venezuela')},
  {emoji: '🇻🇳', value: 'vn', label: __('Viet Nam')},
  {emoji: '🇻🇮', value: 'vi', label: __('Virgin Islands')},
  {emoji: '🇼🇫', value: 'wf', label: __('Wallis and Futuna Islands')},
  {emoji: '🇪🇭', value: 'eh', label: __('Western Sahara')},
  {emoji: '🇾🇪', value: 'ye', label: __('Yemen')},
  {emoji: '🇿🇲', value: 'zm', label: __('Zambia')},
  {emoji: '🇿🇼', value: 'zw', label: __('Zimbabwe')}
];

export const goals = [
  {
    label: 'wizard.goal.option1.title',
    value: 'promote-something'
  },
  {
    label: 'wizard.goal.option2.title',
    value: 'facilitating-networking'
  },
  {
    label: 'wizard.goal.option3.title',
    value: 'multimedia'
  },
  {
    label: 'wizard.goal.option4.title',
    value: 'share-news'
  },
  {
    label: 'wizard.goal.option5.title',
    value: 'sell-things-online'
  },
  {
    label: 'wizard.goal.option6.title',
    value: 'other'
  }
];

export const industries = [
  {
    label: 'wizard.industry.question.option1',
    value: 'finance'
  },
  {
    label: 'wizard.industry.question.option2',
    value: 'education'
  },
  {
    label: 'wizard.industry.question.option3',
    value: 'tech-ecommerce'
  },
  {
    label: 'wizard.industry.question.option4',
    value: 'entertainment'
  },
  {
    label: 'wizard.industry.question.option5',
    value: 'food-services'
  },
  {
    label: 'wizard.industry.question.option6',
    value: 'real-estate'
  },
  {
    label: 'wizard.industry.question.option7',
    value: 'healthcare-wellness'
  },
  {
    label: 'wizard.industry.question.option8',
    value: 'travel-hospitality'
  },
  {
    label: 'wizard.industry.question.option9',
    value: 'home-services'
  },
  {
    label: 'wizard.industry.question.option10',
    value: 'other'
  }
];

export const EMAILS_NOTIFICATION_CONFIG = [
  'config_monthly_email',
  'config_critical-issues_email',
  'config_alerts_email'
];

export const OYP_SUCCESS_SCORE = 85;
