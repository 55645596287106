import {Tag} from '@marketgoo/components';
const KeywordCompetition = ({competition = null}) => {
  if (competition !== null && competition >= 0) {
    if (competition <= 0.8 && competition > 0.4)
      return <Tag variant="warning">{__('Medium')}</Tag>;
    if (competition <= 0.4 && competition > 0.005)
      return <Tag variant="success">{__('Easy')}</Tag>;
    if (competition <= 0.005 && competition >= 0)
      return <Tag variant="success">{__('Very easy')}</Tag>;
    return <Tag variant="error">{__('Hard')}</Tag>;
  }
  return <Tag>{__('Not available')}</Tag>;
};

export default KeywordCompetition;
