import {
  paywallError,
  paywallRequest,
  paywallToggleCreditCard
} from 'components/Paywall/actions';
import produce from 'immer';

export const initialState = {
  processing: null,
  error: null,
  update_card: null
};

export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case paywallRequest().type:
        draft.processing = true;
        draft.error = null;
        break;

      case paywallError().type:
        draft.processing = false;
        draft.error = action;
        break;

      case paywallToggleCreditCard().type:
        draft.update_card = !draft.update_card;
        break;

      default:
        draft;
        break;
    }
  });
