import {Modal, ModalContent} from '@marketgoo/components';
import cx from 'classnames';
import {ProductDescription} from 'components/Modals/Upgrade/ProductDescription';
import {modalHide} from 'components/UI/Modal/actions';
import React from 'react';
import {connect} from 'react-redux';

const Product = ({name, active, features}) => {
  const featuresType = active ? 'from' : 'to';
  return (
    <div className={cx(['product-comparison-section', active && 'active'])}>
      <h1>{name}</h1>
      <ProductDescription features={features} product={featuresType} />
    </div>
  );
};

const ProductComparison = ({closeModal, product_from, product_to}) => {
  return (
    <Modal open variant="center" onClose={closeModal}>
      <ModalContent className="product-comparison">
        <div className="modal-content">
          <Product
            name={product_from?.name}
            features={product_from?.features}
            active={true}
          />
          <Product name={product_to?.name} features={product_to?.features} />
        </div>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = ({data: {config}}) => {
  const products = config?.dealer?.products;
  const product_from = products.filter((prod) => prod?.free == 1).first();
  const product_to = products.filter((prod) => prod?.free !== 1).first();

  return {
    product_from,
    product_to
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalHide())
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductComparison);
