import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ButtonGroup,
  Button,
  Icon
} from '@marketgoo/components';
import {modalHide} from 'components/UI/Modal/actions';
import {useTranslation, Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {getSupportEmail} from 'selectors';

const DefaultError = ({closeModal, to, buttonKeyText, supportEmail}) => {
  const {t} = useTranslation();
  const ButtonWrapper = to ? Link : React.Fragment;

  return (
    <Modal open variant="narrow" onClose={closeModal}>
      <ModalContent className="ola-center">
        <div className="modal-scan-blocked-icon">
          <Icon name="warning" size="big" />
        </div>
        <p className="ola-margin-top-2 ola-font-3-regular">
          {t('scanning.modal.error.title')}
        </p>
        <p className="ola-margin-top-2 ola-font-2-regular ola-color-neutral-700">
          {t('scanning.modal.error.description')}
        </p>
        {buttonKeyText && to ? (
          <ModalFooter>
            <ButtonGroup className="ola-margin-top-1" variant="center">
              {
                <ButtonWrapper to={to}>
                  <Button onClick={closeModal} variant="primary">
                    {t(buttonKeyText)}
                  </Button>
                </ButtonWrapper>
              }
            </ButtonGroup>
          </ModalFooter>
        ) : (
          <p className="ola-margin-top-2 ola-font-2-regular ola-color-neutral-700">
            <Trans
              i18nKey="scanning.modal.error.contact"
              components={[
                <a
                  href={`mailto:${supportEmail}`}
                  target="_blank"
                  rel="noreferrer noopener"
                />
              ]}
            />
          </p>
        )}
      </ModalContent>
    </Modal>
  );
};

const Imunify360Error = ({closeModal, supportEmail}) => {
  const {t} = useTranslation();

  return (
    <Modal open variant="narrow" onClose={closeModal}>
      <ModalContent className="ola-center">
        <div className="modal-scan-blocked-icon">
          <Icon name="warning" size="big" />
        </div>
        <p className="ola-margin-top-2 ola-font-3-regular">
          {t('scanning.modal.error.imunify360.title')}
        </p>
        <p className="ola-margin-top-2 ola-font-2-regular ola-color-neutral-700">
          <Trans
            i18nKey="scanning.modal.error.imunify360.description"
            components={[
              <a
                href={`mailto:${supportEmail}`}
                target="_blank"
                rel="noreferrer noopener"
              />
            ]}
          />
        </p>
      </ModalContent>
    </Modal>
  );
};

const ScanBlockedMessage = ({
  buttonKeyText = null,
  to = null,
  error = null
}) => {
  const supportEmail = useSelector(getSupportEmail);
  const dispatch = useDispatch();
  const closeModal = () => dispatch(modalHide());

  if (error && error.http_status === 701) {
    return (
      <Imunify360Error supportEmail={supportEmail} closeModal={closeModal} />
    );
  }

  return (
    <DefaultError
      supportEmail={supportEmail}
      to={to}
      buttonKeyText={buttonKeyText}
      closeModal={closeModal}
    />
  );
};

export default ScanBlockedMessage;
