export const SAVE_LANGUAGE_REQUEST = 'SAVE_LANGUAGE_REQUEST';
export const SAVE_LANGUAGE_RESPONSE = 'SAVE_LANGUAGE_RESPONSE';
export const SAVE_THEME_REQUEST = 'SAVE_THEME_REQUEST';
export const SAVE_THEME_RESPONSE = 'SAVE_THEME_RESPONSE';

export const saveLanguageRequest = (language) => {
  return {
    type: SAVE_LANGUAGE_REQUEST,
    language
  };
};

export const saveLanguageResponse = (language, error) => {
  return {
    type: SAVE_LANGUAGE_RESPONSE,
    language,
    error
  };
};

export const saveThemeRequest = (theme) => {
  return {
    type: SAVE_THEME_REQUEST,
    theme
  };
};

export const saveThemeResponse = (theme, error) => {
  return {
    type: SAVE_THEME_RESPONSE,
    theme,
    error
  };
};
