const DOMAIN = 'contact';

export const saveContactDataRequest = (values, extras) => ({
  type: `${DOMAIN}/saveContactDataRequest`,
  values,
  extras
});

export const saveContactDataResponse = (values) => ({
  type: `${DOMAIN}/saveContactDataResponse`,
  values
});
