import {ButtonIcon, Table, Tooltip} from '@marketgoo/components';
import cx from 'classnames';
import {addKeyword} from 'components/Keywords/actions';
import {filterTrackedKeywords, findKeyword} from 'components/Keywords/filters';
import numeral from 'misc/Numeral';
import {connect} from 'react-redux';
import KeywordCompetition from './KeywordCompetition';

const KeywordResultTable = ({
  uuid,
  trackedKeywords,
  searchedKeywords,
  searchKeyword,
  full,
  add
}) => {
  return (
    <Table gridTemplateColumns="2fr 1fr 1fr 1fr">
      <Table.Header>
        <Table.Cell>{__('Keywords found')}</Table.Cell>
        <Table.Cell>
          {__('Competition')}
          <Tooltip trigger={<ButtonIcon as="span" icon="help" />}>
            {__(
              'Indication on how hard is for your website to rank the keyword on search engines. Easy keywords are more likely to bring traffic to your website'
            )}
          </Tooltip>
        </Table.Cell>
        <Table.Cell>
          {__('Search volume')}
          <Tooltip trigger={<ButtonIcon as="span" icon="help" />}>
            {__(
              'The number of times this exact search term was searched for in the past month.'
            )}
          </Tooltip>
        </Table.Cell>
        <Table.Cell></Table.Cell>
      </Table.Header>
      {searchedKeywords.map((keyword, idx) => {
        let alreadyTracked = !!findKeyword(trackedKeywords, keyword?.id);
        let matchWithSearched = searchKeyword === keyword?.name;

        return (alreadyTracked && matchWithSearched) || !alreadyTracked ? (
          <Table.Row
            className={cx({added: alreadyTracked && matchWithSearched})}
            key={idx}
          >
            <Table.Cell>
              {keyword?.name}
              {alreadyTracked && matchWithSearched ? (
                <strong>
                  {' '}
                  <i>{__('(already tracked)')}</i>
                </strong>
              ) : (
                <></>
              )}
            </Table.Cell>
            <Table.Cell>
              <KeywordCompetition competition={keyword?.competition} />
            </Table.Cell>
            <Table.Cell>
              {keyword?.searchVolume
                ? numeral(keyword?.searchVolume, 'value', 'miles', '0')
                    .replace(' ', '')
                    .toUpperCase()
                : 0}
            </Table.Cell>
            <Table.Cell>
              {alreadyTracked && matchWithSearched ? (
                <ButtonIcon disabled={true} icon="plus" />
              ) : (
                <ButtonIcon
                  disabled={full}
                  onClick={() => add(keyword, uuid)}
                  icon="plus"
                />
              )}
            </Table.Cell>
          </Table.Row>
        ) : (
          <></>
        );
      })}
    </Table>
  );
};

const mapStateToProps = (
  {data: {plans, config}, keywords},
  {currentKeywords, searchedKeywords}
) => {
  const plan = plans[config?.current_plan];
  const product = plan?.product;
  const searchKeyword = keywords?.search_keyword;
  const maxKeywords =
    config?.dealer?.products?.[product]?.features?.max_keywords;
  const trackedKeywords = filterTrackedKeywords(currentKeywords);
  return {
    uuid: config?.current_plan,
    trackedKeywords,
    searchedKeywords,
    searchKeyword,
    full: trackedKeywords.length >= maxKeywords
  };
};

const mapDispatchToProps = (dispatch) => ({
  add: (keyword, uuid) => {
    dispatch(addKeyword(keyword, uuid));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(KeywordResultTable);
