import {modalShow} from 'components/UI/Modal/actions';
import {addSiteRequest} from 'screens/AddSite/actions';
import produce from 'immer';

const initialState = {
  processing: false
};

export default (state = initialState, {type}) =>
  produce(state, (draft) => {
    switch (type) {
      case addSiteRequest().type:
        draft.processing = true;
        break;

      case modalShow().type:
        draft.processing = false;
        break;

      default:
        draft;
        break;
    }
  });
