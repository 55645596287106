/**
 * Mensajes específicos para el scanning de los sites. **TODOS** los mensajes
 * que se listan a continuación SON ENVIADOS DE FORMA ASINCRONA por el servidor
 * de scanning PARA TODOS LOS SITES que tengamos en el store. Es decir, que
 * NO DEBEMOS ASUMIR que los mensajes SCANNING_* son del site actual.
 *
 *  type: SCANNING_STARTED          ***ENVIADO POR EL SERVIDOR***
 *
 *        Enviado por el servidor cuando está empezando un nuevo scanning, ya
 *        sea por petición nuestra, o porque es un site nuevo o bien porque
 *        está empezando un scanning diario automático.
 *
 *        payload: {
 *            uuid: [uuid] => UUID del sitio que está empezando este scan
 *            created_at: [timestamp] => Fecha/Hora de cuando se empezó el scan (en hora UTC)
 *            canonical: [URL canónica de la web] => Puede ser diferente del site.url
 *        }
 *
 *
 *  type: SCANNING_PROGRESS         ***ENVIADO POR EL SERVIDOR***
 *
 *        Enviado por el servidor a medida que va avanzando el scan. Como en
 *        todos los eventos de SCANNING_* puede que corresponda, o no, con el
 *        site que tenemos seleccionado actualmente por el usuario.
 *
 *        payload: {
 *            uuid: [uuid] => UUID del sitio que se está escaneando
 *            created_at: [timestamp] => Fecha/Hora de cuando se empezó el scan (en hora UTC)
 *            canonical: [URL canónica de la web] => Puede ser diferente del site.url
 *            elapsed_time: [segundos] => Número de segundos que lleva escaneando
 *            pages_found: [integer] => Número de páginas encontradas hasta este momento
 *            pages_read: [integer] => Número de páginas leidas
 *        }
 *
 *
 *  type: SCANNING_COMPLETED        ***ENVIADO POR EL SERVIDOR***
 *
 *        Enviado por el servidor cuando ha terminado de escanear el sitio.
 *        PERO ESTO NO QUIERE DECIR QUE YA PODAMOS REFRESCAR, aún faltan
 *        analizar las tareas. Al recibir el SCANNING_COMPLETED ya podemos
 *        informar al usuario que el scan ha terminado (pages_read == pages_found)
 *        pero que estamos "analizando su web". El proceso no terminará hasta
 *        no recibir el correspondiente SCANNING_TASKS_COMPLETED.
 *
 *        payload: {
 *            uuid: [uuid] => UUID del sitio que acaba de terminar el scan
 *            created_at: [timestamp] => Fecha/Hora de cuando se empezó el scan (en hora UTC)
 *            canonical: [URL canónica de la web] => Puede ser diferente del site.url
 *            elapsed_time: [segundos] => Número de segundos que duró el escaneo
 *            pages_found: [integer] => Número de páginas encontradas hasta este momento
 *            pages_read: [integer] => Número de páginas leidas
 *        }
 *
 *
 *  type: SCANNING_TASKS_COMPLETED  ***ENVIADO POR EL SERVIDOR***
 *
 *        Enviado por el servidor cuando las tareas se han completado, ahora
 *        es cuando es seguro leer las tareas del site que acaba de terminar.
 *
 *        payload: {
 *            uuid: [uuid] => UUID del sitio
 *        }
 *
 *
 *  type: SCANNING_SINGLE_TASK_COMPLETED  ***ENVIADO POR EL SERVIDOR***
 *
 *        Enviado por el servidor cuando una tarea se ha completado, debemos
 *        leer la tarea indicada en el payload y refrescar los datos.
 *
 *        payload: {
 *            uuid: [uuid] => UUID del sitio
 *            task_id: [task_id] => ID de la tarea a actualizar
 *        }
 *
 */

export const websocketActions = {
  SCANNING_STARTED: 'SCANNING_STARTED',
  SCANNING_PROGRESS: 'SCANNING_PROGRESS',
  SCANNING_COMPLETED: 'SCANNING_COMPLETED',
  SCANNING_TASKS_COMPLETED: 'SCANNING_TASKS_COMPLETED',
  SCANNING_SINGLE_TASK_STARTED: 'SCANNING_SINGLE_TASK_STARTED',
  SCANNING_SINGLE_TASK_COMPLETED: 'SCANNING_SINGLE_TASK_COMPLETED',
  SCANNING_SINGLE_TASK_FAILED: 'SCANNING_SINGLE_TASK_FAILED',
  REFRESH_PLAN_DATA: 'REFRESH_PLAN_DATA'
};

/**
 * Acciones que ENVIAREMOS NOSOTROS para realizar acciones sobre el escaneo
 */

/**
 * acción: startScanning // START_SCANNING
 *
 * Se tiene que enviar al store cuando queramos iniciar un scan bajo demanda.
 * Ejemplo:    dispatch(startScanning("UUID"))
 *
 */
export const START_SCANNING = 'START_SCANNING';
export function startScanning(uuid) {
  return {
    type: 'START_SCANNING',
    payload: {uuid}
  };
}

/**
 * acción: ANALYZING_WIZARD_PRESENT
 *
 * Enviada por la página de "/analyzing" (Wizard) cuando se monta el componente
 * React en la página. Hay que tener en cuenta que al estar desacoplado, el
 * componente del Wizard no sabe si hay un scanning pendiente ya que éste no se
 * envía con el payload del HTML y sólo se actualiza al recibir las acciones
 * de SCANNING_* desde el servidor, con lo que muchas veces se queda "parado".
 * Al enviar una acción ANALYZING_WIZARD_PRESENT podemos empezar un "saga" que
 * ejerza una acción de "race" entre un timeout y la recepción de mensajes para
 * así poder enviar al usuario al Dashboard en caso de que no se reciban
 * mensajes desde el servidor pasados ciertos segundos.
 */
export const ANALYZING_WIZARD_PRESENT = 'ANALYZING_WIZARD_PRESENT';

export const SITE_UNREACHABLE_RETRY = 'SITE_UNREACHABLE_RETRY';
export function retrySiteUnreachable(uuid) {
  return {
    type: 'SITE_UNREACHABLE_RETRY',
    payload: {uuid}
  };
}
