const DOMAIN = 'addSite';

export const addSiteRequest = (values) => ({
  type: `${DOMAIN}/addSiteRequest`,
  values
});

export const addSiteResponse = (values) => ({
  type: `${DOMAIN}/addSiteResponse`,
  values
});
