import {
  loadTemporalSuggested,
  searchKeywordsRequest,
  searchKeywordsResponse,
  searchReset
} from 'components/Keywords/actions';
import {modalHide, modalShow} from 'components/UI/Modal/actions';
import produce from 'immer';
import hydrate from 'lib/hydrate';

export const initialState = {
  related: hydrate('related_keywords') || [],
  search_keyword: null,
  searching: undefined,
  search_results: [],
  temporal_suggested: []
};

export default (state = initialState, {type, payload, modal, show}) =>
  produce(state, (draft) => {
    switch (type) {
      case searchKeywordsRequest().type:
        draft.searching = true;
        draft.search_keyword = payload.values;
        break;

      case searchKeywordsResponse().type:
        draft.searching = false;
        draft.search_results = payload.results;
        break;

      case searchReset().type:
        draft.searching = undefined;
        draft.search_results = [];
        break;

      case loadTemporalSuggested().type:
        draft.temporal_suggested = payload.keywords;
        break;

      case modalHide().type:
        draft.temporal_suggested = [];
        draft.search_results = [];
        draft.search_keyword = null;
        break;

      case modalShow().type:
        if (modal == 'keywords' && !show) {
          draft.searching = undefined;
        }
        break;
      default:
        draft;
        break;
    }
  });
