import {showNotification} from 'components/UI/Notifications/actions';
import config from 'lib/application-config';
import {endpoints} from 'lib/redux/sagas/api';
import {request} from 'lib/redux/sagas/request';
import {takeEvery} from 'redux-saga';
import {call, put, select} from 'redux-saga/effects';
import {
  testingToolJourneyPopulate,
  testingToolJourneyUpdateRequest,
  testingToolJourneyUpdateResponse,
  testingToolTaskUpdateRequest,
  testingToolTaskUpdateResponse
} from 'testingtool/actions';

/**
 * Inicializar el listado de "Journeys" disponibles para el TestingTool.
 */
function* populateJourneys() {
  // Vamos a ver si ya lo tenemos en el store...
  const tt = yield select((store) => store.testingtool);
  const currentPlan = yield select((store) => store.data.config?.current_plan);
  // Si no tenemos cargado aún el "available_journeys" tenemos que cargarlo...
  if (currentPlan && null === tt?.available_journeys) {
    try {
      const {body} = yield call(request, endpoints.mock.read(currentPlan));
      yield put(testingToolJourneyPopulate(body));
    } catch (e) {
      yield put(
        showNotification({
          name: 'error',
          title: 'Sorry! There was an unexpected error!'
        })
      );
    }
  }
}

function* journeyUpdateRequest({values}) {
  try {
    const currentPlan = yield select(
      (store) => store.data.config?.current_plan
    );
    const res = yield call(request, endpoints.mockJourney.update(currentPlan), {
      journey_id: values.journeyId,
      moment_id: values.data?.id
    });
    if (res && res.body && res.body.status === 'ko') {
      yield put(
        showNotification({
          name: 'error',
          title: `There was an error in the mocking: "${res.body.errors.message}"`
        })
      );
    } else {
      yield put(
        showNotification({
          name: 'success',
          title: 'Your Configuration Data changes have been saved'
        })
      );
    }
  } catch (e) {
    yield put(
      showNotification({
        name: 'error',
        title: 'Sorry! There was an unexpected error!'
      })
    );
  }

  yield put(testingToolJourneyUpdateResponse());
}

function* taskUpdateRequest({values}) {
  try {
    const currentPlan = yield select(
      (store) => store.data.config?.current_plan
    );
    const res = yield call(request, endpoints.mockTask.update(currentPlan), {
      task_id: values.taskID,
      completed: values.completed
    });
    if (res && res.body && res.body.status === 'ko') {
      yield put(
        showNotification({
          name: 'error',
          title: `There was an error in the mocking: "${res.body.errors.message}"`
        })
      );
    } else {
      yield put(
        showNotification({
          name: 'success',
          title: 'Your Configuration Data changes have been saved'
        })
      );
    }
  } catch (e) {
    yield put(
      showNotification({
        name: 'error',
        title: 'Sorry! There was an unexpected error!'
      })
    );
  }

  yield put(testingToolTaskUpdateResponse());
}

export function* testingToolsSaga() {
  if (config.app.mode !== 'production') {
    yield populateJourneys();
    yield takeEvery(
      testingToolJourneyUpdateRequest().type,
      journeyUpdateRequest
    );
    yield takeEvery(testingToolTaskUpdateRequest().type, taskUpdateRequest);
  } else {
    // NOTA: TODO: Para NO devolver ningún Saga en esta función generadora
    // no se muy bien cuál sería la mejor opción. Una es "yield []", o
    // quizá no devolver nada. La duda que tengo es que a lo mejor no
    // devolver nada (un simple "return"?) hace cancelar todos los Saga.
    // De momento "yield undefined" parece funcionar...
    yield undefined;
  }
}

export default testingToolsSaga;
