import {modalShow} from 'components/UI/Modal/actions';
import produce from 'immer';
import {
  setPlanToCanceled,
  updateStatus
} from 'screens/ManageSubscriptions/actions';

export const status = {
  SUBMITTING: 'submitting',
  SUCCESS: 'success',
  ERROR: 'error',
  INPUT: 'input'
};

export const initialState = {
  status: status.INPUT,
  canceledPlan: false
};

export default (state = initialState, {type, payload}) =>
  produce(state, (draft) => {
    switch (type) {
      case updateStatus().type:
        draft.status = payload ?? status.INPUT;
        break;
      case modalShow().type:
        draft.status = status.INPUT;
        break;
      case setPlanToCanceled().type:
        draft.canceledPlan = true;
        break;
      default:
        draft;
    }
  });
