import {paywallError, paywallRequest} from 'components/Paywall/actions';
import {endpoints} from 'lib/redux/sagas/api';
import {request} from 'lib/redux/sagas/request';
import {takeEvery} from 'redux-saga';
import {call, put, select} from 'redux-saga/effects';
import {clientSecretCall} from 'screens/Profile/Billing/Card/saga';

// TODO: Eliminar regla eslint
/* eslint-disable no-useless-catch */
export function* apiCall(params) {
  try {
    const response = yield call(request, endpoints.payment.create(), params);
    if (response.body.status !== 'ok') throw {error: response.body.errors[0]};
    return response;
  } catch (e) {
    // Este throw automaticamente dispara el catch del saveCardRequest
    throw e;
  }
}
/* eslint-enable no-useless-catch */

export const selectUpdateCard = ({paywall}) => paywall.update_card || false;
export const selectHasCard = (state) =>
  state.data.config?.user?.has_sources ||
  state.data.config?.user?.has_payment_method;

export function* paywallRequestSaga({
  stripe,
  interval,
  product,
  upgrade,
  domain,
  card_holder_name,
  promo,
  rpntoken,
  promoCode
}) {
  try {
    /*
            Para el handleCardSetup de Stripe necesitamos 3 params:
            - secret (string): Viene dado por el saga clientSecretCall
            - element (object): React component que se entrega en el onReady de componente.
            - (object): payment_method_data, con el card_holder_name
        */

    // OMITIR setupIntent si ya hay card
    const update_card = yield select(selectUpdateCard);
    const has_card = yield select(selectHasCard);

    // Get the client_secret to get setupintets
    const clientSecret = yield call(clientSecretCall);

    // Params ready
    let params = {
      interval,
      product,
      upgrade,
      domain,
      promo,
      partner_token: rpntoken,
      setup_intent: null, // lo actualizaremos si es necesario a continuación
      promoCode
    };

    if (!has_card || update_card) {
      const paymentMethod = {
        payment_method_data: {
          billing_details: {name: card_holder_name}
        }
      };
      const {setupIntent, error} = yield call(
        stripe.handleCardSetup,
        clientSecret.body.secret,
        paymentMethod
      );
      // Error en el setup intent
      if (error) throw {type: 'setup_intent_error', error};
      params.setup_intent = setupIntent;
    }

    // Api Call
    const response = yield call(apiCall, params);
    // Redirect
    yield call(
      {context: window.location, fn: window.location.replace},
      response.body.redirect
    );
  } catch (e) {
    yield put(paywallError(interval, e.error));
  }
}

export function* PaywallSaga() {
  yield takeEvery(paywallRequest().type, paywallRequestSaga);
}

export default PaywallSaga;
