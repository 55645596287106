import {useSelector, shallowEqual} from 'react-redux';
import amplitude from 'amplitude-js';

var amplitudInitialized = false;
var amplitudUserIdConfigured = false;

const defaultValues = {
  'Purchase web': {
    'Purchase plan': 'Trial',
    price: 0.0,
    quantity: 1,
    revenue: 0.0
  }
};

const useAmplitude = () => {
  const {apiKey, dealer, userId} = useSelector(
    ({data}) => ({
      apiKey: data.config?.tokens?.amplitude,
      dealer: data.config?.dealer?.name,
      userId: data.config?.user?.ulid
    }),
    shallowEqual
  );

  if (!amplitudInitialized) {
    amplitude.getInstance().init(apiKey);
    amplitudInitialized = true;
  }

  if (!amplitudUserIdConfigured && amplitudInitialized && userId) {
    amplitude.getInstance().setUserId(userId);
    const identify = new amplitude.Identify().set('user_id', userId);
    amplitude.identify(identify);
    amplitudUserIdConfigured = true;
  }

  return {
    logTypes: {
      WP_PLUGIN_Purchase_Flow_PageView: 'Purchase Flow PageView',
      WP_PLUGIN_Purchase_Web: 'Purchase web',
      WP_PLUGIN_Purchase_Flow_Payment_Error: 'Purchase flow payment error'
    },
    logEvent: (type, properties) => {
      // eslint-disable-next-line no-console
      if (!amplitudInitialized)
        console.info(
          '[Amplitude] is not initialized. You cannot send an event.'
        );
      amplitude.getInstance().logEvent(type, {
        dealer,
        'Product Key': 'wordpress',
        'Product Name': 'WordPress',
        ...(defaultValues[type] || {}),
        ...(properties || {})
      });
    }
  };
};

export default useAmplitude;
