import {t} from 'i18next';
import {isEmail, isURL} from 'validator';

export const required = {
  value: true,
  message: t('validations.required')
};

export const isValidDomain = (domain) =>
  isURL(domain.trim(), {protocols: ['http', 'https']}) ||
  t('validations.domain');

export const getMinLengthRule = (number) => ({
  value: number,
  message: t('validations.minlength', {count: number})
});

export const getMaxLengthRule = (number) => ({
  value: number,
  message: t('validations.maxlength', {count: number})
});

export const isValidEmail = (value) =>
  isEmail(value) ? true : t('validations.email');
