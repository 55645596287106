export const modalShow = (modalType = null, modalProps = {}) => {
  return {
    type: 'modal/show',
    modalType,
    modalProps
  };
};

export const modalHide = () => ({
  type: 'modal/hide'
});
