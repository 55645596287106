export const sendData = (values) => ({
  type: 'plan/sendCancelForm',
  values
});

export const updateStatus = (status) => ({
  type: 'survey/updateFormStatus',
  payload: status
});

export const setPlanToCanceled = (status = true) => ({
  type: 'plan/cancel',
  payload: status
});

export const setSubscription = (plan, data) => ({
  type: 'setSubscription',
  payload: plan,
  data
});
