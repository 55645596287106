import {connect} from 'react-redux';
import {getAffiliateTracking} from 'misc/helper';

const RPNRibbon = ({visible}) => (
  <div>
    {visible && (
      <h1 className="rpn-ribbon">
        {__('Your 25% discount has been applied.')}
        <span>{__('Original Pricing: $29.97/month and $297.71/year')}</span>
      </h1>
    )}
  </div>
);
const mapStateToProps = ({data: {config, plans}}) => {
  const allowed_routes = ['User:signup', 'User:credit_card_form'];
  const route_allowed = allowed_routes.includes(config?.current_route);

  let promo_allowed = true;
  const excluded_promos = ['weebly'];
  const current_plan = config?.current_plan;
  if (current_plan) {
    const promo = plans[current_plan]?.promo;
    if (excluded_promos.includes(promo)) {
      promo_allowed = false;
    }
  }

  return {
    visible: route_allowed && getAffiliateTracking() && promo_allowed
  };
};

export default connect(mapStateToProps, null)(RPNRibbon);
