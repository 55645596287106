const DOMAIN = 'ofsSuggestions';

export const requestScanSingle = () => ({
  type: `${DOMAIN}/requestScanSinglePage`
});

export const resetRequestScanSingle = () => ({
  type: `${DOMAIN}/resetRequestScanSinglePage`
});

export const requestKeywordSuggestion = (pageId) => ({
  type: `${DOMAIN}/requestKeywordSuggestion`,
  pageId
});

export const responseKeywordSuggestion = (keyword) => ({
  type: `${DOMAIN}/responseKeywordSuggestion`,
  keyword
});
