const DOMAIN = 'testingTool';

export const testingToolJourneyPopulate = (values) => ({
  type: `${DOMAIN}/journeyPopulate`,
  values
});

export const testingToolJourneyUpdateRequest = (values) => ({
  type: `${DOMAIN}/journeyUpdateRequest`,
  values
});

export const testingToolJourneyUpdateResponse = (values) => ({
  type: `${DOMAIN}/journeyUpdateResponse`,
  values
});

export const testingToolTaskUpdateRequest = (values) => ({
  type: `${DOMAIN}/taskUpdateRequest`,
  values
});

export const testingToolTaskUpdateResponse = (values) => ({
  type: `${DOMAIN}/taskUpdateResponse`,
  values
});
