import {
  Button,
  Field,
  Input,
  ButtonGroup,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader
} from '@marketgoo/components';
import {useForm} from 'react-hook-form';
import {modalHide} from 'components/UI/Modal/actions';
import {t} from 'i18next';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {sendData} from 'screens/ManageSubscriptions/actions';

const ResultModal = ({children}) => {
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(modalHide());
  };
  return (
    <Modal open onClose={closeModal}>
      <ModalContent>
        <div className="modal-cancelsurvey-layout">{children}</div>
      </ModalContent>
    </Modal>
  );
};

const Success = () => (
  <ResultModal>
    <figure className="success" />
    <div>
      <h1>{t('cancelsurvey.success.title')}</h1>
      <p>{t('cancelsurvey.success.description')}</p>
    </div>
  </ResultModal>
);

const Error = () => (
  <ResultModal>
    <figure />
    <div>
      <h3>{t('cancelsurvey.error.description')}</h3>
    </div>
  </ResultModal>
);

const SurveyForm = ({busy = null}) => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: {errors, isSubmitting, dirtyFields}
  } = useForm({defaultValues: {reason: ''}});

  const required = {
    value: true,
    message: t('cancelsurvey.validreason')
  };

  const onSubmit = () => {
    dispatch(sendData());
  };

  const hideModal = () => {
    dispatch(modalHide());
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="modal-cancelsurvey">
      <Modal open onClose={hideModal}>
        <ModalHeader
          title={t('cancelsurvey.title')}
          intro={t('cancelsurvey.intro')}
        />
        <ModalContent variant="scroll">
          <Field
            id="reason"
            label={t('cancelsurvey.form.label')}
            hint={t('validations.required')}
            error={!!errors.reason}
            description={errors && errors.reason && errors.reason.message}
          >
            <Input
              type="textarea"
              placeholder={t('cancelsurvey.form.placeholder')}
              {...register('reason', {required})}
            />
          </Field>
        </ModalContent>
        <ModalFooter>
          <ButtonGroup variant="reversed">
            <Button onClick={hideModal} variant="secondary">
              {t('cancelsurvey.form.button.continue')}
            </Button>
            <Button
              variant="destructive-primary"
              disabled={!dirtyFields.reason || isSubmitting}
              busy={busy && t('g.please.wait')}
            >
              {t('cancelsurvey.form.button.cancel')}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    </form>
  );
};

const CancelSurvey = () => {
  const status = useSelector((state) => state.survey.status);
  const contentByStatus = {
    input: <SurveyForm />,
    submitting: <SurveyForm busy={true} />,
    success: <Success />,
    error: <Error />
  };
  return contentByStatus[status];
};

export default CancelSurvey;
