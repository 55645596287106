import React, {useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {modalHide} from 'components/UI/Modal/actions';
import {
  Button,
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  ButtonGroup,
  Check
} from '@marketgoo/components';
import {unassignKeyword} from 'components/Keywords/actions';

const UnassignKeyword = ({keyword, busy, task}) => {
  const [deleteFromTracked, setDeleteFromTracked] = useState(false);
  const dispatch = useDispatch();
  const onCloseModal = () => {
    dispatch(modalHide());
  };
  const handleUnassignKeyword = () => {
    dispatch(unassignKeyword(task, keyword, deleteFromTracked));
    posthog?.capture('confirm remove keyword click');
  };

  const intro = (
    <span
      dangerouslySetInnerHTML={{
        __html: __(
          'After removing keyword <strong>{keyword}</strong>, you will be able to assign a different keyword to this page, but all previous optimizations will be lost',
          {keyword: keyword?.name}
        )
      }}
    />
  );
  const handleCheck = () => {
    setDeleteFromTracked(!deleteFromTracked);
    posthog?.capture('remove keyword from tracked check');
  };

  return (
    <Modal open onClose={onCloseModal} variant="narrow">
      <ModalHeader
        title={__('Do you want to remove this keyword?')}
        intro={intro}
      />
      {keyword?.is_tracked ? (
        <ModalContent>
          <Check
            type="checkbox"
            checked={deleteFromTracked}
            onChange={handleCheck}
          >
            {__(
              'Remove also from the tracked keyword list. All historical data will be lost'
            )}
          </Check>
        </ModalContent>
      ) : null}
      <ModalFooter>
        <ButtonGroup>
          <Button variant="destructive" disabled={busy} onClick={onCloseModal}>
            {__("Don't remove")}
          </Button>
          <Button
            variant="destructive-primary"
            busy={busy && __('Please wait ...')}
            onClick={handleUnassignKeyword}
          >
            {__('Remove the keyword')}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

const mapState = ({tasks}, {closeModal, unassign, keyword, task}) => {
  const busy = tasks?.keyword_assign_busy || false;
  return {
    closeModal,
    unassign,
    keyword,
    busy,
    task
  };
};

export default connect(mapState)(UnassignKeyword);
