const DOMAIN = 'tasks';
export const REQUEST_TASK_AUTOCOMPLETED = 'REQUEST_TASK_AUTOCOMPLETED';
export const RESPONSE_TASK_AUTOCOMPLETED = 'RESPONSE_TASK_AUTOCOMPLETED';

export const loadTasks = () => ({
  type: `${DOMAIN}/load`
});

export const updateTasks = (uuid, tasks, suggestions) => ({
  type: `${DOMAIN}/update`,
  payload: {
    tasks,
    suggestions,
    uuid
  }
});

export const performTaskAction = (task_action, task) => ({
  type: `${DOMAIN}/performTaskAction`,
  payload: {
    task_action,
    task
  }
});

export const resetTaskAiFeature = (url) => ({
  type: `${DOMAIN}/resetTaskAiFeature`,
  payload: {
    url
  }
});

export const endKeywordAssign = (keyword, uuid) => ({
  type: `${DOMAIN}/endKeywordAssign`,
  payload: {
    keyword,
    uuid
  }
});

export const completeTask = (task, uuid) => ({
  type: `${DOMAIN}/completeTask`,
  payload: {
    task,
    uuid
  }
});

export const startKeywordAssign = (task_id, keyword, add_to_traked_list) => ({
  type: `${DOMAIN}/startKeywordAssign`,
  payload: {
    task_id,
    keyword,
    add_to_traked_list
  }
});

export const requestTaskDataById = (taskId) => ({
  type: `${DOMAIN}/requestTaskDataById`,
  payload: {
    taskId
  }
});

export const responseTaskDataById = (response) => ({
  type: `${DOMAIN}/responseTaskDataById`,
  payload: {response}
});

export const responseDataPage = (response) => ({
  type: `${DOMAIN}/responseDataPage`,
  payload: {response}
});

export const resetScanningMessage = () => ({
  type: `${DOMAIN}/resetScanningMessage`
});

export const requestTaskAutocompleted = (payload) => ({
  type: REQUEST_TASK_AUTOCOMPLETED,
  payload
});

export const responseTaskAutocompleted = (response) => ({
  type: RESPONSE_TASK_AUTOCOMPLETED,
  payload: response
});
