const DOMAIN = 'wizard';

export const wizardUpdate = (field, value) => ({
  type: `${DOMAIN}/update`,
  field,
  value
});

export const wizardNextStep = () => ({
  type: `${DOMAIN}/nextStep`
});

export const wizardPreviousStep = () => ({
  type: `${DOMAIN}/previousStep`
});

export const wizardSetStep = (step) => ({
  type: `${DOMAIN}/setStep`,
  step
});

export const wizardSetNavigateObject = (navigate) => ({
  type: `${DOMAIN}/setNavigateObject`,
  navigate
});

export const wizardSetKeywordData = (data) => ({
  type: `${DOMAIN}/setKeywordData`,
  data
});
