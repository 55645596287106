const DOMAIN = 'notifications';

// PAYLOAD FOR GENERIC:
/*
payload: {
    style,
    title,
    message,
},
*/

export const showNotification = (notification) => {
  return {
    type: `${DOMAIN}/show`,
    payload: {...notification}
  };
};

export const removeNotification = (id) => {
  return {
    type: `${DOMAIN}/removeNotification`,
    payload: {id}
  };
};
