const DOMAIN = 'creditCard';

export const saveCardRequest = (stripe, secret, element, cardHolderName) => ({
  type: `${DOMAIN}/saveRequest`,
  payload: {
    stripe,
    secret,
    element,
    cardHolderName
  }
});

export const updateCreditCard = (creditcard) => ({
  type: `${DOMAIN}/update`,
  payload: {
    creditcard
  }
});

export const toggleCardMenu = () => ({
  type: `${DOMAIN}/toggleMenu`
});

export const toggleCardEditing = () => ({
  type: `${DOMAIN}/toggleEditing`
});

export const cardChanged = (data) => ({
  type: `${DOMAIN}/changed`,
  payload: {
    data
  }
});
