import {modalShow} from 'components/UI/Modal/actions';
import {takeEvery} from 'redux-saga';
import {put, select} from 'redux-saga/effects';
import {getAnalyticsProfileProperty} from 'selectors';

function* showNewVersionNotice() {
  const analyticsProfileProperty = yield select(getAnalyticsProfileProperty);
  const hasOldAnalyticsVersion = analyticsProfileProperty?.startsWith('UA');
  if (hasOldAnalyticsVersion) yield put(modalShow('NEW_VERSION_NOTICE'));
}

export function* NoticeSaga() {
  yield [takeEvery('APP_READY', showNewVersionNotice)];
}

export default NoticeSaga;
