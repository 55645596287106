import {
  removeNotification,
  showNotification
} from 'components/UI/Notifications/actions';

import produce from 'immer';
import {v4 as uuidV4} from 'uuid';

const initialState = [];

export default (state = initialState, {type, payload}) =>
  produce(state, (draft) => {
    switch (type) {
      case showNotification().type:
        draft.push({id: uuidV4(), ...payload});
        break;
      case removeNotification().type:
        return draft.filter((notification) => notification.id !== payload.id);

      default:
        return state;
    }
  });
