function comprobar_configuracion() {
  if ($('#is_local').val() == 'si') {
    $('#city-content').show();
  } else {
    $('#city-content').hide();
  }

  if ($('#in_charge').val() == '2') {
    $('#webmaster_email-content').show();
  } else {
    $('#webmaster_email-content').hide();
  }
}

if ($('#city-content').length && $('#webmaster_email-content').length) {
  comprobar_configuracion();
}

$('#is_local, #in_charge').change(function () {
  comprobar_configuracion();
});
