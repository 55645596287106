const DOMAIN = 'upgrade';

export const upgradeRelocateToCTAUrl = () => ({
  type: `${DOMAIN}/relocateToCTAUrl `
});

export const upgradeClick = (contentType) => ({
  type: `${DOMAIN}/upgradeClick`,
  payload: contentType
});
