const DOMAIN = 'siteResult';

export const updateKeywordSelected = (keyword_id) => ({
  keyword_id: keyword_id,
  type: `${DOMAIN}/updateKeywordSelected`
});

export const updateEngineSelected = (engine) => ({
  engine: engine,
  type: `${DOMAIN}/updateEngineSelected`
});

export const updateSerieSelected = (serie) => ({
  serie: serie,
  type: `${DOMAIN}/updateSerieSelected`
});
