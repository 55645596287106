import {
  addKeyword,
  endUnassignKeyword,
  removeKeyword,
  searchKeywordsRequest,
  searchKeywordsResponse,
  unassignKeyword
} from 'components/Keywords/actions';
import {resetTaskAiFeature} from 'components/TasksPanel/actions';
import {modalHide, modalShow} from 'components/UI/Modal/actions';
import i18next from 'i18next';
import {endpoints} from 'lib/redux/sagas/api';
import {request} from 'lib/redux/sagas/request';
import {takeEvery} from 'redux-saga';
import {call, put, select} from 'redux-saga/effects';

export const modalError = modalShow('MODAL_INFO', {
  title: i18next.t('notification.global.error.tryagain.title'),
  description: i18next.t('notification.global.error.tryagain.description'),
  error: true
});

function* addKeywordSaga({payload: {keyword}}) {
  yield call(request, endpoints.keyword.create(), {
    name: keyword?.name,
    tracked: true
  });
}

function* removeKeywordSaga({payload: {keyword_id, unassign}}) {
  const keyword = encodeURIComponent(keyword_id);
  if (unassign) {
    yield call(request, endpoints.keyword.delete(keyword), {
      unassign
    });
  } else {
    yield call(request, endpoints.keyword.delete(keyword));
  }
}

function* unassignKeywordSaga({
  payload: {task_id, delete_from_tracked, keyword}
}) {
  try {
    // unassing and delete from tracked -> {"unlink_kwd": true, "delete_kwd": true}
    // unassing only -> {"unlink_kwd": true}

    yield call(request, endpoints.optimize.update(task_id), {
      unlink_kwd: true,
      delete_kwd: delete_from_tracked
    });

    const data = yield select((state) => state.data);
    const uuid = data.config?.current_plan;
    const url = yield select(
      (state) => state?.optimizeYourPages?.page_data?.page?.url
    );

    // Usamos el keyword para actualizar el state sin esperar al refrehs plan data
    yield put(endUnassignKeyword(keyword, uuid, delete_from_tracked));
    yield put(resetTaskAiFeature(url));
    yield put(modalHide());
  } catch (e) {
    yield put(modalError);
  }
}

export function* searchKeywordsRequestSaga({payload: {values}}) {
  const response = yield call(request, endpoints.keywordSearch.read(values));
  const results = (response && response.body.results) || [];
  yield put(searchKeywordsResponse(results));
}

export function* KeywordsSaga() {
  yield [
    takeEvery(unassignKeyword().type, unassignKeywordSaga),
    takeEvery(addKeyword().type, addKeywordSaga),
    takeEvery(removeKeyword().type, removeKeywordSaga),
    takeEvery(searchKeywordsRequest().type, searchKeywordsRequestSaga)
  ];
}

export default KeywordsSaga;
