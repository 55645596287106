import {
  Button,
  Check,
  Field,
  Input,
  Panel,
  PanelContent,
  PanelHeader
} from '@marketgoo/components';
import React from 'react';
import {useForm} from 'react-hook-form';
import {Trans as T, useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {sendData} from './actions';
import {
  required,
  getMinLengthRule,
  getMaxLengthRule,
  isValidEmail
} from 'components/UI/Form/validators';

const SignUp = ({promo = null, product = null}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    watch,
    formState: {errors}
  } = useForm({defaultValues: {promo, product, gdpr: false}});

  const onSubmit = (data) => {
    dispatch(sendData(data));
  };

  return (
    <Panel>
      <PanelHeader
        title={t('payflow.signup.title')}
        intro={t('payflow.signup.intro')}
      />
      <PanelContent>
        <form
          action="/ajax/signup"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="inputs-block">
            <div>
              <Field
                id="name"
                error={!!errors.name}
                description={errors && errors.name && errors.name.message}
                label={t('g.name')}
              >
                <Input
                  type="text"
                  {...register('name', {
                    required,
                    maxLength: getMaxLengthRule(25),
                    minLength: getMinLengthRule(2)
                  })}
                />
              </Field>
              <Field
                id="email"
                error={!!errors.email}
                description={errors && errors.email && errors.email.message}
                label={t('g.email')}
              >
                <Input
                  type="text"
                  {...register('email', {
                    required,
                    validate: isValidEmail
                  })}
                />
              </Field>
              <Field
                id="password"
                error={!!errors.password}
                description={
                  errors && errors.password && errors.password.message
                }
                label={t('g.password')}
              >
                <Input
                  type="password"
                  {...register('password', {
                    required,
                    minLength: getMinLengthRule(6)
                  })}
                />
              </Field>
            </div>
          </div>
          <div className="actions">
            <div>
              <Field id="gdpr">
                <Check {...register('gdpr')}>
                  <T
                    i18nKey="signup.gdpr"
                    components={[
                      <a
                        key={0}
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.marketgoo.com/privacy-policy/"
                      />,
                      <a
                        key={1}
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.marketgoo.com/terms/"
                      />
                    ]}
                  />
                </Check>
              </Field>
            </div>
            <div>
              <Button variant="primary" disabled={!watch('gdpr')}>
                {t('payflow.signup.title')}
              </Button>
            </div>
          </div>
        </form>
      </PanelContent>
    </Panel>
  );
};

export default SignUp;
