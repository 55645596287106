import {Navigation, Typography, Tag} from '@marketgoo/components';
import React from 'react';
import {connect} from 'react-redux';
import {CrownSimple, PlusCircle} from '@phosphor-icons/react';
import {Link} from 'react-router-dom';

const MyWebsites = ({
  forbidden,
  empty,
  plans,
  manageAccount,
  currentPlan,
  products
}) => {
  const getProductName = (plan) => products?.[plan?.product]?.name;

  const urlManageSubscription = currentPlan?.suspended_at
    ? `/website/select/${currentPlan?.uuid}`
    : `/website/${currentPlan?.uuid}/pricing`;

  return (
    <Navigation.Collapse
      closeOnClickChildren
      trigger={<Navigation.Button>{currentPlan?.domain}</Navigation.Button>}
    >
      {!manageAccount && !forbidden && !empty && (
        <Navigation.Button
          active
          contentRight={<Tag>{getProductName(currentPlan)}</Tag>}
        >
          <Typography as="span">{currentPlan?.domain}</Typography>
        </Navigation.Button>
      )}
      {manageAccount && !forbidden && (
        <>
          {Object.values(plans).map((plan) => {
            return (
              <Navigation.Button
                key={plan?.uuid}
                as="a"
                active={plan?.uuid === currentPlan?.uuid}
                href={`/website/select/${plan?.uuid}`}
                contentRight={<Tag>{getProductName(plan)}</Tag>}
              >
                <Typography as="span">{plan?.domain}</Typography>
              </Navigation.Button>
            );
          })}
          <Navigation.Divider direction="horizontal" />
          <Navigation.Button
            as="a"
            href={urlManageSubscription}
            active={window.location.pathname.startsWith('/website/')}
          >
            <CrownSimple size={24} weight="fill" />{' '}
            <Typography as="span" m={{l: 1}}>
              {__('Manage subscription')}
            </Typography>
          </Navigation.Button>
          <Navigation.Button
            as={Link}
            to="/mywebsites/add"
            active={window.location.pathname === '/mywebsites/add'}
          >
            <PlusCircle size={24} weight="fill" />{' '}
            <Typography as="span" m={{l: 1}}>
              {__('Add a new site')}
            </Typography>
          </Navigation.Button>
        </>
      )}
    </Navigation.Collapse>
  );
};

const mapStateToProps = ({data: {plans, config}}) => {
  const currentRoute = config?.current_route;
  const currentUuid = config?.current_plan;
  const currentPlan = plans?.[currentUuid];
  const products = config?.dealer?.products;
  const manageAccount = config?.dealer?.manage_account;

  const urlsWithoutMywebsites = [
    'User:remember',
    'User:remember_sent',
    'User:remember_check',
    'User:remember_check_post',
    'User:remember_message'
  ];

  const forbidden = urlsWithoutMywebsites.includes(currentRoute);
  const empty = plans.length == 0;

  return {
    plans: plans,
    currentPlan,
    products,
    manageAccount,
    forbidden,
    empty
  };
};

export default connect(mapStateToProps)(MyWebsites);
