import {request} from 'lib/redux/sagas/request';
import {takeEvery} from 'redux-saga';
import {endpoints} from 'lib/redux/sagas/api';
import {NOTIFICATIONS_ROUTES} from 'components/UI/Notifications/constants';
import {
  showNotification,
  removeNotification
} from 'components/UI/Notifications/actions';
import {call, put, select} from 'redux-saga/effects';
import {getCurrentRoute} from 'selectors';

function* getNotifications() {
  const currentRoute = yield select(getCurrentRoute);
  if (!NOTIFICATIONS_ROUTES.includes(currentRoute)) {
    return;
  }

  const res = yield call(request, endpoints.notifications.read());
  try {
    const notifications = res.body.data || [];
    if (notifications.size === 0) return;
    for (const notification of notifications) {
      yield put(showNotification(notification));
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteNotification({payload}) {
  try {
    yield call(request, endpoints.notifications.delete(payload?.id));
  } catch (error) {
    console.log(error);
  }
}

export function* notificationsSaga() {
  yield takeEvery('APP_READY', getNotifications);
  yield takeEvery(removeNotification().type, deleteNotification);
}

export default notificationsSaga;
