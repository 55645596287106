import produce from 'immer';
import {modalShow, modalHide} from 'components/UI/Modal/actions';

export const initialState = {
  modalType: null,
  modalProps: {}
};

export default (state = initialState, {type, modalType, modalProps}) =>
  produce(state, (draft) => {
    switch (type) {
      case modalShow().type:
        draft.modalType = modalType;
        draft.modalProps = modalProps;
        break;

      case modalHide().type:
        draft.modalType = null;
        draft.modalProps = {};
        break;

      default:
        draft;
        break;
    }
  });
