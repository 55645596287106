import produce from 'immer';
import config from 'lib/application-config';
import hydrate from 'lib/hydrate';
import {normalizeData} from 'misc/helper';
import {updateCreditCard} from 'screens/Profile/Billing/Card/actions';
import {updateEmailSettings, updateRecipients} from 'screens/Settings/actions';
import {setManageAccount} from './actions';

const initialState = {
  ...config,
  dealer: {
    ...config.dealer,
    products: normalizeData(config.dealer.products, 'key')
  },
  hash: window.location.hash.substring(1),
  email_settings: hydrate('email_settings')
};

export default (state = initialState, {type, payload}) =>
  produce(state, (draft) => {
    let payloadKey = null;
    if (payload) {
      payloadKey = Object.keys(payload)[0];
    }

    switch (type) {
      case updateCreditCard().type:
        if (action.creditcard) {
          draft.user.billing.card = payload.creditcard;
        }
        break;

      case updateEmailSettings().type:
        if (typeof payload[payloadKey] === 'boolean') {
          draft.email_settings[payloadKey].enabled = payload[payloadKey];
        }
        break;

      case updateRecipients().type:
        const {self, recipients} = payload[payloadKey];

        draft.email_settings[payloadKey].self = self;
        draft.email_settings[payloadKey].recipients = recipients;
        break;
      case setManageAccount().type:
        draft.dealer.manage_account = payload;
      default:
        draft;
        break;
    }
  });
