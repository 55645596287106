import React from 'react';
// TODO: Mover a Ola
export const Separator = ({children}) => {
  return (
    <div className="ola_separator">
      <hr />
      {children}
      <hr />
    </div>
  );
};
