import {call} from 'redux-saga/effects';
import Superagent from 'superagent';

export function prepareRequest(
  requestUrl = '/',
  requestMethod = 'GET',
  requestParams = null
) {
  return () => Superagent(requestMethod, requestUrl).send(requestParams);
}

export function* request({method, url}, params) {
  // eslint-disable-next-line no-useless-catch
  try {
    const req = prepareRequest(url, method, params);
    return yield call(req, url);
  } catch (e) {
    throw e;
  }
}
