import {websocketActions} from 'app/Scanning/actions';
import {modalShow} from 'components/UI/Modal/actions';
import i18next from 'i18next';
import {endpoints} from 'lib/redux/sagas/api';
import {request} from 'lib/redux/sagas/request';
import {takeEvery, takeLatest} from 'redux-saga';
import {call, put, select} from 'redux-saga/effects';
import {
  loadMorePagesRequest,
  loadMorePagesResponse,
  nextPageSuggestedRequest,
  nextPageSuggestedResponse,
  pageDataByIdRequest,
  pageDataByIdResponse,
  pagesRequest,
  pagesResponse,
  totalPagesRequest,
  totalPagesResponse
} from 'screens/OptimizeYourPages/actions';

export const modalError = modalShow('MODAL_INFO', {
  title: i18next.t('notification.global.error.tryagain.title'),
  description: i18next.t('notification.global.error.tryagain.description'),
  error: true
});

export function createGetPagesQuery(
  filters = [],
  search = '', // No puede ser null ya que viene del input
  limit = 10,
  offset = 0,
  sort = '-date'
) {
  const queryFilterNotOptimized = filters.includes('not-optimized')
    ? 'filter[optimized]=false'
    : null;
  const queryOffset = `page[offset]=${offset || 0}`;
  const queryLimit = `page[limit]=${limit || 10}`;
  const querySort = filters.includes('not-optimized')
    ? 'sort=progress'
    : `sort=${sort}`;
  const cleanSearch = search ? search.trim() : null;
  const querySearch =
    cleanSearch !== null && cleanSearch !== ''
      ? `filter[search]=${cleanSearch}`
      : null;

  const queryFilters = [
    queryFilterNotOptimized,
    querySearch,
    queryOffset,
    querySort,
    queryLimit
  ];
  const requestQuery = queryFilters
    .reduce((acc, qfilter) => {
      if (qfilter !== null) {
        const r = acc ? `${acc}${qfilter}&` : `${qfilter}&`;
        return r;
      }
      return acc;
    }, null)
    .slice(0, -1);
  return encodeURI(`?${requestQuery}`);
}

export function* ajaxPagesLoadMore({url}) {
  try {
    const res = yield call(request, {url, method: 'GET'});
    if (res.body && res.statusCode === 200) {
      yield put(loadMorePagesResponse(res.body));
    } else {
      yield put(modalError);
    }
  } catch (e) {
    yield put(modalError);
  }
}

export function* ajaxGetPages({filters, search, limit, offset}) {
  const requestQuery = createGetPagesQuery(filters, search, limit, offset);
  try {
    const res = yield call(
      request,
      endpoints.pages.read({query: requestQuery})
    );
    if (res.body && res.statusCode === 200) {
      yield put(pagesResponse(res.body));
    } else {
      yield put(modalError);
    }
  } catch (e) {
    yield put(modalError);
  }
}

export function* ajaxGetTotalPages() {
  const requestTotalQuery = createGetPagesQuery([], '', 1);
  const requestPendingQuery = createGetPagesQuery(['not-optimized'], '', 1);
  try {
    const resTotal = yield call(
      request,
      endpoints.pages.read({query: requestTotalQuery})
    );
    const resPending = yield call(
      request,
      endpoints.pages.read({query: requestPendingQuery})
    );
    if (
      resTotal.body &&
      resTotal.statusCode === 200 &&
      resPending.body &&
      resPending.statusCode === 200
    ) {
      const totalPages = resTotal.body.meta.total_items;
      const totalPending = resPending.body.meta.total_items;
      yield put(totalPagesResponse(totalPages, totalPending));
    } else {
      yield put(modalError);
    }
  } catch (e) {
    yield put(modalError);
  }
}

// Este saga pide los datos de todas las páginas
// en caso de que no haya pages y se haga un scan de nuevo
// o actualiza los datos en caso de que ya tengamos un listado de pages
export function* loadIfRefreshPlanData() {
  const optimizeYourPages = yield select((store) => store.optimizeYourPages);
  const pageList = optimizeYourPages?.pages ?? null;

  // EMPTY PAGES
  if (!pageList || pageList.length === 0) {
    yield put(totalPagesRequest());
    yield put(pagesRequest());
  }
}

export function* updateAfterScanning() {
  yield put(totalPagesRequest());
  yield put(pagesRequest());
  const pageID = yield select(
    (state) => state.optimizeYourPages?.page_data?.page?.id || null
  );
  if (pageID) {
    yield put(pageDataByIdRequest(pageID));
  }
}

export function* requestPageDataById({page_id}) {
  try {
    const res = yield call(request, endpoints.pages.read({id: page_id}));
    const resStatus = res.body && res.statusCode === 200;

    if (!resStatus)
      yield call(
        {context: window.location, fn: window.location.replace},
        '/ofs'
      );

    const statusOk = resStatus && res.ok; // OJO! Este ok es diferente al del body
    const resWithErrors = statusOk && res.body && res.body.status === 'ko';

    if (resWithErrors) yield put(pageDataByIdResponse(null));

    if (statusOk && !resWithErrors) {
      const response = res.body;
      const taskId = response ? response.data.task_id : null;
      const progress = response ? response.data.progress : null;
      if (taskId) {
        const taskResponse = yield call(request, endpoints.task.read(taskId));
        if (taskResponse.body && res.statusCode === 200) {
          const TASK_DATA = {progress, ...taskResponse.body.task};
          yield put(pageDataByIdResponse(TASK_DATA));
        }
      }
    }
  } catch (e) {
    yield call({context: window.location, fn: window.location.replace}, '/ofs');
  }
}

export function* ajaxGetNextPageSuggested() {
  const query = createGetPagesQuery(['not-optimized'], '', 1);
  try {
    const res = yield call(request, endpoints.pages.read({query}));
    const pageData =
      res.body.data && res.body.data.length > 0 ? res.body.data[0].data : null;
    if (res.body && res.statusCode === 200) {
      yield put(nextPageSuggestedResponse(pageData));
    }
  } catch (e) {
    if (process.env.NODE_ENV == 'development')
      // eslint-disable-next-line no-console
      console.log('Next page suggested error');
  }
}

export function* scanningSingleTaskFailed() {
  // Pendiente de mejorar para poner otro mensaje al usuario.
  yield put(modalError);
}

export function* OptimizeYourPagesSaga() {
  yield [takeEvery(totalPagesRequest().type, ajaxGetTotalPages)];
  yield [takeLatest(pagesRequest().type, ajaxGetPages)];
  yield [takeEvery(loadMorePagesRequest().type, ajaxPagesLoadMore)];
  yield [takeLatest(websocketActions.REFRESH_PLAN_DATA, loadIfRefreshPlanData)];
  yield [
    takeLatest(
      websocketActions.SCANNING_SINGLE_TASK_FAILED,
      scanningSingleTaskFailed
    )
  ];
  yield [
    takeEvery(
      websocketActions.SCANNING_SINGLE_TASK_COMPLETED,
      updateAfterScanning
    )
  ];
  yield [
    takeLatest(websocketActions.SCANNING_TASKS_COMPLETED, updateAfterScanning)
  ];
  yield [takeLatest(pageDataByIdRequest().type, requestPageDataById)];
  yield [takeEvery(nextPageSuggestedRequest().type, ajaxGetNextPageSuggested)];
}

export default OptimizeYourPagesSaga;
