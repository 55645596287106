import {modalShow} from 'components/UI/Modal/actions';
import i18next from 'lib/i18next';
import {
  downloadPDF,
  downloadPDFIntent,
  pdfDownloaded
} from 'lib/redux/actions/pdf';
import {endpoints} from 'lib/redux/sagas/api';
import {request} from 'lib/redux/sagas/request';
import {delay, takeEvery} from 'redux-saga';
import {call, put, select} from 'redux-saga/effects';
import {getPDFDownloadIntents} from 'selectors';

const tryAgainModal = modalShow('MODAL_INFO', {
  title: i18next.t('notification.score.error.download.title'),
  description: i18next.t('notification.score.error.download.title'),
  error: true
});

const popupModal = modalShow('MODAL_INFO', {
  title: i18next.t('notification.score.error.popup.title'),
  description: i18next.t('notification.score.error.popup.description'),
  error: true
});

function* pdfDownload() {
  const DOWNLOAD_INTENTS = 12;
  try {
    let {
      body: {status, download_url}
    } = yield call(request, endpoints.resultsDownload.read());

    if (!status || status === 'busy') {
      const downloadIntents = yield select(getPDFDownloadIntents);
      if (downloadIntents === DOWNLOAD_INTENTS) {
        yield put(pdfDownloaded());
        yield put(tryAgainModal);
      } else {
        yield call(delay, 5000);
        yield put(downloadPDFIntent());
        yield put(downloadPDF());
      }
    } else if (status === 'fail') {
      yield put(pdfDownloaded());
      yield put(tryAgainModal);
    } else if (status === 'ok') {
      yield put(pdfDownloaded());
      let popup_window = window.open(download_url, '_blank', 'x=y');
      try {
        popup_window.focus();
      } catch (e) {
        yield put(popupModal);
      }
    }
  } catch (e) {
    yield call(delay, 5000);
    yield put(downloadPDFIntent());
    yield put(downloadPDF());
  }
}

export function* pdfSaga() {
  yield takeEvery(downloadPDF().type, pdfDownload);
}

export default pdfSaga;
