import {fork} from 'redux-saga/effects';
import taskActionSaga from './sagas/taskAction';
import scanningSaga from 'app/Scanning/saga';
import surveySaga from 'screens/ManageSubscriptions/saga';
import taskPanelSaga from 'components/TasksPanel/saga';
import analyticsSaga from 'components/Analytics/saga';
import pdf from './sagas/pdf';
import keywordsSaga from 'components/Keywords/saga';
import initialSaga from 'lib/redux/sagas/initialSaga';
import competitorsSaga from 'components/Competitors/saga';
import addSiteSaga from 'screens/AddSite/saga';
import contactDataSage from 'screens/Profile/Contact/saga';
import settingSaga from 'screens/Settings/saga';
import creditCardSaga from 'screens/Profile/Billing/Card/saga';
import paywallSaga from 'components/Paywall/saga';
import signupSaga from 'screens/Signup/saga';
import noticeSaga from 'components/UI/Modal/Notice/saga';
import modalSaga from 'components/UI/Modal/saga';
import widgetSaga from 'components/TestingWidget/saga';
import optimizeYourPagesSaga from 'screens/OptimizeYourPages/saga';
import testingToolsSaga from 'testingtool/saga';
import upgradeSaga from 'lib/redux/sagas/upgrade';
import eventsSaga from 'lib/redux/sagas/events';
import planSaga from 'app/Data/plans/saga';
import toolsSaga from 'screens/Tools/saga';
import notificacionsSaga from 'components/UI/Notifications/saga';
import OptimizeYourPagesSuggestions from 'screens/OptimizeYourPagesSuggestions/saga';

export function* rootSaga() {
  yield [
    fork(eventsSaga),
    fork(upgradeSaga),
    fork(taskActionSaga),
    fork(planSaga),
    fork(scanningSaga),
    fork(surveySaga),
    fork(taskPanelSaga),
    fork(analyticsSaga),
    fork(pdf),
    fork(keywordsSaga),
    fork(competitorsSaga),
    fork(contactDataSage),
    fork(addSiteSaga),
    fork(signupSaga),
    fork(settingSaga),
    fork(creditCardSaga),
    fork(noticeSaga),
    fork(optimizeYourPagesSaga),
    fork(paywallSaga),
    fork(modalSaga),
    fork(widgetSaga),
    fork(testingToolsSaga),
    fork(toolsSaga),
    fork(OptimizeYourPagesSuggestions),
    fork(notificacionsSaga),
    fork(initialSaga) // keep this initialSaga as last item ALWAYS
  ];
}

export default rootSaga;
