import {sendEvent} from 'lib/redux/actions/events';
import {endpoints} from 'lib/redux/sagas/api';
import {request} from 'lib/redux/sagas/request';
import {takeEvery} from 'redux-saga';
import {call, select} from 'redux-saga/effects';
import {getConfigCurrentUuid} from 'selectors';

function redirect(url) {
  window.location.href = url;
}

export function* sendEventSaga({payload}) {
  const uuid = yield select(getConfigCurrentUuid);
  try {
    yield call(request, endpoints.events.create(), {uuid, ...payload});
    if (payload.relocate) {
      yield call(redirect, payload.relocate);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* eventsSaga() {
  yield takeEvery(sendEvent().type, sendEventSaga);
}

export default eventsSaga;
