import config from 'lib/application-config';
import {endpoints} from 'lib/redux/sagas/api';
import {request} from 'lib/redux/sagas/request';
import {takeEvery} from 'redux-saga';
import {call, put, select} from 'redux-saga/effects';
import {
  sendData,
  setPlanToCanceled,
  updateStatus
} from 'screens/ManageSubscriptions/actions';
import {getCurrentProductFeatures, getIsWordpressDirect} from 'selectors';
import {status} from 'screens/ManageSubscriptions/reducer';

export function* apiCall() {
  try {
    const response = yield call(
      request,
      endpoints.plan.delete(config.current_plan)
    );
    return response;
  } catch (e) {
    throw new Error(e);
  }
}

export function* sendSurveyData() {
  yield put(updateStatus(status.SUBMITTING));
  try {
    const response = yield call(apiCall);
    if (response.body && response.body.status == 'ok') {
      // Show Success message
      yield put(updateStatus(status.SUCCESS));
      const features = yield select(getCurrentProductFeatures);
      const isWPPlugin = getIsWordpressDirect(features);
      if (isWPPlugin) {
        yield put(setPlanToCanceled());
      } else {
        // Llamar a window replace con call permite manejarlo mejor en los test.
        yield call(
          {context: window.location, fn: window.location.replace},
          response.body.redirect
        );
      }
    } else {
      yield put(updateStatus(status.ERROR));
    }
  } catch (e) {
    yield put(updateStatus(status.ERROR));
  }
}

function* SurveySaga() {
  yield takeEvery(sendData().type, sendSurveyData);
}

export default SurveySaga;
