/* eslint-disable camelcase */
import {showNotification} from 'components/UI/Notifications/actions';
import {updateEmailSettings, updateRecipients} from 'screens/Settings/actions';
import i18next from 'i18next';
import {endpoints} from 'lib/redux/sagas/api';
import {request} from 'lib/redux/sagas/request';
import {takeEvery, takeLatest} from 'redux-saga';
import {call, put, select} from 'redux-saga/effects';
import {
  saveEmailNotificationRequest,
  saveEmailNotificationsResponse,
  saveGeneralSettingsRequest,
  saveGeneralSettingsResponse,
  updateEmailRecipientsRequest,
  updateEmailSettingsRequest
} from 'screens/Settings/actions';

const errorNotification = showNotification({
  name: 'error',
  title: i18next.t('notification.settings.error')
});

const succesNotification = (input) =>
  showNotification({
    name: 'success',
    title: i18next.t(`notification.settings.${input}.updated`)
  });

const translationKeys = {
  EMAIL_NOTIFICATIONS: 'emailnotifications',
  COMPANY_PROFILE: 'companyprofile'
};

function* saveEmailNotificationSaga({payload}) {
  const {webmaster_name, webmaster_email} = payload;
  try {
    yield call(request, endpoints.emailNotifications.create(), {
      webmaster_email,
      webmaster_name
    });
    yield put(saveEmailNotificationsResponse(payload, false));
    yield put(succesNotification(translationKeys.EMAIL_NOTIFICATIONS));
  } catch (error) {
    yield put(saveEmailNotificationsResponse(payload, true));
    yield put(errorNotification);
  }
}

function* saveGeneralSettingsRequestSaga({payload}) {
  const {is_local, city, company_name, seoTargetCountry, goal, industry, lang} =
    payload;

  // Guardaremos algunos campos como null si vienen como false
  const cityValue = is_local && city ? city : null;

  // Cogemos del state el lang para compararlo más adelante
  const oldLang = yield select((state) => state.data.config?.user?.lang);

  try {
    yield call(request, endpoints.settings.create(), {
      company_name: company_name || null,
      city: cityValue,
      country: seoTargetCountry || null,
      goal: goal || null,
      industry: industry || null,
      lang: lang
    });
    yield put(saveGeneralSettingsResponse(payload, false));
    yield put(succesNotification(translationKeys.COMPANY_PROFILE));

    // Hacemos un reload si cambió el lang
    if (oldLang !== lang) {
      yield call(() => location.reload());
    }
  } catch (error) {
    yield put(saveGeneralSettingsResponse(payload, true));
    yield put(errorNotification);
  }
}

function* updateEmailSettingsRequestSaga({payload}) {
  try {
    const {
      body: {status}
    } = yield call(request, endpoints.emailSettings.create(), payload);
    if (status === 'ok') {
      yield put(succesNotification(translationKeys.EMAIL_NOTIFICATIONS));
      yield put(updateEmailSettings(payload));
    } else {
      yield put(errorNotification);
    }
  } catch (error) {
    yield put(errorNotification);
  }
}

function* updateEmailRecipientsRequestSaga({payload}) {
  try {
    const {
      body: {status}
    } = yield call(request, endpoints.emailRecipients.create(), payload);
    if (status === 'ok') {
      // yield put(succesNotification(translationKeys.EMAIL_NOTIFICATIONS));
      yield put(updateRecipients(payload));
    } else {
      yield put(errorNotification);
    }
  } catch (error) {
    yield put(errorNotification);
  }
}

export function* settingSaga() {
  yield [
    takeLatest(
      updateEmailSettingsRequest().type,
      updateEmailSettingsRequestSaga
    ),
    takeLatest(
      updateEmailRecipientsRequest().type,
      updateEmailRecipientsRequestSaga
    ),
    takeEvery(saveEmailNotificationRequest().type, saveEmailNotificationSaga),
    takeEvery(saveGeneralSettingsRequest().type, saveGeneralSettingsRequestSaga)
  ];
}

export default settingSaga;
