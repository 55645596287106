const DOMAIN = 'tools';
export const sendStructuredData = (values) => ({
  type: `${DOMAIN}/sendStructuredData`,
  payload: values
});

export const saveStructuredData = (values) => ({
  type: `${DOMAIN}/saveStructuredData`,
  payload: values
});

export const isSubmitting = (value) => ({
  type: `${DOMAIN}/isSubmitting`,
  payload: value
});
