import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Header = ({header}) => (
  <div className={cx({header})}>
    {header &&
      header.map((item, i) => (
        <div key={i} className="header-item">
          <span>{item}</span>
        </div>
      ))}
  </div>
);
const Data = (props) => {
  const {data, components, onDelete, onSelect} = props;
  return (
    <div className="data">
      {data &&
        data.map((item, i) => (
          <div
            key={i}
            onClick={() =>
              onSelect &&
              (props.notSelectableProp
                ? item?.[props.notSelectableProp] !== props.notSelectableValue
                : true) &&
              onSelect(item)
            }
            className={cx([
              'data-item',
              {
                'allow-delete': onDelete,
                'allow-select':
                  onSelect &&
                  (props.notSelectableProp
                    ? item?.[props.notSelectableProp] !==
                      props.notSelectableValue
                    : true),
                selected:
                  props.selected &&
                  item?.[props.selectedProp] === props.selected
              }
            ])}
          >
            {components &&
              components.map((Comp, j) => {
                let Component = Comp;
                return (
                  <div className="data-col" key={j}>
                    <Component item={item} {...props} />
                  </div>
                );
              })}
            {onDelete && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(item);
                }}
                className={cx({delete: onDelete})}
              ></div>
            )}
          </div>
        ))}
    </div>
  );
};

const List = (props) => (
  <div className="list">
    <Header header={props.header} />
    <Data
      data={props.data}
      components={props.components}
      onDelete={props.onDelete}
      onSelect={props.onSelect}
      selected={props.selected}
      selectedProp={props.selectedProp}
      notSelectableProp={props.notSelectableProp}
      notSelectableValue={props.notSelectableValue}
      {...props}
    />
  </div>
);
List.propTypes = {
  header: PropTypes.array,
  data: PropTypes.object,
  components: PropTypes.array,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func,
  selected: PropTypes.any,
  selectedProp: PropTypes.string,
  notSelectableProp: PropTypes.string,
  notSelectableValue: PropTypes.any
};

export default List;
