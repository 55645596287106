const getScreenByPathnameToPosthog = (pathname) => {
  if (pathname.startsWith('/task/')) {
    return 'task';
  }
  if (pathname.startsWith('/site-task/')) {
    return 'site-task';
  }

  return pathname.slice(1);
};

export const sendEventToPosthog = (name, pathname, ctaKey) => {
  posthog?.capture(name, {
    screen: getScreenByPathnameToPosthog(pathname),
    ctaKey
  });
};

export const sendEventOnClose = (pathname, ctaKey) => {
  sendEventToPosthog('upgrade modal close', pathname, ctaKey);
};

export const sendEventOnUpgrade = (pathname, ctaKey) => {
  sendEventToPosthog('upgrade modal click', pathname, ctaKey);
};

export const sendEventOnOpen = (pathname, ctaKey) => {
  sendEventToPosthog('upgrade modal visualization', pathname, ctaKey);
};
