import {put} from 'redux-saga/effects';

function* initialSaga() {
  yield put({type: 'APP_READY'});

  // eslint-disable-next-line no-console
  if (process.env.NODE_ENV == 'development') console.log('APP READY!');
}

export default initialSaga;
