import {
  addCompetitor,
  loadedSuggestedCompetitors,
  loadSuggestedCompetitors,
  removeCompetitor
} from 'components/Competitors/actions';
import {modalHide} from 'components/UI/Modal/actions';
import {endpoints} from 'lib/redux/sagas/api';
import {request} from 'lib/redux/sagas/request';
import {takeEvery} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

export function* addCompetitorSaga({payload: {competitor}}) {
  yield put(modalHide());
  yield call(request, endpoints.competitor.create(), {
    domain: competitor.domain
  });
}

export function* removeCompetitorSaga({payload: {competitorId}}) {
  yield put(modalHide());
  yield call(request, endpoints.competitor.delete(competitorId));
}

export function* loadSuggestedCompetitorsSaga() {
  try {
    const response = yield call(request, endpoints.competitor.read());
    if (response && Array.isArray(response.body)) {
      yield put(loadedSuggestedCompetitors(response.body));
    } else {
      yield put(loadedSuggestedCompetitors([]));
    }
  } catch (error) {
    yield put(loadedSuggestedCompetitors([]));
  }
}

export function* CompetitorsSaga() {
  yield [
    takeEvery(loadSuggestedCompetitors().type, loadSuggestedCompetitorsSaga),
    takeEvery(addCompetitor().type, addCompetitorSaga),
    takeEvery(removeCompetitor().type, removeCompetitorSaga)
  ];
}

export default CompetitorsSaga;
