import {startScanning, websocketActions} from 'app/Scanning/actions';
import produce from 'immer';

export default (state = {}, {type, payload}) =>
  produce(state, (draft) => {
    switch (type) {
      /**
       * action: START_SCANNING
       *
       * Esta acción se envía desde la App mediante los botones o enlaces que
       * quieran inciar un scanning. Como es una ACCION, el "saga"
       * correspondiente iniciará el scanning según convenga, normalmente por
       * un POST a un AJAX para iniciar el scanning.
       *
       * Como reducer, vamos a hacer una actualización OPTIMISTA del state
       * creando el valor en "state.scanning[uuid]" con los campos que
       * debería tener al empezar el scanning y así indicar que el site con
       * este UUID está actualizándose.
       */
      case startScanning().type:
        draft[payload.uuid] = {
          pages_found: 0,
          pages_read: 0
        };
        break;
      /**
       * actions: SCANNING_STARTED y SCANNING_PROGRESS
       *
       * Estos mensajes LOS ENVIA EL SERVIDOR por el canal de websockets
       * conforme va avanzando el scan. El reducer sólo debe actualizar los
       * valores que le llegan.
       */
      case websocketActions.SCANNING_STARTED:
      case websocketActions.SCANNING_PROGRESS:
        draft[payload.uuid] = payload;
        break;

      /**
       * action: SCANNING_COMPLETED
       *
       * Lo envía el servidor cuando se termina el scanning. Aquí la única
       * acción a tener en cuenta es cuando existe el campo
       * "payload.scanning_error", que indica que el scan falló por algún
       * motivo y por tanto debemos marcar el scan como terminado.
       * En cualquier otro caso se debe comportar igual que en los mensajes
       * SCANNING_STARTED ó SCANNING_PROGRESS.
       */
      case websocketActions.SCANNING_COMPLETED:
        if (payload.scanning_error) {
          // Eliminamos del state el scanning del UUID.
          // Ya no está escaneando, y el error se debería marcar en el
          // site almacenado en "state.data.plans", pero eso ya no es
          // tarea de este reducer.
          delete draft[payload.uuid];
          break;
        }
        // En caso contrario, hacemos como en los SCANNING_PROGRESS
        draft[payload.uuid] = payload;
        break;

      /**
       * action: REFRESH_PLAN_DATA
       *
       * Resultado de AJAX de /ajax/plan/xxxx puede ser como respuesa a una
       * acción SCANNING_TASKS_COMPLETED desde el servidor o bien porque el
       * UI haya decidido actualizar los datos por otro motivo.
       *
       * En el reducer de Scanning, significa que hemos terminado el scanning
       * pero debemos cerciorarnos de que EFECTIVAMENTE HA TERMINADO.
       *
       * Casos: Recibimos REFRESH_PLAN_DATA:
       *      A) state.scanning[uuid] puede no existir
       *      B) state.scanning[uuid] existe y "pages_read" != "pages_found"
       *      C) state.scanning[uuid] existe y "pages_read" == "pages_found"
       *
       * Si nos encontramos con el caso C) debemos ELIMINAR los datos de
       * "state.scanning[uuid]" para marcar el scaneado como completo.
       */

      case websocketActions.REFRESH_PLAN_DATA:
        const current_scan = draft[payload.uuid];

        // A) No hacer nada aquí porque no había scaneo en curso
        if (!current_scan) break;

        const pages_read = current_scan.pages_read;
        const pages_found = current_scan.pages_found;

        // B) No hacer nada si el escaneo está en curso y no terminado
        if (pages_read != pages_found) break;

        // C) Ultimo caso, escaneo en curso, pero debemos comprobar que
        //    "pages_found" no sea cero, en cuyo caso el escaneo acaba de
        //    empezar (vaya casualidad!)
        if (!pages_found) break;

        // Hemos detectado scaning en curso y terminado, podemos eliminar
        // el scanning del state...
        delete draft[payload.uuid];
        break;

      default:
        return draft;
    }
  });
