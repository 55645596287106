import {connect} from 'react-redux';
import {Navigation, Typography} from '@marketgoo/components';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {
  Gear,
  House,
  Question,
  UserCircle,
  XCircle
} from '@phosphor-icons/react';

const Account = ({session, helpLink, manageAccount}) => {
  const {t} = useTranslation();

  return session ? (
    <Navigation.Collapse
      closeOnClickChildren
      trigger={
        <Navigation.Button>
          <UserCircle size={24} weight="fill" />
        </Navigation.Button>
      }
    >
      {manageAccount && (
        <Navigation.Button
          as={Link}
          to="/myprofile"
          active={window.location.pathname === '/myprofile'}
        >
          <House size={24} weight="fill" />{' '}
          <Typography as="span" m={{l: 1}}>
            {t('menu.profile')}
          </Typography>
        </Navigation.Button>
      )}
      <Navigation.Button
        as={Link}
        to="/settings"
        active={window.location.pathname.startsWith('/settings')}
      >
        <Gear size={24} weight="fill" />{' '}
        <Typography as="span" m={{l: 1}}>
          {t('menu.settings')}
        </Typography>
      </Navigation.Button>
      {helpLink && (
        <Navigation.Button as="a" href={helpLink} rel="noopener noreferrer">
          <Question size={24} weight="fill" />{' '}
          <Typography as="span" m={{l: 1}}>
            {t('menu.help')}
          </Typography>
        </Navigation.Button>
      )}
      <Navigation.Button as="a" href="/logout">
        <XCircle size={24} weight="fill" />{' '}
        <Typography as="span" m={{l: 1}}>
          {t('menu.logout')}
        </Typography>
      </Navigation.Button>
    </Navigation.Collapse>
  ) : (
    <></>
  );
};

const mapStateToProps = ({data}) => ({
  session: !!data.config?.user,
  helpLink: data.config?.dealer?.help_link,
  manageAccount: data.config?.dealer?.manage_account
});

export default connect(mapStateToProps)(Account);
