import {showNotification} from 'components/UI/Notifications/actions';
import i18next from 'i18next';
import {endpoints} from 'lib/redux/sagas/api';
import {request} from 'lib/redux/sagas/request';
import {delay, takeEvery} from 'redux-saga';
import {call, put} from 'redux-saga/effects';
import {
  saveContactDataRequest,
  saveContactDataResponse
} from 'screens/Profile/Contact/actions';

export function* saveContactDataRequestSaga(action) {
  yield call(request, endpoints.myProfileContact.create(), {
    name: action.values.name,
    password: action.values.password,
    lang: action.values.language
  });
  yield put(saveContactDataResponse());
  if (
    !action.extras ||
    (action.extras && action.extras.successMessage !== null)
  ) {
    yield put(
      showNotification({
        name: 'success',
        title: i18next.t('notification.contact.save.success')
      })
    );
    yield delay(1000);
    location.reload();
  }
}

export function* ContactDataSaga() {
  yield [takeEvery(saveContactDataRequest().type, saveContactDataRequestSaga)];
}

export default ContactDataSaga;
