import SuperAgent from 'superagent';

const defaultHeaders = {
  Accept: 'application/vnd.marketgoo.api+json'
};

function request(method, url) {
  return SuperAgent(method, url).set(defaultHeaders);
}

export default request;
