import {ButtonIcon, Icon, Table, Tooltip} from '@marketgoo/components';
import cx from 'classnames';
import {addKeyword} from 'components/Keywords/actions';
import {findKeyword} from 'components/Keywords/filters';
import numeral from 'misc/Numeral';
import {connect} from 'react-redux';

const SuggestedTable = ({
  uuid,
  currentKeywords,
  suggestedKeywords,
  full,
  add
}) => {
  return (
    <Table gridTemplateColumns="2fr 1fr 1fr 1fr">
      <Table.Header>
        <Table.Cell>{__('Suggested Keywords')}</Table.Cell>
        <Table.Cell>
          {__('Position in Google')}
          <Tooltip trigger={<ButtonIcon as="span" icon="help" />}>
            {__("The current 'rank' of this keyword on Google.")}
          </Tooltip>
        </Table.Cell>
        <Table.Cell>
          {__('Search volume')}
          <Tooltip trigger={<ButtonIcon as="span" icon="help" />}>
            {__(
              'The number of times this exact search term was searched for in the past month.'
            )}
          </Tooltip>
        </Table.Cell>
        <Table.Cell></Table.Cell>
      </Table.Header>
      {suggestedKeywords.map((keyword, idx) => {
        let added = findKeyword(currentKeywords, keyword?.id);
        let position = keyword?.ranking;
        return (
          <Table.Row className={cx('suggested', {added: added})} key={idx}>
            <Table.Cell>
              {keyword?.name}
              {added && (
                <strong>
                  {' '}
                  <i>{__('(already added)')}</i>
                </strong>
              )}
            </Table.Cell>
            <Table.Cell>
              {position ? (
                numeral(position, 'value', 'miles', '0')
                  .replace(' ', '')
                  .toUpperCase()
              ) : (
                <span title={__('Not ranked')}>
                  <Icon name="null" size="small" />
                </span>
              )}
            </Table.Cell>
            <Table.Cell>
              {keyword?.search_volume
                ? numeral(keyword?.search_volume, 'value', 'miles', '0')
                    .replace(' ', '')
                    .toUpperCase()
                : 0}
            </Table.Cell>
            <Table.Cell>
              <ButtonIcon
                disabled={!!added || full}
                onClick={() => add(keyword, uuid)}
                icon="plus"
              />
            </Table.Cell>
          </Table.Row>
        );
      })}
    </Table>
  );
};

const mapStateToProps = (
  {data: {plans, config}},
  {currentKeywords, suggestedKeywords}
) => {
  const plan = plans?.[config?.current_plan];
  const product = plan?.product;
  const maxKeywords =
    config?.dealer?.products?.[product]?.features?.max_keywords;
  return {
    uuid: config?.current_plan,
    currentKeywords,
    suggestedKeywords,
    full: currentKeywords.length >= maxKeywords
  };
};

const mapDispatchToProps = (dispatch) => ({
  add: (keyword, uuid) => {
    dispatch(addKeyword(keyword, uuid));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SuggestedTable);
