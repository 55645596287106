import {modalHide} from 'components/UI/Modal/actions';
import {eventNames} from 'constants/events';
import {sendEvent} from 'lib/redux/actions/events';
import {upgradeClick} from 'lib/redux/actions/upgrade';
import {takeEvery} from 'redux-saga';
import {put} from 'redux-saga/effects';

export function* upgradeClickSaga({payload}) {
  yield put(modalHide());
  yield put(
    sendEvent({
      event: eventNames.UPGRADE_CLICK,
      ...payload
    })
  );
}

export function* upgradeSaga() {
  yield takeEvery(upgradeClick().type, upgradeClickSaga);
}

export default upgradeSaga;
