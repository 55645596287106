import produce from 'immer';
import {
  loadSuggestedCompetitors,
  loadedSuggestedCompetitors
} from 'components/Competitors/actions';

export const initialState = {
  suggestions: [],
  isLoadingSuggestions: false,
  error: null
};

export default (state = initialState, {type, payload}) =>
  produce(state, (draft) => {
    switch (type) {
      case loadSuggestedCompetitors().type:
        draft.isLoadingSuggestions = true;
        break;
      case loadedSuggestedCompetitors().type:
        draft.isLoadingSuggestions = false;
        draft.suggestions = payload.suggestions;
        break;
      default:
        draft;
        break;
    }
  });
