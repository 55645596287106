import {modalShow} from 'components/UI/Modal/actions';
import i18next from 'i18next';
import {endpoints} from 'lib/redux/sagas/api';
import {request} from 'lib/redux/sagas/request';
import {getAffiliateTracking} from 'misc/helper';
import {takeEvery} from 'redux-saga';
import {call, put} from 'redux-saga/effects';
import {addSiteRequest, addSiteResponse} from 'screens/AddSite/actions';

const modalError = modalShow('MODAL_INFO', {
  title: i18next.t('notification.global.error.tryagain.title'),
  description: i18next.t('notification.global.error.tryagain.description'),
  error: true
});

export function* saveConfigurationRequest({values}) {
  values.partner_token = getAffiliateTracking();

  try {
    const response = yield call(request, endpoints.myWebsites.create(), values);
    if (response.body && response.body.status == 'ok') {
      yield put(addSiteResponse());

      // Llamar a window replace con call permite manejarlo mejor en los test.
      yield call(
        {context: window.location, fn: window.location.replace},
        response.body.redirect
      );
    } else {
      yield put(modalError);
      yield put(addSiteResponse());
    }
  } catch (e) {
    yield put(modalError);
    yield put(addSiteResponse());
  }
}

export function* ConfigurationSaga() {
  yield [takeEvery(addSiteRequest().type, saveConfigurationRequest)];
}

export default ConfigurationSaga;
