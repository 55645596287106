import React from 'react';
import cx from 'classnames';

/* por defecto ponemos el horizontal con el texto oscuro */
const AnalyticsLogoLink = ({position = 'horizontal', color = 'black'}) => {
  const classes = cx({
    'analytics-logo': true,
    horizontal: position == 'horizontal',
    vertical: position == 'vertical'
  });
  return (
    <a
      className={classes}
      href="//www.google.com/analytics"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={
          '/img/logos/logo_lockup_analytics_icon_' +
          position +
          '_' +
          color +
          '_2x.png'
        }
      />
    </a>
  );
};

export default AnalyticsLogoLink;
