import {
  disableAnalytics,
  resetSelectProfile,
  updateSelectProfile
} from 'app/Data/plans/plan/actions';
import {modalHide, modalShow} from 'components/UI/Modal/actions';
import i18next from 'i18next';
import config from 'lib/application-config';
import {endpoints} from 'lib/redux/sagas/api';
import {request} from 'lib/redux/sagas/request';
import {takeEvery} from 'redux-saga';
import {call, put, select} from 'redux-saga/effects';

const modalErrorNeedAccount = modalShow('MODAL_CONFIRM', {
  title: i18next.t('notification.analytics.google.needaccount.title'),
  description: i18next.t(
    'notification.analytics.google.needaccount.description'
  ),
  confirmButtonText: i18next.t(
    'notification.analytics.google.needaccount.confirmButtonText'
  ),
  onConfirm: () => window.open('//www.google.com/analytics')
});

const modalErrorConnect = modalShow('MODAL_INFO', {
  title: i18next.t('notification.analytics.google.connection.error.title'),
  description: i18next.t(
    'notification.analytics.google.connection.error.description'
  ),
  error: true
});

export function* getProfiles() {
  try {
    const response = yield call(request, endpoints.googleAnalytics.read());
    return response.body;
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV == 'development') console.log('error!');
  }
}

export function* sendProfile({profile}) {
  try {
    const response = yield call(
      request,
      endpoints.googleAnalytics.create(),
      profile
    );

    if (response.body && response.body.type && response.body.payload) {
      const {type, payload} = response.body;
      yield put({
        type,
        payload: {
          uuid: payload.plan.uuid,
          plan: payload.plan
        }
      });

      // Lanzamos el scan si no tiene el cta para el scanning
      // TODO:
      // tener un metodo que nos permita saber que tipo de producto tenemos
      // sin usar el nombre del producto.

      yield put(modalHide());
    } else {
      return response;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV == 'development') console.log('error!');
  }
}

export const checkInsufficientPermissions = (errors = []) => {
  const errorsReason = errors.filter(
    (err) => err?.reason === 'insufficientPermissions'
  );
  return errorsReason.length > 0;
};

function* errorsControl(errorData) {
  if (checkInsufficientPermissions(errorData)) {
    yield put(modalErrorNeedAccount);
  } else {
    yield put(modalErrorConnect);
  }
}

export function* checkProfile() {
  const site = yield select(
    (state) => state.data.plans?.[config.current_plan]?.site
  );
  const analytics = site?.config?.google_analytics;

  // Si "site.config.google_analytics" ya es null, no tenemos que abrir el
  // formulario de selección de profile, puesto que aún no ha dado permisos
  // para unir la cuenta...
  if (!analytics) return;

  const profile = analytics?.profile;
  if (!profile) {
    // OK, SI TENEMOS analytics, pero NO TENEMOS profile, con lo que hay
    // que pedirlo lanzando el modal de selección

    const data = yield getProfiles();
    if (data.error) {
      yield errorsControl(data.error.errors);
      yield call(request, endpoints.googleAnalytics.update());
    } else {
      yield put(modalShow('ANALYTICS_PROFILES', {profiles: data}));
    }
  }
}

export function* selectProfile() {
  const data = yield getProfiles();

  if (data.error) {
    yield errorsControl(data.error.errors);
    yield call(request, endpoints.googleAnalytics.update());
  } else {
    yield put(modalShow('ANALYTICS_PROFILES', {profiles: data}));
  }
}

export function* updateSelectProfileSaga() {
  try {
    yield call(request, endpoints.googleAnalytics.update());
    return;
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV == 'development') console.log('error!');
  }
}

export function* disableAnalyticsSaga() {
  try {
    yield call(request, endpoints.googleAnalytics.update());
    yield put(resetSelectProfile(config.current_plan));
    return;
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV == 'development') console.log('error!');
  }
}

export function* resetSelectProfileSaga() {
  try {
    const response = yield call(request, endpoints.googleAnalytics.update());
    return response.body;
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV == 'development') console.log('error!');
  }
}

export function* analytics() {
  yield takeEvery(resetSelectProfile().type, resetSelectProfileSaga);
  yield takeEvery(updateSelectProfile().type, updateSelectProfileSaga);
  yield takeEvery(disableAnalytics().type, disableAnalyticsSaga);
  yield takeEvery('PROFILE_SELECTED', sendProfile);
  yield takeEvery('CHECK_GA_PROFILE', checkProfile);
  yield takeEvery('SELECT_GA_PROFILE', selectProfile);
}

export default analytics;
