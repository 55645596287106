import {Button, ButtonGroup, Field, Input} from '@marketgoo/components';
import {getMinLengthRule, required} from 'components/UI/Form/validators';
import React from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

const RememberCheck = ({url_check, token}) => {
  const {t} = useTranslation();

  const {
    register,
    handleSubmit,
    formState: {errors, isSubmitting}
  } = useForm({defaultValues: {password: '', repassword: '', token}});

  const onSubmit = () => {
    document.getElementById('form_remember_check').submit();
  };

  return (
    <section id="remember-container">
      <section className="ola_ly-pageCenter">
        <header>
          <h2 className="ola-font-5-medium ola-nomargin">
            {t('remember.check.header.title')}
          </h2>
        </header>
        <form
          id="form_remember_check"
          action={url_check.replace(/:token/, token)}
          method="post"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="inputs-block">
            <div>
              <Field
                id="password"
                label={t('remember.check.password.label')}
                error={!!errors.password}
                description={
                  errors && errors.password && errors.password.message
                }
              >
                <Input
                  type="password"
                  placeholder={t('wp.login.password.help')}
                  {...register('password', {
                    required,
                    minLength: getMinLengthRule(6)
                  })}
                />
              </Field>
              <Field
                id="repassword"
                label={t('remember.check.repassword.label')}
                error={!!errors.repassword}
                description={
                  errors && errors.repassword && errors.repassword.message
                }
              >
                <Input
                  type="password"
                  {...register('repassword', {
                    required,
                    minLength: getMinLengthRule(6)
                  })}
                />
              </Field>
              <input type="hidden" value={token} name="token" />
            </div>
          </div>
          <div className="actions">
            <ButtonGroup className="ola-margin-top-3 ola-margin-bottom-3">
              <Button
                variant="primary"
                busy={isSubmitting && t('g.please.wait')}
              >
                {t('remember.check.submit.button')}
              </Button>
            </ButtonGroup>
          </div>
        </form>
      </section>
    </section>
  );
};

export default RememberCheck;
