const DOMAIN = 'competitors';

export const addCompetitor = (competitor, uuid) => ({
  type: `${DOMAIN}/add`,
  payload: {
    competitor,
    uuid
  }
});

export const removeCompetitor = (competitorId, uuid) => ({
  type: `${DOMAIN}/remove`,
  payload: {
    competitorId,
    uuid
  }
});

export const loadSuggestedCompetitors = () => ({
  type: `${DOMAIN}/loadSuggested`
});

export const loadedSuggestedCompetitors = (suggestions) => ({
  type: `${DOMAIN}/loadedSuggested`,
  payload: {suggestions}
});
