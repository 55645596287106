import {showNotification} from 'components/UI/Notifications/actions';
import i18next from 'i18next';
import {endpoints} from 'lib/redux/sagas/api';
import {request} from 'lib/redux/sagas/request';
import {takeEvery} from 'redux-saga';
import {call, put, select} from 'redux-saga/effects';
import {
  saveCardRequest,
  updateCreditCard
} from 'screens/Profile/Billing/Card/actions';

export const cardHolderNameSelector = (state) =>
  state.data.config.user?.billing?.card?.name;

export function* apiCall(params) {
  try {
    const response = yield call(
      request,
      endpoints.myProfileCreditCard.create(),
      params
    );
    if (response.body.status !== 'ok') throw {code: 'api_reponse_error'};
    return response;
  } catch (e) {
    // Este throw automaticamente dispara el catch del saveCardRequest
    throw {code: 'api_reponse_error'};
  }
}

export function* clientSecretCall() {
  // Get the client_secret to get setupintets
  try {
    const clientSecret = yield call(request, endpoints.setupIntent.read());
    if (clientSecret.body.status !== 'ok') {
      throw {code: 'api_reponse_error'};
    }
    return clientSecret;
  } catch (error) {
    throw {code: 'api_reponse_error'};
  }
}

export function* saveCardRequestSaga({payload}) {
  const {stripe, element, cardHolderName} = payload;

  const storedCardHolderName = yield select(cardHolderNameSelector);
  const paymentMethod = {
    payment_method_data: {
      billing_details: {
        name: cardHolderName ? cardHolderName : storedCardHolderName
      }
    }
  };

  try {
    const clientSecret = yield call(clientSecretCall);
    const {setupIntent, error} = yield call(
      stripe.handleCardSetup,
      clientSecret.body.secret,
      element,
      paymentMethod
    );
    if (error) throw {code: 'setup_intent_error'};
    const response = yield call(apiCall, {setup_intent: setupIntent});
    yield put(updateCreditCard(response.body.default_payment_method));
    yield put(
      showNotification({
        name: 'success',
        title: i18next.t('notification.billing.creditcard.save.success')
      })
    );
  } catch (e) {
    yield put(
      showNotification({
        name: 'error',
        title: i18next.t('notification.billing.creditcard.save.error')
      })
    );
  }
}

export function* CreditCardSaga() {
  yield [takeEvery(saveCardRequest().type, saveCardRequestSaga)];
}

export default CreditCardSaga;
