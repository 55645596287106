import produce from 'immer';
import {selectDomain} from 'components/Popularity/actions';

export const initialState = {
  selected: null
};

export default (state = initialState, {type, payload = null}) =>
  produce(state, (draft) => {
    switch (type) {
      case selectDomain().type:
        draft.selected = payload;
        break;
      default:
        draft;
        break;
    }
  });
