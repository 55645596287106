const DOMAIN = 'ajax';

export const totalPagesRequest = () => ({
  type: `${DOMAIN}/totalPagesRequest`
});

export const totalPagesResponse = (total_pages, pending_pages) => ({
  type: `${DOMAIN}/totalPagesResponse`,
  total_pages,
  pending_pages
});

export const pagesRequest = (
  filters = [],
  search = null,
  limit = 10,
  offset = 0
) => ({
  type: `${DOMAIN}/pagesRequest`,
  filters,
  search,
  limit,
  offset
});

export const pagesResponse = (response) => ({
  type: `${DOMAIN}/pagesResponse`,
  response
});

export const loadMorePagesRequest = (url) => ({
  type: `${DOMAIN}/loadMorePagesRequest`,
  url
});

export const loadMorePagesResponse = (response) => ({
  type: `${DOMAIN}/loadMorePagesResponse`,
  response
});

// Update MODAL on Refresh plan data
export const UPDATE_OYP_TASK_MODAL = 'UPDATE_OYP_TASK_MODAL';
export const updateOypTaskModal = (data) => ({
  type: UPDATE_OYP_TASK_MODAL,
  data
});

export const pageDataByIdRequest = (pageId) => ({
  type: `${DOMAIN}/pageDataByIdRequest`,
  page_id: pageId
});

export const pageDataByIdResponse = (response) => ({
  type: `${DOMAIN}/pageDataByIdResponse`,
  response
});

export const resetPageData = () => ({
  type: `${DOMAIN}/resetPageData`
});

export const nextPageSuggestedRequest = () => ({
  type: `${DOMAIN}/nextPageSuggestedRequest`
});

export const nextPageSuggestedResponse = (response) => ({
  type: `${DOMAIN}/nextPageSuggestedResponse`,
  response
});

export const recommendedKeywordsRequest = (taskId) => ({
  type: `${DOMAIN}/recommendedKeywordsRequest`,
  taskId
});

export const recommendedKeywordsResponse = (response) => ({
  type: `${DOMAIN}/recommendedKeywordsResponse`,
  response
});
