import Dialog from 'components/UI/Modal/Dialog';
import cx from 'classnames';

const DialogModalInfo = ({
  title,
  description,
  error,
  image,
  content,
  confirmButtonText
}) => (
  <Dialog
    title={title}
    description={description}
    content={content}
    image={image}
    confirmButtonText={confirmButtonText}
    className={cx(['modal-dialog', {error}])}
  />
);

export default DialogModalInfo;
