import {
  Button,
  ButtonGroup,
  ButtonIcon,
  Check,
  Icon,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SearchField,
  Table,
  Tooltip
} from '@marketgoo/components';
import {searchKeywordsRequest} from 'components/Keywords/actions';
import KeywordCompetition from 'components/Keywords/components/KeywordCompetition';
import {
  filterAssignedKeywords,
  findKeyword,
  filterTrackedKeywords,
  trackedUnasignedKeywords
} from 'components/Keywords/filters';
import {startKeywordAssign} from 'components/TasksPanel/actions';
import {modalHide} from 'components/UI/Modal/actions';
import {getTitleFromPage} from 'misc/helper';
import numeral from 'misc/Numeral';
import React, {Fragment, useState} from 'react';
import {connect} from 'react-redux';

const KeywordsFoundTable = ({children}) => {
  return (
    <Table gridTemplateColumns="60px 2fr 1fr 1fr">
      <Table.Header>
        <Table.Cell></Table.Cell>
        <Table.Cell>{__('Keywords found')}</Table.Cell>
        <Table.Cell>
          {__('Competition')}
          <Tooltip trigger={<ButtonIcon as="span" icon="help" />}>
            {__(
              'Indication on how hard is for your website to rank the keyword on search engines. Easy keywords are more likely to bring traffic to your website'
            )}
          </Tooltip>
        </Table.Cell>
        <Table.Cell>
          {__('Search volume')}
          <Tooltip trigger={<ButtonIcon as="span" icon="help" />}>
            {__(
              'The number of times this exact search term was searched for in the past month.'
            )}
          </Tooltip>
        </Table.Cell>
      </Table.Header>
      {children}
    </Table>
  );
};

const UnusedKeywordsTable = ({children}) => {
  return (
    <Table gridTemplateColumns="60px 2fr 1fr">
      <Table.Header>
        <Table.Cell></Table.Cell>
        <Table.Cell>{__('Your unused keywords')}</Table.Cell>
        <Table.Cell>
          {__('Your Google position')}
          <Tooltip trigger={<ButtonIcon as="span" icon="help" />}>
            {__("The current 'rank' of this keyword on Google.")}
          </Tooltip>
        </Table.Cell>
      </Table.Header>
      {children}
    </Table>
  );
};

/*
// Al inicio (hook) deberíamos:
// reset del state de keyword
// pedir las unsigned traked keyword
*/

const OfsSelectKeyword = ({
  page, // page data
  alreadyAsignedKeywords, // listado de keywords ya asignadas
  trackedKeywords, // listado de keywords tracked
  unUsedTrackedKeywords, // listado de traked keywords sin asignar
  hideModal,
  searchAction, // action para buscar keywords
  searching, // buscando keywords
  searchedKeywords, // keywords encontradas
  assingKeyword, // assignar una keyword a un página
  fullMaxKeywords, // no se pueden añadir más keywords,
  busy
}) => {
  // State
  const [keywordSelected, setKeywordSelected] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [addToTrackedList, setAddToTrackedList] = useState(false);

  // show when ...
  const showSearchedKeywords =
    !searching && searchedKeywords && searchedKeywords.length > 0;
  const showUnUsedKeywords =
    !searching &&
    !showSearchedKeywords &&
    unUsedTrackedKeywords &&
    unUsedTrackedKeywords.length > 0;
  const showModalFooter = showUnUsedKeywords || showSearchedKeywords;

  const showCheckForAddToTrakedList =
    keywordSelected && !findKeyword(unUsedTrackedKeywords, keywordSelected?.id);

  // Title
  const pageTitle = getTitleFromPage(page);

  const modalIntro = (
    <span
      dangerouslySetInnerHTML={{
        __html: __(
          'Choose the target keyword for the page: <strong>{pageTitle}</strong>',
          {pageTitle: pageTitle}
        )
      }}
    />
  );

  const __searchSubmit = (e) => {
    e.preventDefault();
    if (!searchKeyword || searchKeyword === '') {
      return false;
    }
    setKeywordSelected(null);
    searchAction(searchKeyword);
  };

  const handleCheck = () => {
    setAddToTrackedList(!addToTrackedList);
    posthog?.capture('Keyword add to list check');
  };
  return (
    <Modal open onClose={hideModal} closable={!busy}>
      <ModalHeader title={__('Select a keyword')} intro={modalIntro} />
      <ModalContent>
        <form onSubmit={__searchSubmit}>
          <SearchField
            busy={searching}
            disabled={busy}
            onInput={(e) => setSearchKeyword(e.target.value)}
            description={__(
              "Use commas to search for more than one keyword, for example: 'used cars, for sale'"
            )}
            id="search-button"
            placeholder={__('Type your keyword')}
            textButton={searching ? __('Searching...') : __('Search')}
          />
        </form>
      </ModalContent>

      {/* Unused tracked keywords */}
      {showUnUsedKeywords && (
        <ModalContent variant="scroll">
          <UnusedKeywordsTable>
            {unUsedTrackedKeywords.map((keyword, idx) => {
              const positionValue =
                keyword?.rankings?.google?.position?.value || keyword?.ranking;
              const position = positionValue ? (
                numeral(positionValue, 'value', 'miles', '0,0.[00]')
              ) : (
                <span title={__('Not ranked')}>
                  <Icon name="null" size="small" />
                </span>
              );

              return (
                <Table.Row key={idx}>
                  <Table.Cell
                    selected={
                      keywordSelected && keyword?.id === keywordSelected?.id
                    }
                  >
                    <Check
                      type="radio"
                      name="keyword"
                      onChange={() => setKeywordSelected(keyword)}
                    ></Check>
                  </Table.Cell>
                  <Table.Cell
                    selected={
                      keywordSelected && keyword?.id === keywordSelected?.id
                    }
                  >
                    {keyword?.name}
                  </Table.Cell>
                  <Table.Cell
                    selected={
                      keywordSelected && keyword?.id === keywordSelected?.id
                    }
                  >
                    {position}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </UnusedKeywordsTable>
        </ModalContent>
      )}

      {/* Searched keywords */}
      {showSearchedKeywords && (
        <ModalContent variant="scroll">
          <KeywordsFoundTable>
            {searchedKeywords.map((keyword, idx) => {
              let alreadyAsigned = !!findKeyword(
                alreadyAsignedKeywords,
                keyword?.id
              );
              let matchWithSearched = searchKeyword === keyword?.name;

              // Si ya se está usando no la mostramos a no ser que sea igual que la búsqueda
              if (alreadyAsigned && matchWithSearched) {
                return (
                  <Table.Row className="added" key={idx}>
                    <Table.Cell>
                      <Check
                        disabled={true}
                        type="radio"
                        name="keyword"
                        onChange={() => {}}
                      ></Check>
                    </Table.Cell>
                    <Table.Cell>
                      {keyword?.name}&#32;
                      <strong>
                        {' '}
                        <i>{__('(already asigned)')}</i>
                      </strong>
                    </Table.Cell>
                    <Table.Cell>
                      <KeywordCompetition competition={keyword?.competition} />
                    </Table.Cell>
                    <Table.Cell>
                      {keyword?.searchVolume
                        ? numeral(
                            keyword?.searchVolume,
                            'value',
                            'miles',
                            '0,0.[00]'
                          )
                        : 0}
                    </Table.Cell>
                  </Table.Row>
                );
              }

              // Si no se está usando mostramos el resultado
              return !alreadyAsigned ? (
                <Table.Row key={idx}>
                  <Table.Cell
                    selected={
                      keywordSelected && keyword?.id === keywordSelected?.id
                    }
                  >
                    <Check
                      type="radio"
                      name="keyword"
                      onChange={() => setKeywordSelected(keyword)}
                    ></Check>
                  </Table.Cell>
                  <Table.Cell
                    selected={
                      keywordSelected && keyword?.id === keywordSelected?.id
                    }
                  >
                    {keyword?.name}
                  </Table.Cell>
                  <Table.Cell
                    selected={
                      keywordSelected && keyword?.id === keywordSelected?.id
                    }
                  >
                    <KeywordCompetition competition={keyword?.competition} />
                  </Table.Cell>
                  <Table.Cell
                    selected={
                      keywordSelected && keyword?.id === keywordSelected?.id
                    }
                  >
                    {keyword?.searchVolume
                      ? numeral(
                          keyword?.searchVolume,
                          'value',
                          'miles',
                          '0,0.[00]'
                        )
                      : 0}
                  </Table.Cell>
                </Table.Row>
              ) : (
                <></>
              );
            })}
          </KeywordsFoundTable>
        </ModalContent>
      )}

      {/* Modal footer */}
      {showModalFooter && (
        <ModalFooter>
          {showCheckForAddToTrakedList && (
            <Check
              name="addToTracked"
              disabled={fullMaxKeywords || busy}
              onChange={handleCheck}
            >
              {fullMaxKeywords ? (
                <p className="ola-font-0-regular">
                  {__(
                    "You cannot add the keyword to the tracked keywords list because it's full"
                  )}
                </p>
              ) : (
                <Fragment>
                  {__('Add to the list of tracked keywords')}
                  <Tooltip trigger={<ButtonIcon as="span" icon="help" />}>
                    {__(
                      'This list contains the keywords that are most important to rank your site in the best positions of the search engines.'
                    )}
                  </Tooltip>
                </Fragment>
              )}
            </Check>
          )}
          <ButtonGroup variant="reversed">
            <Button
              busy={busy && __('Please wait ...')}
              variant="primary"
              disabled={!keywordSelected}
              onClick={() => {
                const keywordAlreadyTracked = !!findKeyword(
                  trackedKeywords,
                  keywordSelected?.id
                );
                assingKeyword(
                  keywordSelected,
                  page,
                  addToTrackedList,
                  keywordAlreadyTracked
                );
                posthog?.capture('Keyword select keyword click ');
              }}
            >
              {__('Select')}
            </Button>
            <Button disabled={busy} variant="secondary" onClick={hideModal}>
              {__('Cancel')}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      )}
    </Modal>
  );
};

const mapState = ({data: {plans, config}, keywords, tasks}, {page}) => {
  const plan = plans[config?.current_plan];
  const product = plan?.product;
  const site = plan?.site;

  const sitekeywords = site?.keywords;
  const trackedKeywords = filterTrackedKeywords(sitekeywords);
  const alreadyAsignedKeywords = filterAssignedKeywords(sitekeywords);
  const unUsedTrackedKeywords = trackedUnasignedKeywords(sitekeywords);

  const searchedKeywords = keywords?.search_results;
  const searching = keywords?.searching;

  const maxKeywords =
    config?.dealer?.products?.[product]?.features?.max_keywords || 20;

  const fullMaxKeywords = trackedKeywords.length >= maxKeywords;

  const keywordsCta = config?.dealer?.cta?.keywords;
  const busy = tasks?.keyword_assign_busy || false;

  return {
    page,
    sitekeywords,
    alreadyAsignedKeywords,
    trackedKeywords,
    unUsedTrackedKeywords,
    searchedKeywords,
    searching,
    fullMaxKeywords,
    keywordsCta,
    busy
  };
};

const mapDispatch = (dispatch) => ({
  hideModal: () => dispatch(modalHide()),
  searchAction: (value) => dispatch(searchKeywordsRequest(value)),
  assingKeyword: (
    keywordSelected,
    page,
    addToTrackedList,
    keywordAlreadyTracked
  ) => {
    const addToTrackedKeywordsList =
      keywordSelected?.is_tracked || addToTrackedList || keywordAlreadyTracked;
    const taskId = page?.task_id;
    dispatch(
      startKeywordAssign(taskId, keywordSelected, addToTrackedKeywordsList)
    );
  }
});

export default connect(mapState, mapDispatch)(OfsSelectKeyword);
