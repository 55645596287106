import cx from 'classnames';
import {useTranslation, Trans} from 'react-i18next';
import {Tag} from '@marketgoo/components';
// XXX: Duplicated file screens/AddSite/helpers.js

/**
 * Converts interval to human-readable format.
 * @param {number} interval - Interval of scans.
 * @param {string} product - Type of scan.
 * @returns {string} - Human-readable string.
 */

const humanizeInterval = (interval, product) => {
  const phrases = {
    daily: {
      from: 'modal.upgrade.scan.daily.from',
      to: 'modal.upgrade.scan.daily.to'
    },
    weekly: {
      from: 'modal.upgrade.scan.weekly.from',
      to: 'modal.upgrade.scan.weekly.to'
    },
    monthly: {
      from: 'modal.upgrade.scan.monthly.from',
      to: 'modal.upgrade.scan.monthly.to'
    },
    yearly: {
      from: 'modal.upgrade.scan.yearly.from',
      to: 'modal.upgrade.scan.yearly.to'
    },
    custom: {
      from: 'modal.upgrade.scan.custom.from',
      to: 'modal.upgrade.scan.custom.to'
    }
  };

  const types = {
    1: 'daily',
    7: 'weekly',
    30: 'monthly',
    365: 'yearly'
  };
  const type = types[interval] ?? 'custom';
  return phrases[type][product];
};

/* Devuelve la frase adecuada para el intervalo de scaneo */
const ScanInterval = ({interval, ctaKey, product}) => {
  const klass = cx({
    'cta-related': ctaKey == 'check-again' || ctaKey == 'ribbon'
  });
  return (
    <li className={klass}>
      <Trans
        i18nKey={humanizeInterval(interval, product)}
        components={[<strong />]}
        values={{interval: interval}}
      />
    </li>
  );
};

/* Devuelve la frase adecuada para el máximo de páginas del scan según si es el producto de origen o el de destino */
const MaxPages = ({maxPages, ctaKey, product}) => {
  const klass = cx({'cta-related': ctaKey == 'ribbon'});
  const phrases = {
    from: (
      <li>
        <Trans
          i18nKey={'modal.upgrade.basic.scan'}
          components={[<strong />]}
          values={{
            max_pages: maxPages
          }}
        />
      </li>
    ),
    to: (
      <li className={klass}>
        <Trans i18nKey={'modal.upgrade.pro.scan'} components={[<strong />]} />
      </li>
    )
  };
  return phrases[product];
};

/* Devuelve la frase para el número de competidores */
const MaxCompetitors = ({maxCompetitors, ctaKey}) => {
  const klass = cx({
    'cta-related': ctaKey == 'rankings-competitors' || ctaKey == 'competitors'
  });
  return (
    maxCompetitors > 0 && (
      <li className={klass}>
        <Trans
          i18nKey={'modal.upgrade.competitors'}
          components={[<strong />]}
          values={{
            max_competitors: maxCompetitors
          }}
        />
      </li>
    )
  );
};

/* Devuelve la frase para el número de keywords */
const MaxKeywords = ({maxKeywords, ctaKey}) => {
  const klass = cx({
    'cta-related': ctaKey == 'rankings-keywords' || ctaKey == 'keywords'
  });
  return (
    maxKeywords > 0 && (
      <li className={klass}>
        <Trans
          i18nKey={'modal.upgrade.keywords'}
          values={{max_keywords: maxKeywords}}
          components={[<strong />]}
        />
      </li>
    )
  );
};
/* Devuelve la frase para el número máximo de tareas del producto */
const MaxTasks = ({maxTasks, ctaKey}) => {
  const klass = cx({'cta-related': ctaKey == 'more-tasks'});
  return !maxTasks ? ( // si no hay max tasks es que no hay límite y puede tener las que sean.
    <li className={klass}>
      <Trans i18nKey={'modal.upgrade.pro.tasks'} components={[<strong />]} />
    </li>
  ) : (
    <li>
      <Trans i18nKey={'modal.upgrade.basic.tasks'} components={[<strong />]} />
    </li>
  );
};

/* Devuelve la frase adecuada si el producto permite usar el OFS o no */
const OfsToolMessage = ({show, ctaKey}) => {
  const klass = cx({'cta-related': ctaKey == 'unlock-ofs'});
  return !show ? null : (
    <li className={klass}>
      <Trans i18nKey={'modal.upgrade.ofs'} components={[<strong />]} />
    </li>
  );
};

/* Devuelve la frase adecuada si el producto permite hacer scan bajo demanda o no */
const OnDemandScanMessage = ({show, ctaKey}) => {
  const klass = cx({'cta-related': ctaKey == 'check-again'});
  return !show ? (
    <li className={klass}>
      <Trans
        i18nKey={'modal.upgrade.basic.ondemand.scan'}
        components={[<strong />]}
      />
    </li>
  ) : (
    <li className={klass}>
      <Trans
        i18nKey={'modal.upgrade.pro.ondemand.scan'}
        components={[<strong />]}
      />
    </li>
  );
};

const AiFeatureMessage = ({ai_suggestions}) => {
  const {t} = useTranslation();
  if (ai_suggestions === 0) return null;
  return (
    <li>
      {t('modal.upgrade.pro.feature.ai')}
      <Tag variant="pro" className="relevant-feature">
        {t('modal.upgrade.pro.tag')}
      </Tag>
    </li>
  );
};

const AdvancedToolsMessage = ({advanced_tools}) => {
  const {t} = useTranslation();
  if (!advanced_tools) return null;
  return (
    <li>
      {t('modal.upgrade.pro.feature.tools')}
      <Tag variant="pro" className="relevant-feature">
        {t('modal.upgrade.pro.tag')}
      </Tag>
    </li>
  );
};

/* Devuelve la frase descripción adecuada de un producto según sea el producto de origen o el de destino o el de origen*/
export const ProductDescription = ({features, product, cta_key}) => {
  return (
    <ul>
      <ScanInterval
        interval={features?.scan_interval}
        ctaKey={cta_key}
        product={product}
      />
      <MaxPages
        maxPages={features?.max_pages}
        ctaKey={cta_key}
        product={product}
      />
      <MaxTasks
        maxTasks={features?.max_tasks}
        ctaKey={cta_key}
        product={product}
      />
      <MaxKeywords maxKeywords={features?.max_keywords} ctaKey={cta_key} />
      <MaxCompetitors
        maxCompetitors={features?.max_competitors}
        ctaKey={cta_key}
      />
      <OfsToolMessage show={features?.ofs_tool} ctaKey={cta_key} />
      <OnDemandScanMessage show={features?.scan_on_demand} ctaKey={cta_key} />
      <AiFeatureMessage ai_suggestions={features?.ai_suggestions} />
      <AdvancedToolsMessage advanced_tools={features?.advanced_tools} />
    </ul>
  );
};
