const DOMAIN = 'signup';

export const signupProccessing = (value = false) => ({
  type: `${DOMAIN}/processing`,
  payload: value
});

export const sendData = (values) => ({
  type: `${DOMAIN}/sendData`,
  payload: values
});

export const sendSignupSuccess = (userUlid) => ({
  type: `${DOMAIN}/success`,
  payload: {
    user: {
      ulid: userUlid
    }
  }
});
