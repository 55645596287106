import Dialog from 'components/UI/Modal/Dialog';

const DialogModal = ({title, description, confirmButtonText, onConfirm}) => (
  <Dialog
    title={title}
    description={description}
    onConfirm={onConfirm}
    onCancel={() => false}
    confirmButtonText={confirmButtonText}
  />
);

export default DialogModal;
