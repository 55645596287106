import lang from 'lib/browser-lang';
import hydrate from 'lib/hydrate';

const createConfig = () => {
  if (process.env.NODE_ENV === 'test')
    return {
      app: {
        mode: 'development',
        version: '',
        route_prefix: ''
      },
      dealer: {
        tag: 'MarketGoo',
        name: 'MarketGoo'
      },
      current_plan: '44fc85df42cb46c5d180ac24c2e20e569111365e',
      locale: 'en_US',
      user: {
        email: 'test@test.com',
        ulid: 'b8fb90c5a47161817ea8642f27d767b0511f54ea'
      }
    };

  return {
    ...hydrate('config'),
    locale: lang
  };
};

const applicationConfig = createConfig();

export default applicationConfig;
