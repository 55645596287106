import React from 'react';
import {connect} from 'react-redux';
import {createPortal} from 'react-dom';

// Modals
import AddCompetitor from 'components/Modals/AddCompetitor';
import RemoveCompetitor from 'components/Modals/RemoveCompetitor';
import SearchKeywords from 'components/Modals/SearchKeywords';
import CancelSurvey from 'components/Modals/CancelSurvey';
import Upgrade from 'components/Modals/Upgrade';
import FeatureUpgradeRequired from 'components/Modals/Upgrade/FeatureRequired';
import ProductComparison from 'components/Modals/ProductComparison';
import OfsSelectKeyword from 'components/Modals/OfsSelectKeyword';
import OfsSuccessScore from 'components/Modals/OfsSuccessScore';
import OfsWrongScore from 'components/Modals/OfsWrongScore';
import OfsLimitPages from 'components/Modals/OfsLimitPages';

import AnalyticsProfiles from 'components/Modals/GoogleAnalyticsProfiles';
import AnalyticsIssue from 'components/Modals/GoogleAnalyticsIssue';

import DeleteTrackedKeyword from 'components/Modals/DeleteTrackedKeyword';
import UnassignKeyword from 'components/Modals/UnassignKeyword';
import ScanBlockedMessage from 'components/Modals/ScanBlockedMessage';

// Próximos modales a ser deprecados
import ConfirmDialog from 'components/UI/Modal/Dialog/confirm';
import InfoDialog from 'components/UI/Modal/Dialog/info';
import NewVersionNotice from 'components/UI/Modal/Notice/NewVersion';

const MODAL_COMPONENTS = {
  COMPETITORS_ADD: AddCompetitor,
  COMPETITORS_REMOVE: RemoveCompetitor,
  KEYWORDS_SEARCH: SearchKeywords,
  CANCEL_SURVEY: CancelSurvey,
  UPGRADE: Upgrade,
  PRODUCT_COMPARISON: ProductComparison,

  DELETE_TRACKED_KEYWORD: DeleteTrackedKeyword,
  UNASSIGNED_KEYWORD: UnassignKeyword,

  ANALYTICS_PROFILES: AnalyticsProfiles,
  ANALYTICS_ISSUE: AnalyticsIssue,
  OFS_SELECT_KEYWORD: OfsSelectKeyword,
  OFS_SUCCESS_SCORE: OfsSuccessScore,
  OFS_WRONG_SCORE: OfsWrongScore,
  OFS_LIMIT_PAGES: OfsLimitPages,
  NEW_VERSION_NOTICE: NewVersionNotice,
  MODAL_CONFIRM: ConfirmDialog,
  MODAL_INFO: InfoDialog,
  SCAN_BLOCKED: ScanBlockedMessage,
  FEATURE_UPGRADE_REQUIRED: FeatureUpgradeRequired
};

const ModalRoot = ({modalType, modalProps}) => {
  if (!modalType) return null;
  const SpecificModal = MODAL_COMPONENTS[modalType];
  return createPortal(<SpecificModal {...modalProps} />, document.body);
};

const mapStateToProps = ({modal: {modalType, modalProps}}) => ({
  modalType,
  modalProps
});

export default connect(mapStateToProps)(ModalRoot);
