import React from 'react';
import {useIsWordPressPlugin} from 'hooks';
import {useTranslation} from 'react-i18next';
import {Button} from '@marketgoo/components';

const RememberMessage = ({error, login_url, remember_url}) => {
  const isWpPlugin = useIsWordPressPlugin();
  const {t} = useTranslation();
  const hasError = error && error.length > 0;
  const title = hasError
    ? t('remember.message.title.error')
    : t('remember.message.title.success');
  const intro = hasError
    ? t('remember.message.intro.error')
    : t('remember.message.intro.success');
  const buttonLabel = hasError
    ? t('remember.message.error.button')
    : t('remember.message.success.button');
  const __goToDestination = () =>
    (window.location = hasError
      ? remember_url
      : `${login_url}${isWpPlugin ? '/wordpress' : ''}`);
  return (
    <section id="remember-container">
      <section className="ola_ly-pageCenter">
        <header>
          <h2 className="ola-font-5-medium ola-nomargin ola-center">{title}</h2>
          <p className="ola-font-2-regular ola-center">{intro}</p>
        </header>
        <div className="ola-center">
          <Button variant="primary" onClick={__goToDestination}>
            {buttonLabel}
          </Button>
        </div>
      </section>
    </section>
  );
};

export default RememberMessage;
