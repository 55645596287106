import {
  updateEngineSelected,
  updateKeywordSelected,
  updateSerieSelected
} from 'app/SiteResult/actions';
import produce from 'immer';

export const initialState = {
  keyword_selected: null,
  engine_selected: 'google',
  serie_selected: 'last15'
};

export default (state = initialState, {type, keyword_id, engine, serie}) =>
  produce(state, (draft) => {
    switch (type) {
      case updateKeywordSelected().type:
        draft.keyword_selected = keyword_id;
        break;

      case updateEngineSelected().type:
        draft.engine_selected = engine;
        break;

      case updateSerieSelected().type:
        draft.serie_selected = serie;
        break;

      default:
        draft;
        break;
    }
  });
