import {showNotification} from 'components/UI/Notifications/actions';
import {endpoints} from 'lib/redux/sagas/api';
import {request} from 'lib/redux/sagas/request';
import {takeEvery} from 'redux-saga';
import {call, put, select} from 'redux-saga/effects';
import {
  SAVE_LANGUAGE_REQUEST,
  SAVE_LANGUAGE_RESPONSE,
  SAVE_THEME_REQUEST,
  SAVE_THEME_RESPONSE
} from './actions';

export function* getLanguage(action) {
  const oldLang = yield select((state) => state.data.config?.user?.lang);
  const lang = action.language;

  try {
    yield call(request, endpoints.user.update(), {lang});
    yield put({
      type: SAVE_LANGUAGE_RESPONSE,
      language: lang,
      error: false
    });
    yield put(
      showNotification({
        name: 'success',
        title: 'Your language was saved'
      })
    );
    if (oldLang !== lang) {
      yield call(() => location.reload());
    }
  } catch (error) {
    yield put({
      type: SAVE_LANGUAGE_RESPONSE,
      language: lang,
      error: true
    });
    yield put(
      showNotification({
        name: 'error',
        title: 'Your language could not be saved'
      })
    );
  }
}

export function* getTheme(action) {
  const oldBranding = yield select(
    (state) => state.data.config?.dealer?.branding
  );
  const branding = action.theme;

  try {
    yield call(request, endpoints.dealer.update(), {branding});
    yield put({type: SAVE_THEME_RESPONSE, branding, error: false});
    yield put(
      showNotification({
        name: 'success',
        title: 'Your theme was saved'
      })
    );
    if (oldBranding !== branding) {
      yield call(() => location.reload());
    }
  } catch (error) {
    yield put({type: SAVE_THEME_RESPONSE, branding, error: true});
    yield put(
      showNotification({
        name: 'error',
        title: 'Your theme could not be saved'
      })
    );
  }
}

export function* widgetSaga() {
  yield [
    takeEvery(SAVE_LANGUAGE_REQUEST, getLanguage),
    takeEvery(SAVE_THEME_REQUEST, getTheme)
  ];
}
export default widgetSaga;
