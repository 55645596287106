const DOMAIN = 'plans';

export const disableAnalytics = () => ({
  type: `${DOMAIN}/disableAnalytics`
});

export const updateSelectProfile = () => ({
  type: `${DOMAIN}/updateSelectProfile`
});

export const resetSelectProfile = (uuid) => ({
  payload: {uuid},
  type: `${DOMAIN}/resetSelectProfile`
});

export const updatePlan = (plan) => ({
  type: `${DOMAIN}/update`,
  payload: plan
});

export const setProductDetails = (plan, status) => ({
  type: `${DOMAIN}/setProductDetails`,
  payload: plan,
  status
});
