//
// Algunas utilidades que no podemos encontrar en otro sitio, o que nos
// obligaría a utilizar una librería entera como jQuery para pequeños
// snippets de código que podemos implementar aquí.
//
// Basado heavily en lo-dash (Underscore): https://lodash.com
//

// A continuación, corta-pegamos (casi) código directamente de lo-dash
// https://raw.githubusercontent.com/lodash/lodash/master/lodash.src.js

/**
 * funcion findIndex copiada de lo-dash
 */
function findIndex(array, predicate) {
  var index = -1,
    length = array ? array.length : 0;

  // Sólo lo usamos con funciones predicado, para ahorrar código
  // predicate = getCallback(predicate, thisArg, 3);
  while (++index < length) {
    if (predicate(array[index], index, array)) {
      return index;
    }
  }
  return -1;
}

/**
 * funcion findLastIndex copiada de lo-dash
 */
function findLastIndex(array, predicate) {
  var length = array ? array.length : 0;

  // Sólo lo usamos con funciones predicado, para ahorrar código
  // predicate = getCallback(predicate, thisArg, 3);
  while (length--) {
    if (predicate(array[length], length, array)) {
      return length;
    }
  }
  return -1;
}

/**
 * funcion find copiada de lo-dash
 */
function find(collection, predicate, thisArg) {
  // Lo limitamos a trabajar con Arrays
  var index = findIndex(collection, predicate, thisArg);
  return index > -1 ? collection[index] : undefined;
}

/**
 * funcion first copiada de lo-dash
 */
function first(array) {
  return array ? array[0] : undefined;
}

/**
 * funcion startsWith copiada de underscore.string
 */
function startsWith(str, starts) {
  if (starts === '') return true;
  if (str == null || starts == null) return false;
  str = String(str);
  starts = String(starts);
  return str.length >= starts.length && str.slice(0, starts.length) === starts;
}

/**
 * funcion copiada de Jquery
 * https://github.com/jquery/jquery/blob/7103d8ef47e04a4cf373abee0e8bfa9062fd616f/src/core.js
 */
function trim(text) {
  var rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
  return text == null ? '' : (text + '').replace(rtrim, '');
}

function cleanUrl(url) {
  if (/(http(s?)):\/\//i.test(url)) return new URL(url).hostname;
  return url;
}

/**
 * Devolvemos el objecto que expone las funciones públicas
 */
export default {
  find: find,
  findIndex: findIndex,
  findLastIndex: findLastIndex,
  first: first,
  startsWith: startsWith,
  trim: trim,
  cleanUrl: cleanUrl
};
