export function findKeyword(keyword_list, keyword_id) {
  return keyword_list.find((kwd) => kwd?.id == keyword_id);
}

export function filterTrackedKeywords(keyword_list) {
  return keyword_list.filter((kwd) => kwd?.is_tracked === 1);
}

export function filterAssignedKeywords(keyword_list) {
  return keyword_list.filter((kwd) => kwd?.page !== null);
}

export function filterUnassignedKeywords(keyword_list) {
  return keyword_list.filter((kwd) => (kwd?.page || null) === null);
}

export function trackedUnasignedKeywords(keyword_list) {
  return filterUnassignedKeywords(filterTrackedKeywords(keyword_list));
}

export function filterUniqueKeywords(keywords_a, keywords_b) {
  return keywords_a.filter(
    (keyword) => findKeyword(keywords_b, keyword?.id) == undefined
  );
}

export function removeKeyword(keywords, keyword_id) {
  return keywords.filter((kwd) => kwd?.id != keyword_id);
}
