import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Header from 'components/Header';
import {Button, ButtonGroup, Input, Field} from '@marketgoo/components';
import {Separator} from 'components/Separator';
import {sendData} from 'screens/Signup/actions';
import {useTranslation, Trans} from 'react-i18next';
import useAmplitude from 'lib/amplitude';
import queryString from 'query-string';
import {useForm} from 'react-hook-form';
import {t} from 'i18next';
import {getThemesPath} from 'selectors';
import {
  required,
  getMinLengthRule,
  getMaxLengthRule,
  isValidEmail
} from 'components/UI/Form/validators';

const SignUpWpForm = ({promo, product, domain}) => {
  const isProcesing = useSelector((state) =>
    state.signup ? state.signup?.processing : false
  );

  const dispatch = useDispatch();

  const defaultValues = {
    name: '',
    email: '',
    password: '',
    promo,
    product,
    domain
  };

  const {
    register,
    handleSubmit,
    formState: {errors},
    reset
  } = useForm({defaultValues});

  useEffect(() => {
    reset(defaultValues);
  }, [reset]);

  const onSubmitForm = (data) => {
    dispatch(sendData(data));
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div className="inputs-block">
        <div>
          <Field
            id="name"
            label={t('g.name')}
            disabled={isProcesing}
            description={errors.name && errors.name.message}
            error={!!errors.name}
          >
            <Input
              {...register('name', {
                required,
                maxLength: getMaxLengthRule(25),
                minLength: getMinLengthRule(2)
              })}
              type="text"
              placeholder={t('payflow.signup.wp.form.name')}
            />
          </Field>
          <Field
            id="email"
            label={t('g.email')}
            disabled={isProcesing}
            description={errors.email && errors.email.message}
            error={!!errors.email}
          >
            <Input
              {...register('email', {
                required,
                validate: {isValidEmail}
              })}
              type="email"
              placeholder={t('payflow.signup.wp.form.email')}
            />
          </Field>
          <Field
            id="password"
            label={t('g.password')}
            disabled={isProcesing}
            description={errors.password && errors.password.message}
            error={!!errors.password}
          >
            <Input
              {...register('password', {
                required,
                minLength: getMinLengthRule(6)
              })}
              type="password"
              placeholder={t('payflow.signup.wp.form.password')}
            />
          </Field>
        </div>
      </div>
      <div className="actions">
        <ButtonGroup className="ola-margin-top-2">
          <Button variant="primary" busy={isProcesing && t('g.please.wait')}>
            {t('payflow.signup.wp.button')}
          </Button>
          {window.opener && (
            <Button
              variant="secondary"
              type="button"
              onClick={() => window.close()}
            >
              {t('payflow.signup.cancel.button')}
            </Button>
          )}
        </ButtonGroup>
      </div>
    </form>
  );
};

// TODO: actualizar componente button para permitir tener
// imágenes fuera del span ola_button_text

const SignUpWpPlugin = (props) => {
  const themesPath = useSelector(getThemesPath);
  const {t} = useTranslation();
  const {logTypes, logEvent} = useAmplitude();
  const params = queryString.parse(location.search);
  const domain = params && params.domain;
  const loginUrl = '/login/wordpress' + (domain ? `?domain=${domain}` : '');
  const [showForm, setShowForm] = useState(false);
  const [showMore, setShowMore] = useState(true);
  const gdprRef = useRef(null);

  const authLink = domain
    ? `/gateway/auth0/auth?domain=${domain}`
    : '/gateway/auth0/auth';

  useEffect(() => {
    logEvent(logTypes.WP_PLUGIN_Purchase_Flow_PageView, {
      Step: 'Sign up'
    });
  }, []);

  const gdprCopy = (
    <Trans
      i18nKey="payflow.signup.wp.form.gdpr"
      components={[
        <a
          key={0}
          target="_blank"
          href="https://www.marketgoo.com/privacy-policy/"
          rel="noreferrer"
        />
      ]}
    />
  );

  return (
    <section id="onboarding-wp-signup">
      <div id="header">
        <Header isWPPlugin />
      </div>
      <section className="ola_ly-pageCenter">
        <header>
          <h2 className="ola-font-5-medium ola-nomargin">
            {t('payflow.signup.wp.title')}
          </h2>
          <p className="ola-font-2-regular ola-color-neutral-700">
            <Trans
              i18nKey="payflow.signup.wp.intro"
              components={[<strong key={0} />, <a href={loginUrl} key={1} />]}
            />
          </p>
        </header>

        <div>
          <ButtonGroup variant="narrow">
            <Button
              variant="secondary"
              href={authLink}
              icon={
                <img
                  style={{width: '100%'}}
                  src={`${themesPath}/img/icons/google.svg`}
                />
              }
            >
              {t('wp.signup.with.google')}
            </Button>
          </ButtonGroup>
          <Separator>
            <p className="ola-color-neutral-400 ola-font-1-regular ola-font-weight-bold ola-uppercase">
              {t('g.or')}
            </p>
          </Separator>
          {!showForm && (
            <ButtonGroup variant="narrow">
              <Button variant="primary" onClick={() => setShowForm(true)}>
                {t('wp.signup.with.email')}
              </Button>
            </ButtonGroup>
          )}
          {showForm && (
            <SignUpWpForm
              product={props.product}
              promo={props.promo}
              domain={domain}
            />
          )}
          <p className="ola-font-1-regular">
            <span style={{display: 'none'}} id="gdpr" ref={gdprRef}>
              {gdprCopy}
            </span>
            {!showMore && gdprCopy}
            {showMore &&
              gdprRef.current &&
              gdprRef.current.innerHTML &&
              `${gdprRef.current.innerHTML.substring(0, 87)}...`}
            {showForm && (
              <Button
                className="ola-margin-left-1"
                variant="link"
                onClick={() => setShowMore(!showMore)}
              >
                {t(
                  `payflow.signup.wp.form.gdpr.see.${
                    showMore ? 'more' : 'less'
                  }`
                )}
              </Button>
            )}
          </p>
        </div>
      </section>
    </section>
  );
};

export default SignUpWpPlugin;
