import {
  saveContactDataRequest,
  saveContactDataResponse
} from 'screens/Profile/Contact/actions';
import produce from 'immer';

const initialState = {
  processing: false
};

export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case saveContactDataRequest().type:
        draft.processing = true;
        break;
        break;

      case saveContactDataResponse().type:
        draft.processing = false;
        break;
      default:
        draft;
        break;
    }
  });
