import {takeLatest, delay} from 'redux-saga';
import {put, select, take, call} from 'redux-saga/effects';

import {requestScanSingle} from './actions';
import {pageDataByIdResponse} from '../OptimizeYourPages/actions';
import {OYP_SUCCESS_SCORE} from 'constants';
import {modalShow, modalHide} from 'components/UI/Modal/actions';

import {performTaskAction} from 'components/TasksPanel/actions';
import {requestKeywordSuggestion, responseKeywordSuggestion} from './actions';
import {endpoints} from 'lib/redux/sagas/api';
import {request} from 'lib/redux/sagas/request';
// TODO: Escuchar eventos de scan por pagina para tener un state de loading
// TODO: Cuando termine el scan tenemos que pedir

export function* requestScanSinglePage() {
  // Deberá lanzar el scan
  const currentUuid = yield select((store) => store.data.config.current_plan);
  const isScanning = yield select(
    (store) => store.scanning && store.scanning[currentUuid]
  );
  const optimizeYourPages = yield select((store) => store.optimizeYourPages);
  const task = optimizeYourPages?.page_data?.subtasks[0];

  const lanchScan = !isScanning && task;
  if (lanchScan) {
    yield put(performTaskAction('check', task));
    yield take(pageDataByIdResponse().type);
    const pageScore = yield select(
      (store) => store.optimizeYourPages?.page_data?.page_score
    );
    const pageScoreRounded = Math.floor(pageScore);
    if (pageScoreRounded >= OYP_SUCCESS_SCORE) {
      yield put(modalShow('OFS_SUCCESS_SCORE'));
      yield delay(4000);
      yield put(modalHide('OFS_SUCCESS_SCORE'));
      yield call(
        {context: window.location, fn: window.location.replace},
        '/ofs'
      );
    } else {
      yield put(modalShow('OFS_WRONG_SCORE'));
      yield delay(4000);
      yield put(modalHide('OFS_WRONG_SCORE'));
    }
  }
}

export function* requestOptimizeKeywordSuggestion({pageId}) {
  try {
    const res = yield call(request, endpoints.keywordSuggestion.read(pageId));
    const resStatus = res.body && res.statusCode === 200;
    const statusOk = resStatus && res.ok; // OJO! Este ok es diferente al del body
    const resWithErrors = statusOk && res.body && res.body.status === 'ko';
    const [keywordSuggested] = res.body;

    if (resStatus) {
      yield put(responseKeywordSuggestion(keywordSuggested));
    }

    if (resWithErrors) yield put(responseKeywordSuggestion(null));
  } catch (e) {
    console.log(e);
  }
}

export function* OptimizeYourPagesSuggestions() {
  yield [takeLatest(requestScanSingle().type, requestScanSinglePage)];
  yield [
    takeLatest(
      requestKeywordSuggestion().type,
      requestOptimizeKeywordSuggestion
    )
  ];
}

export default OptimizeYourPagesSuggestions;
