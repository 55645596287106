import {
  ANALYZING_WIZARD_PRESENT,
  SITE_UNREACHABLE_RETRY,
  START_SCANNING,
  websocketActions
} from 'app/Scanning/actions';
import {modalShow} from 'components/UI/Modal/actions';
import i18next from 'i18next';
import config from 'lib/application-config';
import * as globals from 'lib/redux/actions/global';
import {endpoints} from 'lib/redux/sagas/api';
import {request} from 'lib/redux/sagas/request';
import {delay, takeEvery, takeLatest} from 'redux-saga';
import {call, put, race, select, spawn, take} from 'redux-saga/effects';

export const modalError = modalShow('MODAL_INFO', {
  title: i18next.t('notification.global.error.tryagain.title'),
  description: i18next.t('notification.global.error.tryagain.description'),
  error: true
});

export function* performScan(action) {
  try {
    const {
      body: {type, payload}
    } = yield call(request, endpoints.sitemap.read(), {
      uuid: action.payload.uuid
    });
    yield put({type, payload});
  } catch (e) {
    yield put({type: globals.NETWORK_ERROR});
    yield put(modalError);
  }
}

export function* scanCompleted(action) {
  try {
    const {
      body: {type, payload}
    } = yield call(request, endpoints.plan.update(action.payload.uuid, true));
    yield put({type, payload});
  } catch (e) {
    yield put(modalError);
  }
}

export function* updateSingleTask(action) {
  try {
    let {
      body: {type, task, uuid}
    } = yield call(request, endpoints.task.update(action.payload.task_id));
    yield put({type, task, uuid});
  } catch (e) {
    yield put(modalError);
  }
}

/**
 * wizardWatchDog realiza un "race" condition entre un timeout (delay) y la
 * recepción de alguno de los mensajes que se envían durante un scan. La
 * intención es crear un "watch dog" o alarma que detecte que no se ha recibido
 * ningún mensaje de scanning y por tanto debemos enviar al usuario a la página
 * del dashboard (config.app.route_map.index).
 */
export function* wizardWatchDog() {
  const scanMessages = [
    websocketActions.SCANNING_STARTED,
    websocketActions.SCANNING_PROGRESS,
    websocketActions.SCANNING_COMPLETED,
    websocketActions.SCANNING_TASKS_COMPLETED
  ];

  const isScanMessageForMe = (action) =>
    scanMessages.includes(action.type) &&
    action.payload.uuid == config.current_plan;

  // La cosa es como sigue... hacemos un "race" (carrera) entre un take y
  // un "delay". El que primero "llegue", ganará la carrera. Si es el timeout
  // quiere decir que en 20 segundos no se ha recibido ningún mensaje de
  // scanning del servidor, por tanto vamos a refrescar la página de wizard.
  const result = yield race({
    timeout: call(delay, 20 * 1000), // O un timeout de 20 segundos
    scanning: take(isScanMessageForMe) // O recibimos un mensaje de scan para mi UUID
  });

  // OK, ahora "result" puede contener el resultado del "timeout" o el del
  // "scanning", la que haya podido terminar primero. Si es el timeout, mala
  // suerte para el scan, tenemos que desviar la atención... :-)
  if (result.timeout) {
    const wizard = yield select((store) => store.wizard);
    const config = yield select((store) => store.data.config);
    const history = wizard && wizard.history;
    history && history.push(config.app?.route_map?.overview || '/overview');
  }
}

export function* retrySiteUnreachable(action) {
  const data = yield select((state) => state.data);
  const analyzingPath = data.config?.app?.route_map?.analyzing;
  if (window && window.location && window.location.pathname === analyzingPath) {
    yield call(request, endpoints.sitemap.read(), {
      uuid: action.payload.uuid
    });
  }
  location.reload();
}

function* scanningProxy(action) {
  switch (action.type) {
    case ANALYZING_WIZARD_PRESENT:
      // Hacemos "spawn" para que no se quede como "hijo" del hilo de
      // ejecución actual del Saga y se haga un "detach" para que no
      // perjudique la ejecución normal. Ver:
      // https://redux-saga.js.org/docs/advanced/ForkModel.html
      yield spawn(wizardWatchDog);
      break;

    default:
      return;
  }
}

export function* ScanningBlocked() {
  const currentUuid = yield select((store) => store.data.config.current_plan);
  const scanError = yield select(
    (store) => store.data.plans[currentUuid].site.error
  );
  const pageStatus = yield select(
    (store) => store.data.plans[currentUuid].site.scan.homepage_status
  );
  if (scanError || pageStatus !== 200) {
    yield put(
      modalShow('SCAN_BLOCKED', {
        error: scanError || null,
        buttonKeyText: 'g.know_more',
        to: null
      })
    );
  }
}

export function* scanningSaga() {
  yield takeEvery(START_SCANNING, performScan);
  // yield takeEvery(websocketActions.SCANNING_TASKS_COMPLETED, scanCompleted);
  yield takeEvery(
    websocketActions.SCANNING_SINGLE_TASK_COMPLETED,
    updateSingleTask
  );
  yield takeEvery(SITE_UNREACHABLE_RETRY, retrySiteUnreachable);
  yield takeEvery(ANALYZING_WIZARD_PRESENT, scanningProxy);
  yield takeLatest(websocketActions.REFRESH_PLAN_DATA, ScanningBlocked);
}

export default scanningSaga;
