import {modalHide} from 'components/UI/Modal/actions';
import Freezer from 'misc/freezer';
import {takeEvery} from 'redux-saga';

function* hideModal() {
  yield Freezer.off();
}

export function* modalSaga() {
  yield [takeEvery(modalHide().type, hideModal)];
}

export default modalSaga;
