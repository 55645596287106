import {removeNotification} from 'components/UI/Notifications/actions';
import {connect, useSelector} from 'react-redux';
import {Button, Toast} from '@marketgoo/components';
import {MegaphoneSimple} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {getSiteTasks, getRouteMap} from 'selectors';

const NewToolAvailable = ({notification, handleClose}) => {
  const {t} = useTranslation();
  const {id} = notification;
  return (
    <Toast
      top="80px"
      title={t('tools.notification.textbox.title')}
      icon={<MegaphoneSimple size={24} weight="fill" />}
      onClose={() => handleClose(id)}
    >
      <p className="ola-font-1-regular notification-tools-description">
        {t('tools.notification.textbox.content')}
      </p>
    </Toast>
  );
};
const ErrorNotifications = ({handleClose, notification}) => {
  const {title, id, message} = notification;
  return (
    <Toast
      key={id}
      top="80px"
      title={title}
      variant="negative"
      onClose={() => handleClose(id)}
    >
      <p>{message}</p>
    </Toast>
  );
};

const SuccessNotifications = ({notification, handleClose}) => {
  const {title, id, message} = notification;
  return (
    <Toast
      top="80px"
      title={title}
      variant="positive"
      onClose={() => handleClose(id)}
    >
      <p>{message}</p>
    </Toast>
  );
};

const NewSiteTasks = ({notification, handleClose}) => {
  const {t} = useTranslation();
  const {id} = notification;
  const siteTasks = useSelector(getSiteTasks);
  const routes = useSelector(getRouteMap);
  const taksNoPass = siteTasks?.find((task) => !task?.pass);

  return (
    <Toast
      top="80px"
      title={t('recocard.site.notification.title')}
      icon={<MegaphoneSimple size={24} weight="fill" />}
      onClose={() => handleClose(id)}
    >
      <p className="ola-font-1-regular notification-tools-description">
        {t('recocard.site.notification.content')}
      </p>
      {taksNoPass && (
        <Link
          to={routes.site_task.replace(':taskID', taksNoPass.id)}
          onClick={() => handleClose(id)}
        >
          <Button variant="link" className="notification-tools-action">
            {t(`recocard.site.${taksNoPass.task_id}.header.title`)}
          </Button>
        </Link>
      )}
    </Toast>
  );
};

const NOTIFICATIONS = {
  tools: NewToolAvailable,
  error: ErrorNotifications,
  success: SuccessNotifications,
  'site-tasks': NewSiteTasks
};

const Notifications = ({notifications, handleClose}) => {
  return (
    <div className="notifications-wrapper">
      {notifications
        .filter((notification) => notification.type !== 'badge')
        .map((notification) => {
          if (!NOTIFICATIONS[notification.name]) return null;
          const SpecificNotification = NOTIFICATIONS[notification.name];
          return (
            <SpecificNotification
              key={notification.name}
              notification={notification}
              handleClose={handleClose}
            />
          );
        })}
    </div>
  );
};

const mapStateToProps = ({notifications}) => ({
  notifications
});

const mapDispatchToProps = (distpatch) => ({
  handleClose: (id) => distpatch(removeNotification(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
