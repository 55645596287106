import {ProgressBar} from '@marketgoo/components';
import {connect} from 'react-redux';

const KeywordProgress = ({currentKeywords, maxKeywords}) => {
  return (
    <ProgressBar value={currentKeywords} max={maxKeywords}>
      <span
        dangerouslySetInnerHTML={{
          __html: __('<strong>{total} out of {max}</strong> keywords tracked', {
            total: currentKeywords,
            max: maxKeywords
          })
        }}
      />
    </ProgressBar>
  );
};

const mapStateToProps = ({data: {plans, config}}, {descriptionPosition}) => {
  const plan = plans[config?.current_plan];
  const site = plan?.site;
  const product = plan?.product;
  const currentKeywords = site?.keywords.filter(
    (keyword) => keyword?.is_tracked
  ).length;
  const maxKeywords =
    config?.dealer?.products?.[product]?.features?.max_keywords;

  return {
    maxKeywords,
    currentKeywords,
    descriptionPosition
  };
};
export default connect(mapStateToProps)(KeywordProgress);
