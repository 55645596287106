import * as actions from 'components/TasksPanel/actions';
import {performTaskAction} from 'components/TasksPanel/actions';
// import {startScanning} from "app/Scanning/actions";
import {modalShow} from 'components/UI/Modal/actions';
import {showNotification} from 'components/UI/Notifications/actions';
import i18next from 'i18next';
import {endpoints} from 'lib/redux/sagas/api';
import {request} from 'lib/redux/sagas/request';
import {call, put, select, spawn, take} from 'redux-saga/effects';

const modalError = modalShow('MODAL_INFO', {
  title: i18next.t('notification.global.error.tryagain.title'),
  description: i18next.t('notification.global.error.tryagain.description'),
  error: true
});

// Petición al servidor para realizar una acción. En el body del PUT deberá
// estar la acción a realizar, y no nos preocupamos del resultado porque vamos
// a hacerlo de forma optimista, es decir, duplicando nosotros en el cliente la
// acción que realizará el servidor sin preocuparnos de si ha funcionado o no.
function* performAction(action, taskId) {
  try {
    // No nos interesa el resultado de la llamada. Vamos a refrescar la
    // página independientemente de lo que nos devuelva...
    yield call(request, endpoints.task.create(taskId), {action});

    // Y nos nos preocupamos de lo que nos devuelva... (Actualización optimista)
  } catch (e) {
    // OK! Para probar, de momento hacemos aquí la gestión de errores
    // mostrando un diálogo "Dialog.error" para que refresque la página.
    // Normalmente sólo emitiríamos el "NETWORK_ERROR" y será otro Saga
    // el que se haga cargo de la situación.
    yield put(modalError);
  }
}

// La "saga" taskActionSaga se encarga de enviar la petición al servidor para
// las acciones de tarea como "wmsend" (enviar a webmaster), etc.
export function* taskActionSaga() {
  const data = yield select((state) => state.data);
  const uuid = data.config?.current_plan;

  while (true) {
    let {
      payload: {task, task_action}
    } = yield take(performTaskAction().type);

    // A partir de aquí es cuando la cosa se pone interesante. Vamos a
    // enviar la petición al servidor de forma OPTIMISTA. Es decir, que no
    // esperamos si ha ido bien o a ido mal porque el 99.999% de las veces
    // irá bien. En caso de errores al refrescar la página el usuario
    // volverá a ver los datos como estaban antes de iniciar la petición.
    // El caso que debemos diversificar y a la vez que enviamos la petición
    // al servidor, también debemos emitir los eventos para modificar el
    // store acorde a la acción que debemos hacer (sleep, wmsend, etc).

    // A correr! Enviamos la petición mediante un "spawn" que correrá
    // paralelo a las sagas que hagamos a partir de ahora. Al contrario de
    // "fork", "spawn" corre de forma independiente sin que los errores
    // afecten a la saga "padre".
    yield spawn(performAction, task_action, task?.id);

    // Elegir la acción a enviar dependiendo del "task_action"
    switch (task_action) {
      // "sleep" y "discard" tienen el efecto inmediato en el cliente de
      // hacer desaparecer la tarea. Por tanto, la acción es "DISCARD_TASK"
      // case "sleep":
      // case "discard":
      //     yield put(actions.discardTask(task, uuid));
      //     break;

      // Para checkear una tarea, tenemos que mantenerla abierta e
      // iniciar un scan del sitio hasta implementar checkeos de
      // tareas individuales. Como la petición al servidor se está
      // haciendo en estos momentos y hasta que empecemos a recibir los
      // eventos de SCANNING_*, tenemos que mantener la RecoCard abierta
      // y simular nosotros el inicio del scanning para que el usuario
      // reciba un feedback inmediato.
      case 'check':
        // yield put(startScanning(uuid));
        // No pasamos la task como complete para que se quede abierta
        break;

      // Vamos a completar la tarea. La acción "complete" sólo se
      // recibirá para tareas que no son automáticas, así que el efecto
      // en el cliente es que la tarea pasa a la lista de "¿Qué has hecho
      // bien?"
      case 'complete':
        yield put(actions.completeTask(task, uuid));
        const title = task?.card?.title;
        yield put(
          showNotification({
            name: 'success',
            title: i18next.t('notification.task.complete.title'),
            message: i18next.t(
              'notification.task.complete.success.description',
              {title}
            )
          })
        );
        break;

      // case "close":
      //     yield put(actions.closeTask());
      //     break;
    }
  }
}

export default taskActionSaga;
