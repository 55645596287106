import React from 'react';
import {useDispatch} from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  ButtonGroup,
  Button
} from '@marketgoo/components';
import {modalHide} from 'components/UI/Modal/actions';
import {removeCompetitor} from 'components/Competitors/actions';

const RemoveCompetitor = ({id, uuid, domain}) => {
  const dispatch = useDispatch();
  return (
    <Modal variant="narrow" open onClose={() => dispatch(modalHide())}>
      <ModalHeader
        title={__('Are you sure you want to delete this competitor?')}
      />
      <ModalContent>
        <p className="ola-font-1-regular ola-color-neutral-700">
          {__(
            'We’ll delete the data collected for {domain} so far and you’ll be able to add another competitor. But you won’t be able to retrieve this data, or reverse this step.',
            {domain}
          )}
        </p>
      </ModalContent>
      <ModalFooter>
        <ButtonGroup variant="reversed">
          <Button
            onClick={() => dispatch(removeCompetitor(id, uuid))}
            variant="destructive-primary"
          >
            {__('Delete competitor')}
          </Button>
          <Button onClick={() => dispatch(modalHide())} variant="secondary">
            {__('Cancel')}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default RemoveCompetitor;
