import {isSubmitting, saveStructuredData} from 'screens/Tools/actions';
import produce from 'immer';

export const initialState = {
  structuredData: {
    isSubmitting: false,
    form: {}
  }
};

export default (state = initialState, {type, payload}) =>
  produce(state, (draft) => {
    switch (type) {
      case isSubmitting().type:
        draft.structuredData.isSubmitting = payload;
        break;
      case saveStructuredData().type:
        draft.structuredData.form = payload.payload;
        break;
      default:
        draft;
        break;
    }
  });
