/**
 * PushStream-Redux: Librería para conectar los eventos de PushStream al
 * store de Redux, emitiendolos mediante "dispatch".
 */

// "misc/pushstream" es la librería cliente original de nginx-pushstream
// https://github.com/wandenberg/nginx-push-stream-module
// No es modular, así que exporta el objeto "PushStream" en window.
import 'misc/pushstream';

export default function connectChannel(store, channel = null) {
  if (!channel) throw 'Invalid channel name';

  // Conectamos el canal de PushStream para redirigir los mensajes al store
  let _handle = new window.PushStream({
    host: window.location.hostname,
    port: window.location.port,
    modes: 'websocket|eventsource',
    useSSL: 'https:' == document.location.protocol
  });
  _handle.onmessage = (message) => {
    // Sólo enviar al store aquellos mensajes que contengan un "type"
    if (message.type) {
      store.dispatch(message);
    } else {
      if (process.env.NODE_ENV == 'development') {
        // eslint-disable-next-line no-console
        console.log('[UNKNOWN EVENT MESSAGE]', message);
      }
    }
  };

  try {
    _handle.addChannel(channel);
    _handle.connect();
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV == 'development') console.log(e);
  }
}
