import {modalShow} from 'components/UI/Modal/actions';
import i18next from 'i18next';
import {endpoints} from 'lib/redux/sagas/api';
import {request} from 'lib/redux/sagas/request';
import {getAffiliateTracking} from 'misc/helper';
import {takeEvery} from 'redux-saga';
import {call, put} from 'redux-saga/effects';
import {sendData, sendSignupSuccess, signupProccessing} from './actions';

export const modalError = modalShow('MODAL_INFO', {
  title: i18next.t('notification.global.error.tryagain.title'),
  description: i18next.t('notification.global.error.tryagain.description'),
  error: true
});

function* performSendData({payload}) {
  const name = payload.name;
  const email = payload.email;
  const password = payload.password;
  const promo = payload.promo;
  const product = payload.product;
  const partner_token = getAffiliateTracking();
  const domain = payload.domain;

  try {
    const data = {
      promo,
      product,
      name,
      partner_token,
      email,
      password,
      domain
    };

    yield put(signupProccessing(true));

    // No cogemos la ruta del config ya que esta no debe ser visible para dealers
    const res = yield call(request, endpoints.signup.create(), data);

    if (res.body && res.body.status === 'ok') {
      // Event
      if (res.body.ulid) yield put(sendSignupSuccess(res.body.ulid));
      // All ok, goo!!
      window.location.href = res.body.redirect;
    } else if (res.body && res.body.status == 'ko') {
      // Cogemos el primer error del array, si vienen errores
      const infoError =
        res.body.errors && res.body.errors[Object.keys(res.body.errors)[0]];
      yield put(
        modalShow('MODAL_INFO', {
          title: i18next.t('notification.global.error.tryagain.title'),
          description: infoError
            ? infoError.detail
            : i18next.t('notification.global.error.checkdata.description'),
          error: true
        })
      );

      yield put(signupProccessing());
    } else {
      yield put(modalError);
      yield put(signupProccessing());
    }
  } catch (e) {
    yield put(signupProccessing());
    yield put(modalError);
  }
}

export function* signupSaga() {
  yield takeEvery(sendData().type, performSendData);
}

export default signupSaga;
