/**
 * Hydrate: "Rehidrata" objetos desde el DOM para usarlos en JavaScript.
 */
function decode(string) {
  return JSON.parse(decodeURIComponent(string));
}

/**
 * @path array
 */
export default function hydrate(variable, path) {
  // Primero, intentar en el document head para ver si existe un meta.
  let __meta = document.head.querySelector("[name='" + variable + "']");

  // Si existe
  if (__meta) {
    let json = decode(__meta.getAttribute('content')) || undefined;
    // Si hay path se buscan las claves
    if (path) {
      let __json = json;
      path.forEach(function (key) {
        __json = Object.prototype.hasOwnProperty.call(__json, key)
          ? __json[key]
          : undefined;
      });
      return __json;
    }
    return decode(__meta.getAttribute('content')) || undefined;
  }

  // OK, como alternativa, debe existir un objeto global en "window" llamado
  // "__OBJECT_STORE__" que debe ser un array de objetos deshidratados.
  if (!window.__OBJECT_STORE__ || !window.__OBJECT_STORE__[variable]) {
    return undefined;
  }

  // Parece que existe...
  return window.__OBJECT_STORE__[variable];
}
