const DOMAIN = 'paywall';

export const paywallRequest = (
  userIsCustomer,
  stripe,
  interval,
  card_holder_name,
  product,
  upgrade,
  domain,
  promo,
  rpntoken,
  _,
  promoCode
) => ({
  type: `${DOMAIN}/paywallRequest`,
  userIsCustomer,
  stripe,
  interval,
  card_holder_name,
  product,
  upgrade,
  domain,
  promo,
  rpntoken,
  promoCode
});

export const paywallToggleCreditCard = () => ({
  type: `${DOMAIN}/paywallToggleCreditCard`
});

export const paywallError = (interval, error) => ({
  type: `${DOMAIN}/paywallError`,
  interval,
  error
});
