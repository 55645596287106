import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import createSagaMiddleware from 'redux-saga';
import data from 'app/Data';
import {enableAllPlugins} from 'immer';
enableAllPlugins();

import tasks from 'components/TasksPanel/reducer';
import survey from 'screens/ManageSubscriptions/reducer';
import notifications from 'components/UI/Notifications/reducer';
import scanning from 'app/Scanning/reducer';
import pdf from 'lib/redux/reducers/pdf';
import site_result from 'app/SiteResult/reducer';
import popularity from 'components/Popularity/reducer';
import settings from 'screens/Settings/reducer';
import credit_card from 'screens/Profile/Billing/Card/reducer';
import keywords from 'components/Keywords/reducer';
import competitors from 'components/Competitors/reducer';
import addsite from 'screens/AddSite/reducer';
import contact from 'screens/Profile/Contact/reducer';
import modal from 'components/UI/Modal/reducer';
import wizard from 'screens/Wizard/reducer';
import signup from 'screens/Signup/reducer';
import optimizeYourPages from 'screens/OptimizeYourPages/reducer';
import paywall from 'components/Paywall/reducer';
import testingtool from 'testingtool/reducer';
import tools from 'screens/Tools/reducer';
import rootSaga from './sagas';

export function createGlobalStore() {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    // Reducers. De momento ponemos todos los que tenemos en la App.
    // NOTA: Ver la posibilidad de meter sólo los reducers necesarios.
    // Por ejemplo, en el login es obvio que sobran los reducers de "user",
    // "site", "plan", etc.
    combineReducers({
      data,
      tasks,
      survey,
      notifications,
      scanning,
      pdf,
      site_result,
      popularity,
      settings,
      credit_card,
      keywords,
      competitors,
      addsite,
      contact,
      modal,
      wizard,
      signup,
      optimizeYourPages,
      paywall,
      testingtool,
      tools
    }),

    // State inicial. "undefined" hará que se use el state inicial creado
    // por los reducers
    undefined,

    // Para la extensión Redux DevTools de Chrome
    // https://github.com/zalmoxisus/redux-devtools-extension
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? compose(
          applyMiddleware(sagaMiddleware),
          window.__REDUX_DEVTOOLS_EXTENSION__()
        )
      : applyMiddleware(sagaMiddleware)
  );

  // Empezar la ejecución de los Saga después de crear el Store
  sagaMiddleware.run(rootSaga);
  return store;
}

export default createGlobalStore();
