import i18next from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import {initReactI18next} from 'react-i18next';
import config from 'lib/application-config';

const {current_lang: currentLang} = config;
const {translations} = window;
const language = currentLang.replace('_', '-');

i18next
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    lng: language,
    compatibilityJSON: 'v3',
    debug: process.env.NODE_ENV !== 'production',
    resources: {
      [language]: {
        translation: translations
      }
    },
    interpolation: {
      escapeValue: false,
      format: function (value, format, lng) {
        if (format === 'compact') {
          return new Intl.NumberFormat(lng, {
            notation: 'compact',
            maximumFractionDigits: 2
          }).format(value);
        }
        return value;
      }
    }
  });

export default i18next;
