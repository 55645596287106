import React from 'react';
import {Modal, ModalContent, Typography} from '@marketgoo/components';
import {getThemesPath} from 'selectors';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

const OfsSuccessScore = ({closeModal}) => {
  const {t} = useTranslation();
  const themesPath = useSelector(getThemesPath);

  return (
    <Modal
      open
      data-cy="optimize-success-modal"
      variant="center"
      onClose={closeModal}
    >
      <ModalContent className="ola-center">
        <img src={`${themesPath}/illustrations/oyp/web-perfect.svg`} />
        <Typography as="h2" m={{t: 6}}>
          {t('optimize.successmodal.title')}
        </Typography>
        <Typography m={{t: 1}}>{t('optimize.successmodal.text')}</Typography>
      </ModalContent>
    </Modal>
  );
};

export default OfsSuccessScore;
