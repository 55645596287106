import config from 'lib/application-config';
import {locale} from 'lib/datetime';
import numeral from 'numeral';
import 'numeral/locales/da-dk';
import 'numeral/locales/de';
import 'numeral/locales/en-gb';
import 'numeral/locales/es';
import 'numeral/locales/fr';
import 'numeral/locales/ja';
import 'numeral/locales/nl-be';
import 'numeral/locales/nl-nl';
import 'numeral/locales/pt-br';
import './locales/lt-lt';

const locale_not_founds = {
  nl_NL: {code: 'nl-nl'},
  da_DK: {code: 'da-dk'},
  sv_SE: {code: 'en'},
  lt_LT: {code: 'lt-lt'}
};

let locales = Object.assign(locale, locale_not_founds);
var _locale = locales[config.locale] ? locales[config.locale].code : 'en';
numeral.locale(_locale);

/**
 * Format a number in human format. e.g: 4000 -> 4k, 60000 -> 1min, 50000-> 50kb
 * @param {Number} value //input value to format
 * @param {String = ('value', 'object')} output //output format, it can be default value or an object with keys value and unit, e.g {value: xx, unit: yy}
 * @param {String = ('miles', 'bytes','time')} format //unit format
 * @param {String = ('0','0.00','0.000'...)} precision //zero decimals, one decimal, etc...
 * format(1000) -> 1 k
 * format(1000,'value','time','0.0') -> 1.0 ms
 * format(1000,'value','time','0') -> 1 ms
 * format(7884486213,'object','bytes','0.00') -> {value: 7.88, unit: 'GB'}
 * More info at http://numeraljs.com/
 */

const format = (
  value,
  output = 'value',
  format = 'miles',
  precision = '0.0'
) => {
  let val;
  switch (format) {
    case 'miles':
      val = numeral(value).format(precision + ' a');
      break;
    case 'bytes':
      val = numeral(value).format(precision + ' b');
      break;
    case 'percent':
      val = numeral(value).multiply(100).format('0.00');
      break;
  }

  if (output == 'obj') {
    val = val.split(' ');
    return {
      value: value === null ? null : val[0],
      unit: val[1].toLowerCase()
    };
  } else return val;
};

export default format;
