import React from 'react';
import {connect} from 'react-redux';
import {modalHide} from 'components/UI/Modal/actions';

import {
  Button,
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  ButtonGroup
} from '@marketgoo/components';

const Dialog = ({
  closeModal,
  handleCancel,
  handleConfirm,
  onCancel,
  cancelButtonText = __('Cancel'),
  confirmButtonText = __('Ok'),
  destroy = false,
  title,
  description,
  image,
  content
}) => (
  <Modal open onClose={closeModal}>
    <ModalHeader title={title} />
    <ModalContent>
      {content && content}
      {!content && image ? (
        <div className="with-image">
          <img src={image} />
          <div className="description">
            <span dangerouslySetInnerHTML={{__html: description}} />
          </div>
        </div>
      ) : (
        <span dangerouslySetInnerHTML={{__html: description}} />
      )}
    </ModalContent>
    <ModalFooter>
      <ButtonGroup variant="reversed">
        <Button
          variant={destroy ? 'destructive-primary' : 'primary'}
          onClick={handleConfirm}
        >
          {confirmButtonText}
        </Button>
        {onCancel && (
          <Button variant="secondary" onClick={handleCancel}>
            {cancelButtonText}
          </Button>
        )}
      </ButtonGroup>
    </ModalFooter>
  </Modal>
);

const mapDispatchToProps = (dispatch, {onCancel, onConfirm}) => ({
  closeModal: () => dispatch(modalHide()),
  handleCancel: () => {
    if (onCancel) {
      onCancel();
    }
    dispatch(modalHide());
  },
  handleConfirm: () => {
    if (onConfirm) {
      onConfirm();
    }
    dispatch(modalHide());
  }
});

export default connect(null, mapDispatchToProps)(Dialog);
