import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import Header from 'components/Header';
import {Button, ButtonGroup, Input, Field} from '@marketgoo/components';
import {Separator} from 'components/Separator';
import {Trans, useTranslation} from 'react-i18next';
import useAmplitude from 'lib/amplitude';
import queryString from 'query-string';
import {getThemesPath} from 'selectors';
import {t} from 'i18next';

import {
  required,
  getMinLengthRule,
  isValidEmail
} from 'components/UI/Form/validators';

const hasError = (errorType) => errorType && errorType.length > 0;

const LoginWpPluginForm = ({
  email,
  domain,
  rememberUrl,
  errorEmail,
  errorPassWord
}) => {
  const {
    register,
    setError,
    clearErrors,
    formState: {errors, isSubmitting},
    reset
  } = useForm({
    mode: 'onTouched',
    defaultValues: {email}
  });

  useEffect(() => {
    if (hasError(errorEmail)) {
      setError('email', {
        type: 'manual',
        message: errorEmail
      });
    } else {
      clearErrors('email');
    }
    if (hasError(errorPassWord)) {
      setError('password', {
        type: 'manual',
        message: errorPassWord
      });
    } else {
      clearErrors('password');
    }
  }, [setError, errors]);

  useEffect(() => {
    reset({email});
  }, [reset]);

  return (
    <form id="form_login" action="/login/wordpress" method="post">
      <div className="inputs-block">
        <Field
          id="email"
          label={t('wp.login.email.label')}
          disabled={isSubmitting}
          description={errors.email && errors.email.message}
          error={!!errors.email}
        >
          <Input
            {...register('email', {
              required,
              validate: {isValidEmail}
            })}
            type="text"
            placeholder={t('wp.login.email.placeholder')}
          />
        </Field>
        <Field
          id="password"
          disabled={isSubmitting}
          label={t('wp.login.password.label')}
          description={errors.password && errors.password.message}
          error={!!errors.password}
          hint={
            <a href={rememberUrl}>
              <Trans i18nKey="wp.login.password.forgot" />
            </a>
          }
        >
          <Input
            {...register('password', {
              required,
              minLength: getMinLengthRule(6)
            })}
            type="password"
            placeholder={t('wp.login.password.help')}
          />
        </Field>
        <input type="hidden" value="wordpress" name="mode" />
        {domain && <input type="hidden" value={domain} name="domain" />}
      </div>

      <div className="actions">
        <ButtonGroup className="ola-margin-top-2">
          <Button
            variant="primary"
            busy={isSubmitting && t('wp.login.continue.busy')}
          >
            {t('wp.login.continue.button')}
          </Button>
          {window.opener && (
            <Button
              variant="secondary"
              type="button"
              onClick={() => window.close()}
            >
              {t('wp.login.cancel.button')}
            </Button>
          )}
        </ButtonGroup>
      </div>
    </form>
  );
};

const LoginWpPlugin = ({
  user_domain,
  user_email, // form initial
  error_email,
  error_password
}) => {
  const {t} = useTranslation();
  const {logTypes, logEvent} = useAmplitude();
  const themesPath = useSelector(getThemesPath);

  const params = queryString.parse(location.search);
  const domain = (params && params.domain) || user_domain;
  const authLink = domain
    ? `/gateway/auth0/auth?domain=${domain}`
    : '/gateway/auth0/auth';
  const [showForm, setShowForm] = useState(
    hasError(error_email) || hasError(error_password) || false
  );

  useEffect(() => {
    logEvent(logTypes.WP_PLUGIN_Purchase_Flow_PageView, {
      Step: 'Sign in'
    });
  }, []);

  const signUpUrl = '/signup/wordpress' + (domain ? `?domain=${domain}` : '');
  const rememberUrl =
    '/remember?product=wordpress' + (domain ? `&domain=${domain}` : '');

  return (
    <section id="onboarding-wp-login">
      <div id="header">
        <Header isWPPlugin />
      </div>
      <section className="ola_ly-pageCenter">
        <header>
          <h2 className="ola-font-5-medium ola-nomargin">
            {t('wp.login.header.title')}
          </h2>
          <p className="ola-font-2-regular ola-color-neutral-700">
            <Trans
              i18nKey="wp.login.header.intro"
              components={[<strong key={0} />, <a href={signUpUrl} key={1} />]}
            />
          </p>
        </header>
        <div>
          <ButtonGroup variant="narrow">
            <Button
              variant="secondary"
              href={authLink}
              icon={
                <img
                  style={{width: '100%'}}
                  src={`${themesPath}/img/icons/google.svg`}
                />
              }
            >
              {t('wp.login.with.google')}
            </Button>
          </ButtonGroup>
          <Separator>
            <p className="ola-color-neutral-400 ola-font-1-regular ola-font-weight-bold ola-uppercase">
              {t('g.or')}
            </p>
          </Separator>
          {!showForm && (
            <ButtonGroup variant="narrow">
              <Button variant="primary" onClick={() => setShowForm(true)}>
                {t('wp.login.with.email')}
              </Button>
            </ButtonGroup>
          )}
          {showForm && (
            <LoginWpPluginForm
              email={user_email}
              domain={domain}
              rememberUrl={rememberUrl}
              errorEmail={error_email}
              errorPassWord={error_password}
            />
          )}
        </div>
      </section>
    </section>
  );
};

export default LoginWpPlugin;
