import produce from 'immer';
import {
  wizardUpdate,
  wizardNextStep,
  wizardPreviousStep,
  wizardSetStep,
  wizardSetNavigateObject,
  wizardSetKeywordData
} from './actions';

const stepToNumber = {
  welcome: 0,
  goal: 1,
  industry: 2,
  country: 3,
  keywords: 4,
  competitors: 5,
  end: 6
};

export const initialState = {
  stepToNumber,
  numberToStep: Object.keys(stepToNumber).reduce((prev, cur) => {
    prev[stepToNumber[cur]] = cur;
    return prev;
  }, {}),
  currentStep: 0,
  data: {
    goal: null,
    industry: null,
    country: null,
    isLocal: false,
    city: null,
    keywords: [],
    competitors: []
  },
  keywordsData: [],
  navigate: () => {} // hack para usar el navigate de react-router-dom
};

export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case wizardNextStep().type:
        draft.currentStep = Math.min(
          Object.keys(state.stepToNumber).length - 1,
          draft.currentStep + 1
        );
        state.navigate('/wizard/' + state.numberToStep[draft.currentStep]);
        break;

      case wizardPreviousStep().type:
        draft.currentStep = Math.max(0, draft.currentStep - 1);
        state.navigate('/wizard/' + state.numberToStep[draft.currentStep]);
        break;

      case wizardSetStep().type:
        if (state.stepToNumber[action.step] !== undefined) {
          draft.currentStep = state.stepToNumber[action.step];
          history.replaceState(
            {},
            '',
            '/wizard/' + state.numberToStep[draft.currentStep]
          );
        }
        break;

      case wizardUpdate().type:
        draft.data[action.field] = action.value;
        break;

      case wizardSetKeywordData().type:
        draft.keywordsData = action.data;
        break;

      case wizardSetNavigateObject().type:
        draft.navigate = action.navigate;
        break;

      default:
        draft;
        break;
    }
  });
