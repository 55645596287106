// appConfig
export const getUser = (state) => state.data.config?.user;
export const getAvailableLanguages = (state) =>
  state.data.config?.available_languages;
export const manage_account = (state) =>
  state.data.config.dealer?.manage_account || false;
export const is_customer = (state) =>
  state.data.config.user?.is_customer || false;
export const dealerName = (state) => state.data.config.dealer?.name || false;
export const getDealerTag = (state) => state.data.config.dealer?.tag || false;
export const getDealerBranding = (state) =>
  state.data.config.dealer?.branding || false;
export const assetsPath = (state) => state.data.config.assets_path || false;
export const getConfigCurrentUuid = (state) =>
  state.data.config.current_plan || false;
export const getCurrentLocale = (state) => state.data.config.locale || false;

export const getCheckAgainCta = (state) =>
  state.data.config.dealer?.ctas?.['check-again'] ?? false;

export const getCompetitorsCta = (state) =>
  state.data.config.dealer?.ctas?.['competitors'] ?? false;

export const getMaxPagesCta = (state) =>
  state.data.config.dealer?.ctas?.['max-pages'] || false;

export const getCtaByKey = (ctaKey) => (state) => {
  return state.data?.config?.dealer?.ctas[ctaKey];
};

export const getThemesPath = (state) => {
  const dealerBrand = getDealerBranding(state);
  return `/themes/${dealerBrand}`;
};
export const getSupportEmail = (state) =>
  state.data.config.dealer?.support_email || null;

export const getSupportLink = (state) =>
  state.data.config.dealer?.support_link || null;

export const getDefaultProductByDealer = (state) =>
  state.data.config.dealer?.default_product || null;

export const getProductByDealer = (product) => (state) =>
  state.data.config.dealer?.products?.[product];

export const getRouteMap = (state) => state.data.config.app?.route_map;

export const getCurrentPlanBearer = (state) =>
  state.data.config?.current_plan_bearer || null;

// Plan

export const getCurrentPlan = (state) => {
  const current_uuid = getConfigCurrentUuid(state);
  return state.data.plans[current_uuid] || false;
};

export const getPlanGivenUUID = (uuid) => (state) =>
  state.data.plans[uuid] || false;

export const suspendedAt = (state) => {
  const plan = getCurrentPlan(state);
  return plan['suspended_at'] || false;
};

export const domain = (state) => {
  const plan = getCurrentPlan(state);
  return plan['domain'] || false;
};

export const product = (state) => state.data.plans?.product;

export const getProductDetailsName = (state) => {
  const plan = getCurrentPlan(state);
  return plan.product_details?.name;
};

export const getProductDetails = (state) => {
  const plan = getCurrentPlan(state);
  return plan.product_details.features;
};

export const getProductDetailsFree = (state) => {
  const plan = getCurrentPlan(state);
  return plan.product_details?.free;
};

// Site

export const getTrafficAnalytics = (state) => {
  const plan = getCurrentPlan(state);
  return plan?.site?.results?.traffic?.analytics;
};

export const getAnalyticsProfile = (state) => {
  const plan = getCurrentPlan(state);
  return plan?.site?.config?.google_analytics?.profile;
};

export const getAnalyticsProfileProperty = (state) => {
  const plan = getCurrentPlan(state);
  return plan?.site?.config?.google_analytics?.profile?.web_property_id || null;
};

export const getAnalyticsSessions = (state) => {
  const plan = getCurrentPlan(state);
  return plan?.site?.config?.google_analytics?.sessions?.value;
};

export const getStructuredDatafromTools = (state) => {
  const plan = getCurrentPlan(state);
  return plan?.site?.config?.tools?.structureddata;
};

export const getOpenAiConfig = (state) => {
  const plan = getCurrentPlan(state);
  return plan?.site?.config?.openai;
};

export const getEstimatedOrganicKeywords = (state) => {
  const plan = getCurrentPlan(state);
  return (
    plan?.site?.results?.traffic?.estimated_organic?.keywords?.value || null
  );
};

export const getEstimatedOrganicVisits = (state) => {
  const plan = getCurrentPlan(state);
  return plan?.site?.results?.traffic?.estimated_organic?.visits?.value || null;
};

export const getVisitsVariation = (state) => {
  const plan = getCurrentPlan(state);
  return plan?.site?.results?.traffic?.estimated_organic?.visits?.diff || null;
};

export const getKeywords = (state) => {
  const plan = getCurrentPlan(state);
  return plan?.site?.keywords || null;
};

export const getError = (state) => {
  const plan = getCurrentPlan(state);
  return plan?.site?.error || null;
};

export const getResultsSubmission = (state) => {
  const plan = getCurrentPlan(state);
  return plan?.site?.results?.submission || null;
};

export const getTrackedKeywords = (state) => {
  const keywords = getKeywords(state);
  return keywords ? keywords.filter((keyword) => keyword?.is_tracked) : null;
};
export const getProductToUpdate = (product) => (state) =>
  state.data.config.dealer.products[product];

export const getMaxKeywordsGivenProduct = (product) => (state) =>
  state.data.config.dealer.products[product].features.max_keywords;

export const getSuggestedKeywords = (state) =>
  state.keywords?.temporal_suggested;

export const getSearchedKeywords = (state) => state.keywords?.search_results;

export const getIsSearchingSuggestions = (state) => state.keywords?.searching;

export const getRelatedKeywords = (state) => state.keywords?.related;

export const getSiteBackLinks = (state) => {
  const plan = getCurrentPlan(state);
  return plan?.site?.results?.popularity?.domains || null;
};

export const getSiteTasks = (state) => {
  const plan = getCurrentPlan(state);
  return plan?.site?.tasks || null;
};

export const getAllSiteTasks = (state) => {
  const plan = getCurrentPlan(state);
  return plan ? [...plan.site.tasks, ...plan.site.suggestions] : false;
};

// Scan
export const getScanFinishedAt = (state) => {
  const plan = getCurrentPlan(state);
  return plan?.site?.scan?.finished_at || null;
};

export const getScanHomePageStatus = (state) => {
  const plan = getCurrentPlan(state);
  return plan?.site?.scan?.homepage_status || null;
};

export const getScoreGlobal = (state) => {
  const plan = getCurrentPlan(state);
  return plan?.site?.progress?.scores?.global || null;
};

export const getThumbnail = (state) => {
  const plan = getCurrentPlan(state);
  return plan?.site?.thumbnail || null;
};

export const getHomePageProtocol = (state) => {
  const plan = getCurrentPlan(state);
  return plan?.site?.scan?.homepage_protocol || 'http';
};

export const getDealerProducts = (state) => {
  return state.data?.config?.dealer?.products;
};

// DANGER!! Este selector sólo funciona si tenemos un plan.
// Cuidado de usarlo en las pantallas donde no estemos logueados
export const getCurrentProductFeatures = (state) => {
  const plan = getCurrentPlan(state) || false;
  const product = plan?.product || false;
  return plan && product
    ? state.data?.config?.dealer?.products[product]?.features
    : null;
};

export const getCtaProduct = (ctaKey) => (state) => {
  const productTo = state.data?.config?.dealer?.ctas[ctaKey]?.product_to;
  return state.data?.config?.dealer?.products[productTo]?.features;
};

export const getProductToName = (ctaKey) => (state) => {
  const productTo = state.data?.config?.dealer?.ctas[ctaKey]?.product_to;
  return state.data?.config?.dealer?.products[productTo]?.name;
};

export const getCompetitors = (state) => {
  const plan = getCurrentPlan(state);
  return plan?.site?.competitors || null;
};

export const getMaxCompetitorsGivenProduct = (product) => (state) =>
  state.data?.config?.dealer?.products[product]?.features?.max_competitors;

export const getSuggestedCompetitors = (state) =>
  state.competitors?.suggestions;

export const getIsLoadingSuggestions = (state) =>
  state.competitors?.isLoadingSuggestions;

export const getMaxCompetitors = (state) => {
  const currentProductFeatures = getCurrentProductFeatures(state);
  return currentProductFeatures?.max_competitors;
};

export const getOfsRecomendations = (state) => {
  const currentProductFeatures = getCurrentProductFeatures(state);
  return currentProductFeatures?.optimize_suggestions;
};

// Tasks //
export const getScanningFinished = (state) => {
  return state.tasks?.update || false;
};
export const getStatusTask = (state) => {
  return state.tasks?.data_task?.status || false;
};
export const getBusyState = (state) => {
  return state.tasks?.busy;
};
export const getTaskContent = (key) => (state) => {
  return state.tasks?.task_ai_feature[key] || false;
};
// SCANING
export const getCurrentScanning = (state) => {
  const current_uuid = getConfigCurrentUuid(state);
  return current_uuid ? state.scanning[current_uuid] : null;
};

// KPIS
export const getKpiLinkStatsNumLinks = (state) => {
  const plan = getCurrentPlan(state);
  return plan?.site?.results?.popularity?.num_links || null;
};
export const getKpiLinkStatsNumDomains = (state) => {
  const plan = getCurrentPlan(state);
  return plan?.site?.results?.popularity?.num_domains || null;
};

export const getDefaultProduct = (state) => {
  return state.data?.config?.dealer?.products[
    state.data?.config?.dealer?.default_product
  ];
};

export const getProductByKey = (state, key) => {
  return state.data?.config?.dealer?.products[key];
};

export const getCurrentRoute = (state) => {
  return state.data?.config?.current_route;
};

// site_result

export const getSelectedKeyword = (state) => {
  return state.site_result?.keyword_selected;
};

export const getSelectedEgine = (state) => {
  return state.site_result.engine_selected;
};

// Wordpress Direct

export const getIsWordpressDirect = (features) => {
  return features?.have_plugin === 'wordpress';
};

export const getPDFDownloadIntents = (state) => state.pdf.downloadIntents;
export const getIsPDFDownloading = (state) => state.pdf.isDownloading;

// OptimizeYourPages

export const getPageData = (state) => state.optimizeYourPages.page_data;
export const getKeywordByPage = (state) =>
  state.optimizeYourPages?.page_data?.keyword?.id || null;

// Tools

export const getStructuredData = (state) => state.tools.structuredData;

//  Notifications

export const getNotifications = (state) => state.notifications;

// subscriptions

export const getSubscriptions = (state) => {
  const plan = getCurrentPlan(state);
  return plan?.subscription || null;
};
