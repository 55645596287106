import config from 'lib/application-config';

const prefix = config.app.route_prefix;

const competitor = {
  create: () => ({url: `${prefix}/ajax/competitors`, method: 'POST'}),
  delete: (competitorId) => ({
    url: `${prefix}/ajax/competitors/${competitorId}`,
    method: 'DELETE'
  }),
  read: () => ({url: '/ajax/competitors/related', method: 'GET'})
};

const dealer = {
  update: () => ({url: `${prefix}/ajax/dealers`, method: 'PUT'})
};

const emailNotifications = {
  create: () => ({
    url: `${prefix}/ajax/email-notifications`,
    method: 'POST'
  })
};

const emailRecipients = {
  create: () => ({url: '/ajax/emails/recipients', method: 'POST'})
};

const emailSettings = {
  create: () => ({url: '/ajax/emails/settings', method: 'POST'})
};

const events = {
  create: () => ({url: `${prefix}/ajax/events`, method: 'POST'})
};

const googleAnalytics = {
  read: () => ({url: '/ajax/google-analytics/profiles', method: 'GET'}),
  create: () => ({url: '/ajax/google-analytics/profiles', method: 'POST'}),
  update: () => ({url: '/gateway/google/reset', method: 'GET'})
};

const keyword = {
  create: () => ({url: `${prefix}/ajax/keywords`, method: 'POST'}),
  delete: (keyword) => ({
    url: `${prefix}/ajax/keywords/${keyword}`,
    method: 'DELETE'
  })
};

const keywordSearch = {
  read: (word) => ({
    url: `${prefix}/ajax/keywords/search/${word}`,
    method: 'GET'
  })
};

const mock = {
  read: (plan) => ({url: `/mock/${plan}`, method: 'GET'})
};

const mockJourney = {
  update: (plan) => ({url: `/mock/${plan}/moment`, method: 'POST'})
};

const mockTask = {
  update: (plan) => ({url: `/mock/${plan}/task`, method: 'POST'})
};

const myProfileContact = {
  create: () => ({url: `${prefix}/ajax/myprofile/contact`, method: 'POST'})
};

const myProfileCreditCard = {
  create: () => ({
    url: `${prefix}/ajax/myprofile/credit-card`,
    method: 'POST'
  })
};

const myWebsites = {
  create: () => ({url: `${prefix}/mywebsites/add`, method: 'POST'})
};

const notice = {
  delete: () => ({url: `${prefix}/ajax/notice`, method: 'DELETE'})
};

const optimize = {
  update: (taskId) => ({
    url: `${prefix}/ajax/optimize/${taskId}`,
    method: 'PUT'
  })
};

const pages = {
  read: ({id, query = ''}) => ({
    url: `${prefix}/ajax/pages${id ? '/' + id : ''}${query}`,
    method: 'GET'
  })
};

const payment = {
  create: () => ({url: `${prefix}/payment-billing`, method: 'POST'})
};

// TODO: to remove unused read
const plan = {
  read: () => ({url: `${prefix}/ajax/plan`, method: 'GET'}),
  update: (uuid, updateCache = false) => ({
    url: `${prefix}/ajax/plan/${uuid}${
      updateCache ? '?update_cache=true' : ''
    }`,
    method: 'GET'
  }),
  delete: (plan) => ({
    url: `${prefix}/ajax/plan/${plan}/cancel`,
    method: 'DELETE'
  })
};

// TODO: to remove unused create
const reports = {
  read: ({siteUrl, email}) => ({
    url: `/ajax/reports/${siteUrl}/send/${email}`,
    method: 'GET'
  }),
  create: (origin, domain) => ({
    url: `${origin}/reports/${domain}/download`,
    method: 'GET'
  })
};

const resultsDownload = {
  read: () => ({
    url: '/results/download',
    method: 'POST'
  })
};

const settings = {
  create: () => ({url: `${prefix}/ajax/configuration`, method: 'POST'})
};

const setupIntent = {
  read: () => ({url: `${prefix}/ajax/setup-intent`, method: 'GET'})
};

const signup = {
  create: () => ({url: '/ajax/signup', method: 'POST'})
};

const sitemap = {
  read: () => ({url: `${prefix}/ajax/sitemap`, method: 'POST'})
};

const task = {
  read: (id) => ({
    url: `${prefix}/ajax/task${id ? '/' + id : ''}`,
    method: 'GET'
  }),
  update: (taskId) => ({
    url: `${prefix}/ajax/task/${taskId}`,
    method: 'GET'
  }),
  create: (taskId) => ({url: `/ajax/task/${taskId}`, method: 'PUT'})
};

const user = {
  update: () => ({url: `${prefix}/ajax/users`, method: 'PUT'})
};

const wizard = {
  create: () => ({url: '/ajax/wizard', method: 'POST'})
};

const external = {
  domainCheck: (url) => ({
    url: `https://check-domain.mktgoo.workers.dev/?domain=${url}`,
    method: 'GET'
  })
};

const structuredData = {
  create: () => ({url: '/ajax/tools/structureddata', method: 'POST'})
};

const notifications = {
  read: () => ({url: '/ajax/notifications', method: 'GET'}),
  delete: (id) => ({
    url: `/ajax/notifications/${id}`,
    method: 'DELETE'
  })
};

const keywordSuggestion = {
  read: (pageId) => ({
    url: `/ajax/optimize/suggest_keyword/${pageId}`,
    method: 'GET'
  })
};

export const endpoints = {
  competitor,
  dealer,
  emailNotifications,
  emailRecipients,
  emailSettings,
  external,
  events,
  googleAnalytics,
  keyword,
  keywordSearch,
  mock,
  mockJourney,
  mockTask,
  myProfileContact,
  myProfileCreditCard,
  myWebsites,
  notice,
  optimize,
  pages,
  payment,
  plan,
  reports,
  resultsDownload,
  settings,
  setupIntent,
  signup,
  sitemap,
  task,
  user,
  wizard,
  structuredData,
  notifications,
  keywordSuggestion
};
