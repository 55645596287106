import {
  Accordion,
  Button,
  ButtonGroup,
  Field,
  Input,
  MenuOption,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader
} from '@marketgoo/components';
import {
  addCompetitor,
  loadSuggestedCompetitors
} from 'components/Competitors/actions';
import {uniqueCompetitors} from 'components/Competitors/filters';
import {isValidDomain, required} from 'components/UI/Form/validators';
import {modalHide} from 'components/UI/Modal/actions';
import {t} from 'i18next';
import {endpoints} from 'lib/redux/sagas/api';
import utils from 'misc/utils';
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {Trans} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {
  getConfigCurrentUuid,
  getIsLoadingSuggestions,
  getMaxCompetitorsGivenProduct,
  getPlanGivenUUID,
  getSuggestedCompetitors
} from 'selectors';
import Superagent from 'superagent';

export const findCompetitor = (competitors, competitorId) =>
  competitors.find(
    (competitor) =>
      utils.cleanUrl(competitor.id) == utils.cleanUrl(competitorId)
  );

const Skeleton = () => {
  const ELEMENTS = 5;

  const Ghost = ({type = 'line'}) => (
    <div className={`ola-skeleton is-loading ${type}`}>
      <div></div>
    </div>
  );

  const Line = ({children}) => <div className="option">{children}</div>;

  return (
    <div className="skeleton-list">
      {Array.from(Array(ELEMENTS)).map((_, index) => (
        <Line key={index}>
          <Ghost type="line" />
        </Line>
      ))}
    </div>
  );
};
const Suggestion = ({
  isEmpty,
  isLoading,
  suggestedCompetitors,
  addSuggestedValue
}) => {
  if (!isLoading && isEmpty) {
    return (
      <p className="ola-font-2-regular ola-color-neutral-700 ola-center">
        {t('competition.add.sugestions.none')}
      </p>
    );
  }
  if (isLoading) {
    return <Skeleton />;
  }
  const domainValue = (currentId, competitor) =>
    competitor?.id === currentId ? competitor?.domain : null;

  const onClickSuggested = (competitor) => (event) => {
    const selectedCompetitor = domainValue(event.currentTarget.id, competitor);
    addSuggestedValue(selectedCompetitor);
  };

  return (
    <ul>
      {suggestedCompetitors.slice(0, 5).map((competitor, idx) => {
        const domain = competitor?.domain;
        return (
          <MenuOption
            key={idx}
            type="button"
            size="small"
            id={competitor?.id}
            onClick={onClickSuggested(competitor)}
          >
            {domain}
          </MenuOption>
        );
      })}
    </ul>
  );
};

const AddCompetitor = () => {
  const [redirectedDomain, setRedirectedDomain] = useState(null);
  const uuid = useSelector(getConfigCurrentUuid);
  const currentPlan = useSelector(getPlanGivenUUID(uuid));
  const site = currentPlan?.site;
  const product = currentPlan?.product;
  const currentCompetitors = site?.competitors;
  const siteDomain = site?.domain;
  const isLoadingSuggestions = useSelector(getIsLoadingSuggestions);
  const maxCompetitors = useSelector(getMaxCompetitorsGivenProduct(product));

  const suggestedCompetitors = useSelector(getSuggestedCompetitors);
  const suggestedUniqueCompetitors = uniqueCompetitors(
    suggestedCompetitors,
    currentCompetitors
  );

  const full = currentCompetitors >= maxCompetitors;

  const dispatch = useDispatch();

  const {
    setValue,
    setError,
    register,
    handleSubmit,
    formState: {errors, isSubmitting},
    getValues
  } = useForm({
    defaultValues: {
      domain: ''
    }
  });

  const isNotDuplicatedDomain = (domain) =>
    !findCompetitor(currentCompetitors, domain.trim()) ||
    t('competition.add.error.duplicated');

  const isNotSelfDomain = (domain) =>
    utils.cleanUrl(siteDomain) !== utils.cleanUrl(domain) ||
    t('competition.add.error.self');

  const isExistingDomain = async (input) => {
    const {url, method} = endpoints.external.domainCheck(utils.cleanUrl(input));
    const {
      body: {status, ok, redirected, domain}
    } = await Superagent(method, url);
    return {status, ok, redirected, domain};
  };

  const toggleSuggestions = (event) => {
    if (event.target.open && suggestedCompetitors.length === 0) {
      dispatch(loadSuggestedCompetitors());
    }
  };

  const hideModal = () => {
    dispatch(modalHide());
  };

  const addAction = (domain, uuid) => {
    dispatch(addCompetitor({id: domain, domain: domain}, uuid));
  };

  const addSuggestedValue = (value) => {
    setValue('domain', value);
  };

  const onClickRedirectedDomain = (redirectedDomain) => () =>
    addSuggestedValue(redirectedDomain);

  const onSubmit = async (values) => {
    const {domain, ok, redirected, status} = await isExistingDomain(
      values.domain
    );
    const domainExists = ok || status === 200;
    const redirectedDomain = domainExists && redirected;
    if (redirectedDomain) {
      setRedirectedDomain(domain);
    } else if (domainExists) {
      addAction(domain, uuid);
    } else {
      setError('domain', {
        type: 'manual',
        message: t('competition.add.error.not_found')
      });
    }
    redirectedMessage && addAction(domain, uuid);
  };
  const redirectedMessage = redirectedDomain && (
    <Trans
      i18nKey="competition.add.error.redirect"
      values={{
        included_domain: getValues('domain'),
        redirection_domain: redirectedDomain
      }}
      components={[
        <strong
          className="competition_input-redirect"
          key={0}
          onClick={onClickRedirectedDomain(redirectedDomain)}
        />
      ]}
    />
  );
  const fieldMessage =
    (errors && errors.domain && errors.domain.message) || redirectedMessage;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal variant="narrow" open onClose={hideModal}>
        <ModalHeader
          title={t('competition.add.title')}
          intro={t('competition.add.description')}
        />
        <ModalContent variant="scroll">
          <Field
            id="domain"
            label={t('competition.add.label')}
            type="text"
            error={!!errors.domain && errors.domain.message}
            description={fieldMessage}
          >
            <Input
              type="text"
              {...register('domain', {
                required,
                validate: {
                  isValidDomain,
                  isNotDuplicatedDomain,
                  isNotSelfDomain
                }
              })}
            />
          </Field>
          <Accordion
            onToggle={toggleSuggestions}
            title={t('competition.add.suggestions.title')}
          >
            <Suggestion
              isEmpty={!suggestedUniqueCompetitors.length}
              isLoading={isLoadingSuggestions}
              suggestedCompetitors={suggestedUniqueCompetitors}
              addSuggestedValue={addSuggestedValue}
            />
          </Accordion>
        </ModalContent>
        <ModalFooter>
          <ButtonGroup variant="reversed">
            <Button
              variant="primary"
              disabled={full}
              busy={isSubmitting && t('g.checking')}
            >
              {t('competition.add.cta.submit')}
            </Button>
            <Button disabled={full} variant="secondary" onClick={hideModal}>
              {t('competition.add.cta.cancel')}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    </form>
  );
};

export default AddCompetitor;
