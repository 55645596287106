import React from 'react';
import {Modal, ModalContent, Typography} from '@marketgoo/components';
import {useTranslation} from 'react-i18next';

const OfsLimitPages = ({closeModal, product, limitPages, days}) => {
  const {t} = useTranslation();

  return (
    <Modal open variant="center" onClose={closeModal}>
      <ModalContent className="ola-center">
        <Typography variant="font-6" m={{t: 1}}>
          {t('modal.limitpro.title')}
        </Typography>
        <Typography variant="font-2" m={{t: 2, b: 1}}>
          {t('modal.limitpro.content', {
            product,
            limitPages,
            days
          })}
        </Typography>
      </ModalContent>
    </Modal>
  );
};

export default OfsLimitPages;
