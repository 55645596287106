import {modalHide} from 'components/UI/Modal/actions';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter
} from '@marketgoo/components';
import {useTranslation} from 'react-i18next';
import {getRouteMap} from 'selectors';
import {Link} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';

const NewVersionNotice = () => {
  const {t} = useTranslation();
  const routes = useSelector(getRouteMap);
  const dispatch = useDispatch();
  const handleButtonClick = () => {
    dispatch(modalHide());
  };
  return (
    <Modal open closable={false} variant="narrow">
      <ModalHeader title={t('modal.update.ga4.title')} />
      <ModalContent>
        <p className="ola-nomargin">{t('modal.update.ga4.paragraph')}</p>
        <p>{t('modal.update.ga4.paragraph2')}</p>
      </ModalContent>
      <ModalFooter>
        <ButtonGroup variant="reversed">
          <Link to={routes?.connections}>
            <Button variant="primary" onClick={handleButtonClick}>
              {t('modal.update.ga4.button')}
            </Button>
          </Link>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default NewVersionNotice;
