import {websocketActions} from 'app/Scanning/actions';
import produce from 'immer';
import {endUnassignKeyword, unassignKeyword} from 'components/Keywords/actions';
import {
  endKeywordAssign,
  resetTaskAiFeature,
  performTaskAction,
  resetScanningMessage,
  responseDataPage,
  startKeywordAssign,
  responseTaskAutocompleted,
  requestTaskAutocompleted
} from 'components/TasksPanel/actions';

export const initialState = {
  busy: false,
  keyword_assign_busy: false,
  pageData: {},
  status: {},
  update: false,
  task_ai_feature: {}
};

export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case startKeywordAssign().type:
      case unassignKeyword().type:
        draft.keyword_assign_busy = true;
        break;
      case endKeywordAssign().type:
      case endUnassignKeyword().type:
        draft.keyword_assign_busy = false;
        break;
      case resetTaskAiFeature().type:
        delete draft.task_ai_feature[action.payload.url];
        break;
      case performTaskAction().type:
        draft.status = action.payload.task;
        draft.busy = true;
        break;

      case responseDataPage().type:
        draft.pageData = action.payload.response;
        break;

      case requestTaskAutocompleted().type:
        draft.task_ai_feature[action.payload.path] =
          draft.task_ai_feature[action.payload.path] || {};
        draft.task_ai_feature[action.payload.path].busy = true;
        break;

      case responseTaskAutocompleted().type:
        draft.task_ai_feature[action.payload.path] = {
          ...state.task_ai_feature[action.payload.path],
          ...action.payload
        };
        draft.task_ai_feature[action.payload.path].busy = false;
        break;

      case websocketActions.SCANNING_TASKS_COMPLETED:
      case websocketActions.SCANNING_SINGLE_TASK_COMPLETED:
        draft.update = true;
        draft.busy = false;
        break;

      case resetScanningMessage().type:
        draft.update = false;
        break;

      default:
        draft;
        break;
    }
  });
