const DOMAIN = 'settings';

export const saveGeneralSettingsRequest = (values) => ({
  type: `${DOMAIN}/saveGeneralSettingsRequest `,
  payload: values
});

export const saveEmailNotificationRequest = (values) => ({
  type: `${DOMAIN}/saveEmailNotificationRequest `,
  payload: values
});

export const saveEmailNotificationsResponse = (values = {}, error) => ({
  type: `${DOMAIN}/saveEmailNotificationsResponse `,
  payload: values,
  error
});

export const saveGeneralSettingsResponse = (values = {}, error) => ({
  type: `${DOMAIN}/saveGeneralSettingsResponse `,
  payload: values,
  error
});

export const updateEmailSettingsRequest = (config) => ({
  type: `${DOMAIN}/updateEmailSettingsRequest `,
  payload: config
});

export const updateEmailRecipientsRequest = (config) => ({
  type: `${DOMAIN}/updateEmailRecipientsRequest `,
  payload: config
});

export const updateRecipients = (recipients) => ({
  type: `${DOMAIN}/updateRecipients`,
  payload: recipients
});

export const updateEmailSettings = (isEnabled) => ({
  type: `${DOMAIN}/updateEmailSettings`,
  payload: isEnabled
});
