import {signupProccessing} from 'screens/Signup/actions';
import produce from 'immer';

export const initialState = {
  processing: false
};

export default (state = initialState, {type, payload}) =>
  produce(state, (draft) => {
    switch (type) {
      case signupProccessing().type:
        draft.processing = payload;
        break;
      default:
        draft;
        break;
    }
  });
