import {websocketActions} from 'app/Scanning/actions';
import i18next from 'i18next';
import {takeEvery} from 'redux-saga';
import {call, put} from 'redux-saga/effects';
import {request} from 'lib/redux/sagas/request';
import {updatePlan} from 'app/Data/plans/plan/actions';
import {endpoints} from 'lib/redux/sagas/api';
import {modalShow} from 'components/UI/Modal/actions';

export const modalError = modalShow('MODAL_INFO', {
  title: i18next.t('notification.global.error.tryagain.title'),
  description: i18next.t('notification.global.error.tryagain.description'),
  error: true
});

export function* updatePlanSaga(action) {
  // Pedimos las cards para las tareas del site del plan
  try {
    const {
      body: {payload}
    } = yield call(request, endpoints.plan.update(action.payload.uuid, true));
    yield put(updatePlan(payload));
  } catch (e) {
    yield put(modalError);
  }
}

export function* planSaga() {
  yield takeEvery(websocketActions.REFRESH_PLAN_DATA, updatePlanSaga);
}

export default planSaga;
