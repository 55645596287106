import config from 'lib/application-config';

import {enGB, es, fr, nl, ptBR, el, da, de, ja, sv, lt} from 'date-fns/locale';
export const dateFnsLocales = {
  'en-gb': enGB,
  es,
  fr,
  nl,
  'pt-br': ptBR,
  el,
  da,
  de,
  ja,
  sv,
  lt
};

export const locale = {
  en_GB: {code: 'en-gb'},
  es_ES: {code: 'es'},
  fr_FR: {code: 'fr'},
  nl_NL: {code: 'nl'},
  pt_BR: {code: 'pt-br'},
  el_GR: {code: 'el'},
  da_DK: {code: 'da'},
  de_DE: {code: 'de'},
  ja_JP: {code: 'ja'},
  sv_SE: {code: 'sv'},
  lt_LT: {code: 'lt'}
};

export const currentLocale = locale[config.locale]?.code || 'en';
