import React from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';
import {Navigation} from '@marketgoo/components';
import {useIsWordPressPlugin} from 'hooks';
import config from 'lib/application-config';
import {
  assetsPath,
  dealerName,
  getCurrentPlan,
  suspendedAt,
  getSubscriptions,
  manage_account as manageAccount,
  getProductDetailsFree
} from 'selectors';
import Account from 'components/Header/Account';
import MyWebsites from 'components/Header/MyWebsites';
import Menu from 'components/Header/Menu';

const Header = ({
  assetsPath,
  dealerName,
  suspendedAt,
  isWizarActive,
  currentPlanActive,
  isWPPlugin,
  subscription,
  manageAccount,
  productDetailsFree
}) => {
  const isWordpressPlugin = isWPPlugin || useIsWordPressPlugin();

  const unpaidPlans =
    subscription === null && manageAccount && !productDetailsFree;

  const showMenu =
    !suspendedAt &&
    !isWizarActive &&
    currentPlanActive &&
    !isWordpressPlugin &&
    !unpaidPlans;

  return (
    <Navigation.Bar
      direction="horizontal"
      left={
        <>
          <a
            href={config.dealer.menu_logo_link || '/'}
            className={cx('logo', isWordpressPlugin && 'is-wp-plugin')}
          >
            <img src={`${assetsPath}/img/logo-app.svg`} alt={dealerName} />
          </a>
          <MyWebsites />
        </>
      }
      right={
        <>
          {showMenu && <Menu />}
          <Account />
        </>
      }
    />
  );
};

const mapStateToProps = (store) => ({
  dealerName: dealerName(store),
  assetsPath: assetsPath(store),
  suspendedAt: suspendedAt(store),
  isWizarActive: store.wizard.active,
  currentPlanActive: getCurrentPlan(store),
  subscription: getSubscriptions(store),
  manageAccount: manageAccount(store),
  productDetailsFree: getProductDetailsFree(store)
});

export default connect(mapStateToProps)(Header);
