import React, {useEffect} from 'react';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalContent,
  ModalFooter
} from '@marketgoo/components';
import {getCtaByKey} from 'selectors';
import {useSelector, useDispatch} from 'react-redux';
import {upgradeClick} from 'lib/redux/actions/upgrade';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {modalHide} from 'components/UI/Modal/actions';
import {sendEventOnClose, sendEventOnOpen, sendEventOnUpgrade} from './common';

const FeatureRequired = ({
  title,
  description,
  buttonText,
  hasBackButton,
  hasCancelButton
}) => {
  const {t} = useTranslation();
  const location = useLocation();
  const cta = useSelector(getCtaByKey('app-upgrade'));
  const dispatch = useDispatch();
  const handleButtonClick = () => {
    sendEventOnUpgrade(location.pathname, cta?.key);
    dispatch(
      upgradeClick({
        relocate: cta?.target_url,
        cta: cta.key
      })
    );
  };

  const handleCancelButtonClick = () => {
    sendEventOnClose(location.pathname, cta?.key);
    dispatch(modalHide());
  };
  const handleBackButtonClick = () => {
    sendEventOnClose(location.pathname, cta?.key);
    history.back();
  };

  useEffect(() => {
    sendEventOnOpen(location.pathname, cta?.key);
  }, []);

  return (
    <Modal open closable={false}>
      <h1 className="ola-font-4-bold ola-center">{title}</h1>
      <ModalContent>{description}</ModalContent>
      <ModalFooter>
        <ButtonGroup variant="center">
          {hasBackButton && (
            <Button
              variant="pro-secondary"
              onClick={
                hasCancelButton
                  ? handleCancelButtonClick
                  : handleBackButtonClick
              }
            >
              {t('g.cancel')}
            </Button>
          )}
          <Button variant="pro" onClick={handleButtonClick}>
            {buttonText}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default FeatureRequired;
