import {
  saveCardRequest,
  toggleCardMenu,
  toggleCardEditing,
  cardChanged
} from 'screens/Profile/Billing/Card/actions';
import {showNotification} from 'components/UI/Notifications/actions';
import produce from 'immer';

export const initialState = {
  processing: undefined,
  editing: false,
  show_menu: false,
  ready: false
};

export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case saveCardRequest().type:
        draft.processing = true;
        break;

      case toggleCardMenu().type:
        draft.show_menu = !draft.show_menu;
        break;

      case toggleCardEditing().type:
        draft.editing = !draft.editing;
        break;

      case cardChanged().type:
        draft.ready = action.payload.data.complete;
        break;

      case showNotification().type:
        draft.processing = false;
        draft.editing = false;
        break;

      default:
        draft;
        break;
    }
  });
